import { QueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";

function errorHandler(error: Error) {
    const title = error instanceof Error ? error.message : "Error Connecting to Server";
    toast.error(title);
}

export function generateQueryClient() {
    return new QueryClient({
        defaultOptions: {
            queries: {
                staleTime: 5 * 60 * 1000, // 5 Minutes
                gcTime: 10 * 60 * 1000, // 10 Minutes
                refetchOnMount: true,
                refetchOnWindowFocus: false,
                refetchOnReconnect: false
            },
            mutations: {
                onError: errorHandler
            }
        }
    });
}

export const client = generateQueryClient();
