// CustomStatusChip.tsx
import React from "react";
import Chip from "@mui/material/Chip";
import { styled } from "@mui/system";

interface CustomStatusChipProps {
  value: number;
}

const statusMapping: { [key: number]: string } = {
  0: "Active",
  1: "Discharged",
};

const CustomChip = styled(Chip)<{ status: string }>(({ status }) => ({
  backgroundColor: status === "Active" ? "green" : "red",
  color: "white",
}));

const CustomStatusChip: React.FC<CustomStatusChipProps> = ({ value }) => {
  const status = statusMapping[value] || "Unknown";

  return <CustomChip label={status} status={status} />;
};

export default CustomStatusChip;
