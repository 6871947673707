import React, { useState, useEffect } from "react";
import {
  TextField,
  Modal,
  IconButton,
  Backdrop,
  Box,
  DialogTitle,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useQueryClient } from "react-query";
import { queryConfigs } from "../../hooks/config";
import { useMutationQuery } from "../../hooks/queryHook";
import { showNotification } from "../../services/helper";
import { Height } from "@material-ui/icons";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  height: 300,
  bgcolor: "background.paper",
  backdropFilter: "blur(3px)",
  borderRadius: 2,
  overflowY: "auto",
};
interface DesignationRow {
  id: number;
  created_on?: string;
  name: string;
}

type EditDesignationProps = {
  open: boolean;
  onClose: () => void;
  initialData?: DesignationRow | null;
};

const EditDesignation = ({
  open,
  onClose,
  initialData,
}: EditDesignationProps) => {
  const [formValues, setFormValues] = useState<DesignationRow | null>(null);

  useEffect(() => {
    if (initialData) {
      setFormValues({ ...initialData });
    }
  }, [initialData]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues((prevState) =>
      prevState ? { ...prevState, [name]: value } : null
    );
  };

  const queryClient = useQueryClient();
  const { invalidateKey, mutationFn } = queryConfigs.updateDesignation;
  const { mutate } = useMutationQuery({
    key: invalidateKey,
    func: mutationFn,
    onSuccess: () => {
      showNotification("success", "Designation updated successfully");
      onClose();
    },
  });

  const handleSubmit = () => {
    // Ensure that formValues is defined and not null
    if (!formValues || formValues.id == null) {
      showNotification("error", "Invalid designation ID.");
      return;
    }

    const updatedState = {
      name: formValues.name,
    };

    // Check if name is empty or NaN
    if (
      !updatedState.name ||
      updatedState.name.trim() === ""
      // ||isNaN(updatedState.name as any)
    ) {
      showNotification("error", "Name is required.");
      return;
    }

    // Proceed with mutation if validations pass
    mutate({ id: formValues.id, body: updatedState });
    onClose();
    handleClearForm();
  };

  const handleClearForm = () => {
    setFormValues(initialData || { id: 0, name: "" });
  };

  const handleCancel = () => {
    onClose();
    handleClearForm();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Box sx={style}>
        <div className="bg-[#1C1C1C] flex justify-between items-center px-4 sticky top-0 z-20">
          <DialogTitle
            sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
          >
            Update Designation
          </DialogTitle>
          <IconButton onClick={onClose} sx={{ color: "white" }}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="mt-8">
          <section className=" flex items-center justify-center">
            <TextField
              name="name"
              label="Name"
              value={formValues?.name || ""}
              onChange={handleInputChange}
              margin="dense"
              variant="outlined"
              InputLabelProps={{
                style: {
                  color: "gray",
                  fontFamily: "Poppins, sans-serif",
                  fontSize: "13px",
                },
              }}
              InputProps={{
                classes: {
                  root: "text-black",
                  input: "h-8 py-0",
                },
              }}
              inputProps={{
                className: "text-black",
              }}
              sx={{
                width: "70%",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "1.5rem",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "inherit",
                },
                "& .MuiInputBase-input": {
                  height: "1rem",
                  padding: "1rem 1rem",
                },
              }}
              className="rounded-3xl bg-white"
            />
          </section>
          <div className="flex justify-center mt-4 items-center">
            <div className="mr-12">
              <button
                onClick={handleSubmit}
                className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group"
              >
                <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#4CAF50] opacity-[3%]"></span>
                <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#4CAF50] opacity-100 group-hover:-translate-x-8"></span>
                <span className="relative w-full text-left text-[#4CAF50] transition-colors duration-200 ease-in-out group-hover:text-white">
                  Update
                </span>
                <span className="absolute inset-0 border-2 border-[#4CAF50] rounded-lg"></span>
              </button>
            </div>
            <div className="ml-12">
              <button
                onClick={handleCancel}
                className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group"
              >
                <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#d00000] opacity-[3%]"></span>
                <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#d00000] opacity-100 group-hover:-translate-x-8"></span>
                <span className="relative w-full text-left text-[#d00000] transition-colors duration-200 ease-in-out group-hover:text-white">
                  Cancel
                </span>
                <span className="absolute inset-0 border-2 border-[#d00000] rounded-lg"></span>
              </button>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default EditDesignation;
