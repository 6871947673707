import React, { ChangeEvent, useState } from "react";
import TextField from "@mui/material/TextField";
import { Backdrop, Box, Modal, Typography } from "@mui/material";
import dayjs from "dayjs";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

type medicines = {
  id: number;
  name: string;
};

type PtRow = {
  admission_id: number;
  id: number;
  created_on: string;
  name: string;
  patient_name: string;
  mobile: string;
  age: string;
  diagnosis: string;
  marital_status: string;
  address: string;
  district: string;
  pincode: string;
  emergency_contact: string;
  gender: string;
  remark: string;
  admission_charge: string;
  admission_fee?: number; // Added field
  admission_date?: string;
  discharge_date?: string;
  referred_by?: number;
  is_discharged?: boolean;
  medicines?: medicines[];
};

type DischargeProps = {
  open: boolean;
  onClose: () => void;

  admission?: PtRow | null | undefined;
};

interface Organisation {
  name: string;
  moto: string;
  address: string;
}

interface DischargeFormData {
  organisation: Organisation;
  name: string;
  address: string;
  admission_date: string;
  diagnosis: string;
  medicines: string;
  age: number;
  recommendation?: string;
  guardianFamily?: string;
  guardianName?: string;
  guardianAddress?: string;
  guardianRelationship?: string;
  guardianSignature?: string;
  discharged_on?: string;
}

interface DischargeFormProps {
  data: DischargeFormData;
}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 600,
  bgcolor: "background.paper",
  backdropFilter: "blur(3px)",
  borderRadius: 2,
  overflowY: "auto",
};
const DischargeSummaryModal: React.FC<DischargeProps> = ({
  open,
  onClose,
  admission,
}) => {
  const [formData, setFormData] = useState({
    name: "",
    address: "",
    relationship: "",
  });

  // Handle change function to update state
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Box
        p={4}
        // sx={{
        //   width: "210mm",
        //   height: "297mm",
        //   margin: "auto",
        //   border: "1px solid black",
        //   boxSizing: "border-box",
        //   fontSize: "12pt",
        // }}
        sx={style}
      >
        <Typography variant="h4" align="center" fontWeight="bold">
          Organsation Name
        </Typography>
        <Typography variant="body1" align="center">
          Organsation Moto
        </Typography>
        <Typography variant="body1" align="center">
          Organsation Address
        </Typography>
        <Typography variant="h6" align="center" fontWeight="bold" mt={6}>
          DISCHARGE CERTIFICATE
        </Typography>

        <Box mt={4} sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <section className=" flex items justify-between px-6">
            <div>
              <div className=" flex items-center">
                <p className=" text-sm mr-2">Patient Name: {""}</p>
                <p className="text-sm">{admission?.patient_name}</p>
              </div>
              <div className=" flex items-center">
                <p className=" text-sm mr-2">Age: {""}</p>
                <p className="text-sm">{admission?.age}</p>
              </div>
              <div className=" flex items-center">
                <p className=" text-sm mr-2">Mobile: {""}</p>
                <p className="text-sm">{admission?.mobile}</p>
              </div>
              <div className=" flex items-center">
                <p className=" text-sm mr-2">Emergency Contact: {""}</p>
                <p className="text-sm">{admission?.emergency_contact}</p>
              </div>
              <div className=" flex items-center">
                <p className=" text-sm mr-2">Diagnosis: {""}</p>
                <p className="text-sm">{admission?.diagnosis}</p>
              </div>
            </div>
            <div className="w-[40%]">
              <div className=" flex ">
                <p className=" text-sm mr-2">Address: {""}</p>
                <p className="text-sm">
                  {admission?.address}, {admission?.district},{" "}
                  {admission?.pincode}
                </p>
              </div>
              <div className=" flex ">
                <p className=" text-sm mr-2">Addmitted On: {""}</p>
                <p className="text-sm">
                  {dayjs(admission?.admission_date).format("DD-MMM-YYYY")}
                </p>
              </div>
              <div className=" flex ">
                <p className=" text-sm mr-2">Discharged On: {""}</p>
                <p className="text-sm">
                  {dayjs(admission?.discharge_date).format("DD-MMM-YYYY")}
                </p>
              </div>
            </div>
          </section>

          <TextField
            label="Remarks"
            value={admission?.remark || ""}
            name="results"
            multiline
            rows={2}
            fullWidth
          />
          <TextField
            label="Results-Cure/Recovered/Otherwise"
            name="results"
            multiline
            rows={4}
            fullWidth
          />
          <TextField
            label="Brief Clinical Note"
            name="brief_clinical_note"
            multiline
            rows={4}
            fullWidth
          />
          <TextField
            label="Medication"
            name="medicines"
            value={admission?.medicines}
            multiline
            rows={4}
            fullWidth
          />
          <TextField
            label="Recommendation"
            name="recommendation"
            // value={admission?.recommendation}
            multiline
            rows={4}
            fullWidth
          />

          <section>
            <p className="text-lg font-semibold">
              Guardian/Family with whom the patient is discharged
            </p>
            <section className="flex flex-col ">
              <div className="flex items-center space-x-4">
                <p className="text-sm">Name: </p>
                <TextField
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Name"
                  variant="outlined"
                  size="small"
                  sx={{
                    border: "none",
                    "& fieldset": { border: "none" }, // Removes border
                  }}
                />
              </div>
              <div className="flex items-center space-x-4">
                <p className="text-sm">Address: </p>
                <TextField
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  placeholder="Address"
                  variant="outlined"
                  size="small"
                  sx={{
                    border: "none",
                    "& fieldset": { border: "none" }, // Removes border
                  }}
                />
              </div>
              <div className="flex items-center space-x-4">
                <p className="text-sm">Relationship: </p>
                <TextField
                  name="relationship"
                  value={formData.relationship}
                  onChange={handleChange}
                  placeholder="Relationship"
                  variant="outlined"
                  size="small"
                  sx={{
                    border: "none",
                    "& fieldset": { border: "none" }, // Removes border
                  }}
                />
              </div>
            </section>
            <div className="mt-2">
              <p>Signature:</p>
            </div>
          </section>
        </Box>

        <Box mt={4} display="flex" justifyContent="flex-end">
          <Typography variant="body1" fontWeight="bold">
            Signature of the Project Director
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};

export default DischargeSummaryModal;
