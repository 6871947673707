import React, { useEffect, useState } from "react";
import {
  Chip,
  TableCell,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import CommonTable from "../CommonTable";
import { useGetQuery, useMutationQuery } from "../../hooks/queryHook";
import { queryConfigs } from "../../hooks/config";
import AddBilling from "./AddBilling";
import dayjs from "dayjs";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate, useParams } from "react-router-dom";
import { BillingData, Emp } from "../../types/common";
import EditBilling from "./EditBilling";
import DeleteModal from "../DeleteModal";
import { showNotification } from "../../services/helper";
import { FaEdit } from "react-icons/fa";
import {
  MdDeleteForever,
  MdLocalPrintshop,
  MdOutlinePendingActions,
} from "react-icons/md";
import AddBillingByMonth from "./AddBillingByMonth";
import { FaCashRegister } from "react-icons/fa6";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

type BillingProps = {
  pageTitle: string;
};

type Header = {
  name: string;
};
interface DataItem {
  id: number;
  month: number;
  year: number;
}
const userHeaders: Header[] = [
  { name: "Due Month" },
  { name: "Due" },
  { name: "Extra Charge" },
  { name: "Discount" },
  { name: "Paid Amount" },
  { name: "Paid On" },
  { name: "Status" },
  { name: "Action" },
];

const BillingHistory: React.FC<BillingProps> = ({ pageTitle }) => {
  const userHeaderNames: string[] = userHeaders.map((header) => header.name);
  const navigate = useNavigate();
  const { id } = useParams();

  const patientIdNumber = id ? parseInt(id, 10) : undefined;
  const { queryKey, queryFn } = queryConfigs.getAllBillingsForPatient;
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState({ offset: 0, limit: 10 });
  const limit = pagination.limit;

  const { data, refetch, isLoading } = useGetQuery({
    key: queryKey,
    func: queryFn,
    params: {
      offset: (currentPage - 1) * pagination.limit,
      limit: pagination.limit,
      patient: patientIdNumber,
    },
    isEnabled: patientIdNumber ? true : false,
  });
  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => {
    setCurrentPage(page + 1);
    setPagination((prev) => ({
      ...prev,
      offset: page * pagination.limit,
    }));
  };

  const [isAddBillingOpen, setIsAddBillingOpen] = useState(false);

  const handleAddClick = () => {
    setIsAddBillingOpen(true);
  };

  const [editValues, setEditValues] = useState({});
  const [viewOpen, setViewOpen] = useState(false);

  const handleEditClick = (bill: any) => {
    setViewOpen(true);
    if (bill.id > 0) {
      setEditValues({ ...bill });
    }
  };
  const { queryKey: employees, queryFn: employeesFunc } =
    queryConfigs.useGetEmployees;
  const { data: employeeData, isLoading: isEmployeeLoaiding } = useGetQuery({
    key: employees,
    func: employeesFunc,
  });

  const { invalidateKey, mutationFn } = queryConfigs.deleteBilling;
  const { mutate: deleteBilling, isPending } = useMutationQuery({
    key: invalidateKey,
    func: mutationFn,
    onSuccess: () => {
      showNotification("success", "Patient deleted successfully");
      handleDeleteClose();
    },
    onError: (error) => {
      showNotification("error", error.message);
    },
  });
  const [toDelete, setToDelete] = useState<number | null>(null);
  const [open, setOpen] = useState(false);
  const handleSelectDelete = (id: number) => {
    setToDelete(id);
    setOpen(true);
  };
  const handleDeleteClose = () => {
    setToDelete(null);
    setOpen(false);
  };

  const handleDelete = () => {
    if (toDelete !== null) {
      deleteBilling(toDelete);
    }
  };
  const handleRefetch = () => {
    refetch();
  };
  return (
    <div className="w-full overflow-hidden mx-auto relative h-screen">
      <CommonTable
        refetchIcon={true}
        onRefetchClick={handleRefetch}
        tableHeaders={userHeaderNames}
        totalRows={data?.count || 0}
        colSpan={userHeaders.length}
        pageTitle={pageTitle}
        marginTop={16}
        showInchargeFilter={false}
        showDesignationFilter={false}
        children3={
          <TablePagination
            component="div"
            count={data?.count || 0}
            page={currentPage - 1}
            rowsPerPage={limit}
            onPageChange={handlePageChange}
            onRowsPerPageChange={(event) => {
              const newLimit = parseInt(event.target.value, 10);
              setPagination((prev) => ({
                ...prev,
                limit: newLimit,
                offset: 0,
              }));
              setCurrentPage(1);
            }}
            sx={{ color: "#38a3a5", font: "Poppins, sans-serif" }}
          />
        }
      >
        {data?.list?.map((billing: BillingData) => (
          <TableRow key={billing.id}>
            <TableCell align="center" className="capitalize">
              {dayjs()
                .month(billing.month - 1)
                .format("MMMM")}
              -{billing.year}
            </TableCell>
            <TableCell align="center" className="capitalize">
              ₹{billing.dues}/-
            </TableCell>
            <TableCell align="center" className="capitalize">
              ₹{billing.extra_charge}/-
            </TableCell>
            <TableCell align="center" className="capitalize">
              ₹{billing.deducted_amount}/-
            </TableCell>
            <TableCell align="center" className="capitalize">
              ₹{billing.paid_amount}/-
            </TableCell>

            <TableCell align="center" className="capitalize">
              {billing.paid_on
                ? dayjs(billing.paid_on).format("DD-MMM-YYYY")
                : "NOT PAID"}
            </TableCell>
            <TableCell align="center" className="capitalize">
              {billing.dues +
                billing.extra_charge -
                (billing.deducted_amount + billing.paid_amount) ===
              0 ? (
                <Chip label="Paid" color="success" variant="outlined" />
              ) : (
                <Chip label="Pending" color="warning" variant="outlined" />
              )}
            </TableCell>

            <TableCell align="center" className="capitalize space-x-2">
              <Tooltip title="Print Bill" arrow>
                <button
                  onClick={() => handleEditClick(billing)}
                  className="edit-btn"
                >
                  <MdLocalPrintshop />
                </button>
              </Tooltip>

              {/* <Tooltip title="Delete" arrow>
                <button
                  onClick={() => handleSelectDelete(billing.id)}
                  className="del-btn"
                >
                  <MdDeleteForever />
                </button>
              </Tooltip> */}
            </TableCell>
          </TableRow>
        ))}
      </CommonTable>
    </div>
  );
};

export default BillingHistory;
