import React, { useState } from "react";
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
  SelectChangeEvent,
  Backdrop,
  Modal,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { queryConfigs } from "../../hooks/config";
import { useMutationQuery } from "../../hooks/queryHook";
import { showNotification } from "../../services/helper";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

type PtRow = {
  id: number;
  created_on: string;
  name: string;
  mobile: string;
  age: string;
  diagnosis: string;
  marital_status: string;
  address: string;
  district: string;
  pincode: string;
  emergency_contact: string;
  gender: string;
  remark: string;
  admission_charge: string;
  admission_date: string;
  referred_by: string;
};

type AddBillingProps = {
  open: boolean;
  onClose: () => void;
  ptData: PtRow | null;
};

interface FormValues {
  patient: null | number;
  admission_fee: null | number;
  admission_date: null | string;
}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: 550,
  bgcolor: "background.paper",
  backdropFilter: "blur(3px)",
  borderRadius: 2,
  overflowY: "auto",
};
const AddInPatient = ({ open, onClose, ptData }: AddBillingProps) => {
  const { queryFn, queryKey, invalidateKey } = queryConfigs.useAddAdmission;
  const { mutate } = useMutationQuery({
    key: queryKey,
    func: queryFn,
    invalidateKeys: invalidateKey,
    onSuccess: () => {
      showNotification("success", "Admission added successfully");
      onClose();
      handleClear();
    },
  });

  const [formValues, setFormValues] = useState<FormValues>({
    patient: null,
    admission_fee: null,
    admission_date: null,
  });

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value ? Number(value) : null,
    }));
  };

  const handleSelectChange = (event: SelectChangeEvent<number | null>) => {
    const { name, value } = event.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value ? Number(value) : null,
    }));
  };

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    const formattedDate = dayjs(date).format("YYYY-MM-DD HH:mm:ss");
    setFormValues({ ...formValues, admission_date: formattedDate });
  };
  const validateForm = () => {
    const { admission_fee, admission_date } = formValues;
    const errors: { field: string; message: string }[] = [];

    if (admission_fee === null || admission_fee < 0) {
      errors.push({
        field: "admission_fee",
        message: "Admission fee is required",
      });
    }
    if (admission_date === null || admission_date === "") {
      errors.push({
        field: "admission_date",
        message: "Admission date is required",
      });
    }

    return errors.length > 0 ? errors.map((e) => e.message).join(", ") : null;
  };

  const handleSubmit = () => {
    const validationErrors = validateForm();
    if (validationErrors) {
      showNotification("error", validationErrors);
      return;
    }
    if (ptData?.id) {
      const updatedState = { ...formValues, patient: ptData?.id };

      mutate(updatedState);
    } else {
      showNotification("error", "Invalid Requst");
    }
  };

  const handleClear = () => {
    setSelectedDate(null);
    setFormValues({
      patient: null,
      admission_fee: null,
      admission_date: null,
    });
  };

  const handleCancel = () => {
    onClose();
    handleClear();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Box sx={style}>
        <div className="bg-[#1C1C1C] sticky top-0 z-30 flex justify-between items-center px-4">
          <DialogTitle
            sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
          >
            Add New Admissions
          </DialogTitle>
          <IconButton onClick={onClose} sx={{ color: "white" }}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent>
          <TextField
            required
            name="patient"
            label="Patient"
            disabled
            value={ptData?.name}
            fullWidth
            margin="dense"
            variant="outlined"
            InputLabelProps={{
              style: {
                color: "gray",
                fontFamily: "Poppins, sans-serif",
                fontSize: "13px",
              },
            }}
            InputProps={{
              inputProps: {
                maxLength: 10,
                pattern: "[0-9]*",
              },
              classes: {
                root: "text-black",
                input: "h-10 py-0",
              },
            }}
            inputProps={{
              className: "text-black font-Poppins",
            }}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                borderRadius: "1.5rem",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "inherit",
              },
              "& .MuiInputBase-input": {
                height: "1rem",
                padding: "1rem 1rem",
              },
            }}
            onInput={(e) => {
              const input = e.target as HTMLInputElement;
              input.value = input.value.replace(/[^0-9]/g, "");
            }}
          />

          <TextField
            required
            name="admission_fee"
            label="Admission Fee"
            value={formValues.admission_fee}
            onChange={handleInputChange}
            fullWidth
            margin="dense"
            variant="outlined"
            InputLabelProps={{
              style: {
                color: "gray",
                fontFamily: "Poppins, sans-serif",
                fontSize: "13px",
              },
            }}
            InputProps={{
              inputProps: {
                maxLength: 10,
                pattern: "[0-9]*",
              },
              classes: {
                root: "text-black",
                input: "h-10 py-0",
              },
            }}
            inputProps={{
              className: "text-black font-Poppins",
            }}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                borderRadius: "1.5rem",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "inherit",
              },
              "& .MuiInputBase-input": {
                height: "1rem",
                padding: "1rem 1rem",
              },
            }}
            onInput={(e) => {
              const input = e.target as HTMLInputElement;
              input.value = input.value.replace(/[^0-9]/g, "");
            }}
          />
          <div style={{ zIndex: 999 }}>
            <DatePicker
              required
              selected={selectedDate ? dayjs(selectedDate).toDate() : null}
              onChange={handleDateChange}
              dateFormat="dd, MMMM, yyyy"
              placeholderText="Admission Date"
              maxDate={dayjs().toDate()}
              className="px-6 py-4 w-[100%] h-12 border border-gray-200 rounded-3xl placeholder-gray-500 mt-2"
              wrapperClassName="w-full"
            />
          </div>
        </DialogContent>
        <div className="flex justify-center mb-4 items-end flex-grow">
          <DialogActions>
            <div className="mr-12">
              <button
                onClick={handleSubmit}
                className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group"
              >
                <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#4CAF50] opacity-[3%]"></span>
                <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#4CAF50] opacity-100 group-hover:-translate-x-8"></span>
                <span className="relative w-full text-left text-[#4CAF50] transition-colors duration-200 ease-in-out group-hover:text-white">
                  Add
                </span>
                <span className="absolute inset-0 border-2 border-[#4CAF50] rounded-lg"></span>
              </button>
            </div>
            <div className="ml-12">
              <button
                onClick={handleCancel}
                className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group"
              >
                <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#d00000] opacity-[3%]"></span>
                <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#d00000] opacity-100 group-hover:-translate-x-8"></span>
                <span className="relative w-full text-left text-[#d00000] transition-colors duration-200 ease-in-out group-hover:text-white">
                  Cancel
                </span>
                <span className="absolute inset-0 border-2 border-[#d00000] rounded-lg"></span>
              </button>
            </div>
          </DialogActions>
        </div>
      </Box>
    </Modal>
  );
};

export default AddInPatient;
