import React, { useEffect, useState } from "react";
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Modal,
  Backdrop,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { queryConfigs } from "../../hooks/config";
import { useGetQuery, useMutationQuery } from "../../hooks/queryHook";
import { showNotification } from "../../services/helper";
import { useQueryClient } from "@tanstack/react-query";
import { TDesignation } from "../../types/common";
import { useNavigate, useParams } from "react-router-dom";
import { stringify } from "querystring";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

type EditVitalProp = {
  open: boolean;
  onClose: () => void;
  vital: any;
};

type EmpRow = {
  id: number;
  name: string;
  created_on: string;
  email: string;
  mobile: string;
  address: string;
  designation: number;
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: 500,
  bgcolor: "background.paper",
  backdropFilter: "blur(3px)",
  borderRadius: 2,
  overflowY: "auto",
};

const EditVital = ({ open, onClose, vital }: EditVitalProp) => {
  const [selectedDate, setSelectedDate] = useState<Date | null | undefined>(
    null
  );
  useEffect(() => {
    if (vital.administered_on) {
      setSelectedDate(vital.administered_on);
    }
  }, [vital]);
  const params = useParams();
  const pTId = parseInt(params.id || "0");
  const [formValues, setFormValues] = useState({
    patient: 0,
    blood_high: "",
    blood_low: "",
    pulse_rate: "",
    bio_func: "",
    spo: "",
    weight: "",
    in_charge: "",
    administered_on: "",
  });

  useEffect(() => {
    if (vital.id) {
      setFormValues({ ...vital });
    }
  }, [vital.id, vital]);

  const { queryKey: vitals, queryFn: vitalsFunc } = queryConfigs.getVitals;
  const { data: vitalsData, isLoading: isVitalsLoading } = useGetQuery({
    key: vitals,
    func: vitalsFunc,
    params: { offset: 0, limit: 10, patient: pTId },
    isEnabled: params.id ? true : false,
  });

  const { queryKey: employees, queryFn: employeesFunc } =
    queryConfigs.useGetEmployees;
  const { data: employeeData } = useGetQuery({
    key: employees,
    func: employeesFunc,
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const parsedValue = name === "weight" ? parseInt(value, 10) : value;
    setFormValues((prevState) => ({ ...prevState, [name]: parsedValue }));
  };

  const { mutationFn, invalidateKey } = queryConfigs.updateVital;
  const { mutate } = useMutationQuery({
    key: invalidateKey,
    func: mutationFn,
    onSuccess: () => {
      showNotification("success", "Vitals updated successfully");
      onClose();
    },
  });

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setFormValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleClearForm = () => {
    setFormValues({
      patient: 0,
      blood_high: "",
      blood_low: "",
      pulse_rate: "",
      bio_func: "",
      spo: "",
      weight: "",
      in_charge: "",
      administered_on: "",
    });
  };

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const validateForm = () => {
    const { patient, weight, in_charge } = formValues;

    const errors: string[] = [];

    // Validate Patient
    if (!patient || patient <= 0) {
      errors.push("Patient ID is required and must be greater than 0");
    }

    // Validate Weight
    if (weight === undefined || isNaN(Number(weight)) || Number(weight) <= 0) {
      errors.push("Weight is required and must be a number greater than 0");
    }

    // Validate In Charge
    if (!in_charge || parseInt(in_charge) <= 0) {
      errors.push("In Charge is required and must be greater than 0");
    }

    if (errors.length > 0) {
      return errors.join(", ");
    }

    return null;
  };

  const handleSubmit = () => {
    const validationError = validateForm();

    if (validationError) {
      setErrorMessage(validationError);

      showNotification("error", "Fill all required fields");
      return;
    }
    const updatedForm = {
      ...formValues,
      in_charge: parseInt(formValues.in_charge, 10),
      weight: parseInt(formValues.weight, 10 || null),
      administered_on: formValues.administered_on,
    };
    mutate({ body: updatedForm, id: vital.id });

    // onClose();
    // handleClearForm()
  };

  const handleCancel = () => {
    onClose();
    handleClearForm();
  };

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    if (date) {
      setFormValues((prevState) => ({
        ...prevState,
        administered_on: dayjs(date).format(),
      }));
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Box sx={style}>
        <div className="bg-[#1C1C1C] flex justify-between items-center px-4">
          <DialogTitle
            sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
          >
            Update Vital Record
          </DialogTitle>
          <IconButton onClick={onClose} sx={{ color: "white" }}>
            <CloseIcon />
          </IconButton>
        </div>
        {vital.id && (
          <section className="space-y-5 p-5">
            <div className="flex items-center justify-center gap-4">
              <div style={{ zIndex: "999" }}>
                <DatePicker
                  selected={selectedDate ? dayjs(selectedDate).toDate() : null}
                  onChange={handleDateChange}
                  dateFormat="dd, MMMM, yyyy"
                  placeholderText="Vital Recorded Date"
                  maxDate={dayjs().toDate()}
                  className="px-6 py-4 w-[310px] h-12 border border-gray-200 rounded-3xl placeholder-gray-500"
                  popperPlacement="bottom-start"
                  wrapperClassName="w-full"
                />
              </div>
              <TextField
                name="blood_high"
                label="Blood High"
                value={formValues.blood_high || ""}
                onChange={handleInputChange}
                fullWidth
                margin="dense"
                variant="outlined"
                InputLabelProps={{
                  style: {
                    color: "gray",
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "13px",
                  },
                }}
                InputProps={{
                  inputProps: {
                    maxLength: 10,
                    pattern: "[0-9]*",
                  },
                  classes: {
                    root: "text-black",
                    input: "h-10 py-0",
                  },
                }}
                inputProps={{
                  className: "text-black font-Poppins",
                }}
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "1.5rem",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "inherit",
                  },
                  "& .MuiInputBase-input": {
                    height: "1rem",
                    padding: "1rem 1rem",
                  },
                }}
                onInput={(e) => {
                  const input = e.target as HTMLInputElement;
                  input.value = input.value.replace(/[^0-9]/g, "");
                }}
              />
              <TextField
                name="blood_low"
                label="Blood Low"
                value={formValues.blood_low || ""}
                onChange={handleInputChange}
                fullWidth
                margin="dense"
                variant="outlined"
                InputLabelProps={{
                  style: {
                    color: "gray",
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "13px",
                  },
                }}
                InputProps={{
                  inputProps: {
                    maxLength: 10,
                    pattern: "[0-9]*",
                  },
                  classes: {
                    root: "text-black",
                    input: "h-10 py-0",
                  },
                }}
                inputProps={{
                  className: "text-black font-Poppins",
                }}
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "1.5rem",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "inherit",
                  },
                  "& .MuiInputBase-input": {
                    height: "1rem",
                    padding: "1rem 1rem",
                  },
                }}
                onInput={(e) => {
                  const input = e.target as HTMLInputElement;
                  input.value = input.value.replace(/[^0-9]/g, "");
                }}
              />
            </div>
            <div className="flex items-center justify-center gap-4">
              <TextField
                name="pulse_rate"
                label="Pulse Rate"
                value={formValues.pulse_rate || ""}
                onChange={handleInputChange}
                fullWidth
                margin="dense"
                variant="outlined"
                InputLabelProps={{
                  style: {
                    color: "gray",
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "13px",
                  },
                }}
                InputProps={{
                  inputProps: {
                    maxLength: 10,
                    pattern: "[0-9]*",
                  },
                  classes: {
                    root: "text-black",
                    input: "h-10 py-0",
                  },
                }}
                inputProps={{
                  className: "text-black font-Poppins",
                }}
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "1.5rem",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "inherit",
                  },
                  "& .MuiInputBase-input": {
                    height: "1rem",
                    padding: "1rem 1rem",
                  },
                }}
                onInput={(e) => {
                  const input = e.target as HTMLInputElement;
                  input.value = input.value.replace(/[^0-9]/g, "");
                }}
              />
              <TextField
                name="spo"
                label="SPO2"
                value={formValues.spo || ""}
                onChange={handleInputChange}
                fullWidth
                margin="dense"
                variant="outlined"
                InputLabelProps={{
                  style: {
                    color: "gray",
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "13px",
                  },
                }}
                InputProps={{
                  inputProps: {
                    maxLength: 10,
                    pattern: "[0-9]*",
                  },
                  classes: {
                    root: "text-black",
                    input: "h-10 py-0",
                  },
                }}
                inputProps={{
                  className: "text-black font-Poppins",
                }}
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "1.5rem",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "inherit",
                  },
                  "& .MuiInputBase-input": {
                    height: "1rem",
                    padding: "1rem 1rem",
                  },
                }}
                onInput={(e) => {
                  const input = e.target as HTMLInputElement;
                  input.value = input.value.replace(/[^0-9]/g, "");
                }}
              />
              <TextField
                name="weight"
                label="Weight"
                value={formValues.weight || 0}
                onChange={handleInputChange}
                fullWidth
                margin="dense"
                variant="outlined"
                InputLabelProps={{
                  style: {
                    color: "gray",
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "13px",
                  },
                }}
                InputProps={{
                  inputProps: {
                    maxLength: 10,
                    pattern: "[0-9]*",
                  },
                  classes: {
                    root: "text-black",
                    input: "h-10 py-0",
                  },
                }}
                inputProps={{
                  className: "text-black font-Poppins",
                }}
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "1.5rem",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "inherit",
                  },
                  "& .MuiInputBase-input": {
                    height: "1rem",
                    padding: "1rem 1rem",
                  },
                }}
                onInput={(e) => {
                  const input = e.target as HTMLInputElement;
                  input.value = input.value.replace(/[^0-9]/g, "");
                }}
              />
            </div>
            <TextField
              name="bio_func"
              label="Biological Functions"
              value={formValues.bio_func || ""}
              onChange={handleInputChange}
              fullWidth
              margin="dense"
              multiline
              maxRows={2}
              variant="outlined"
              InputLabelProps={{
                style: {
                  color: "gray",
                  fontFamily: "Poppins, sans-serif",
                  fontSize: "13px",
                },
              }}
              InputProps={{
                classes: {
                  root: "text-black",
                  input: "h-8 py-0",
                },
              }}
              inputProps={{
                className: "text-black",
              }}
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "1.5rem",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "inherit",
                },
                "& .MuiInputBase-input": {
                  height: "1rem",
                  padding: "1rem 1rem",
                  textTransform: "capitalize",
                },
              }}
            />

            <FormControl fullWidth margin="dense" variant="outlined">
              <InputLabel style={{ color: "#000" }}>
                Select In Charge
              </InputLabel>
              <Select
                name="in_charge"
                value={formValues?.in_charge}
                onChange={handleSelectChange}
                label="Select In Charge"
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "1.5rem",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "inherit",
                    borderRadius: "1.5rem",
                  },
                  "& .MuiInputBase-input": {
                    height: "1rem",
                    padding: "1rem 1rem",
                    textTransform: "capitalize",
                  },
                  "& .MuiSelect-icon": {
                    color: "gray",
                  },
                }}
              >
                {employeeData?.list?.map((employee: EmpRow) => (
                  <MenuItem key={employee.id} value={employee.id}>
                    {employee.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </section>
        )}
        <div className="flex justify-center mb-4 items-center">
          <DialogActions>
            <div className="mr-12">
              <button
                onClick={handleSubmit}
                className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group"
              >
                <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#4CAF50] opacity-[3%]"></span>
                <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#4CAF50] opacity-100 group-hover:-translate-x-8"></span>
                <span className="relative w-full text-left text-[#4CAF50] transition-colors duration-200 ease-in-out group-hover:text-white">
                  Save
                </span>
                <span className="absolute inset-0 border-2 border-[#4CAF50] rounded-lg"></span>
              </button>
            </div>
            <div className="ml-12">
              <button
                onClick={handleCancel}
                className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group"
              >
                <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#d00000] opacity-[3%]"></span>
                <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#d00000] opacity-100 group-hover:-translate-x-8"></span>
                <span className="relative w-full text-left text-[#d00000] transition-colors duration-200 ease-in-out group-hover:text-white">
                  Cancel
                </span>
                <span className="absolute inset-0 border-2 border-[#d00000] rounded-lg"></span>
              </button>
            </div>
          </DialogActions>
        </div>
      </Box>
    </Modal>
  );
};

export default EditVital;
