import { useEffect, useState } from "react";
import { CgMenuRightAlt, CgMenu } from "react-icons/cg";
import { Menu, MenuItem, IconButton, Tooltip, Collapse } from "@mui/material";
import {
  MdAccountCircle,
  MdDashboard,
  MdSick,
  MdReceipt,
  MdExpandLess,
  MdExpandMore,
  MdOutlineManageSearch,
} from "react-icons/md";
import { IoReceiptOutline } from "react-icons/io5";
import { FaUserInjured } from "react-icons/fa";
import { FaUserDoctor } from "react-icons/fa6";
import { GiMedicines } from "react-icons/gi";
import {
  NavLink,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Dashboard from "../components/dashboard/Dashboard";
import Users from "../components/user/Users";
import Logo from "../assets/flamingo.png";
import Profile from "../components/profile/Profile";
import Patients from "../components/patient/Patients";
import Medicines from "../components/medicine/Medicines";
import Doctors from "../components/doctors/Doctor";
import Employees from "../components/employees/Employees";
import Vitals from "../components/vitalRecord/Vitals";
import Treatment from "../components/treatmentChart/Treatment";
import Billing from "../components/billing/Billing";
import BillingLedger from "../components/billingLedger/BillingLedger";
import Designations from "../components/designation/Designations";
import {
  deleteAll,
  deleteItem,
  getItem,
  showNotification,
} from "../services/helper";
import { logoutAPI, setAuthHeader } from "../services/api";
import { queryConfigs } from "../hooks/config";
import { useMutationQuery } from "../hooks/queryHook";
import { Token } from "@mui/icons-material";
import Admission from "../components/inPatient/InPatient";
import UploadDocument from "../components/uploadDocument/UploadDocument";
import DischargeTable from "../components/discharge/DischargeTable";
import BillingHistory from "../components/billing/BillingHistory";
import PaymentHistory from "../components/payments/PaymentHistory";
import { FaRegUserCircle } from "react-icons/fa";
import { MdLogout } from "react-icons/md";

interface SubmenuItem {
  id: number;
  link: string;
  label: string;
  subLabel: string;
  viewEnabled: boolean;
}

interface LinkItem {
  id: number;
  link?: string;
  icon: JSX.Element;
  label: string;
  viewEnabled: boolean;
  submenu?: SubmenuItem[];
}

const AdminPage = () => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [anchorProfileMenu, setAnchorProfileMenu] =
    useState<null | HTMLElement>(null);
  const [expandedSubmenu, setExpandedSubmenu] = useState<number | null>(null);
  const location = useLocation();
  const navigate = useNavigate();

  const getPageTitle = () => {
    return "Ningthoujam Healthcare";
  };

  const pageTitle = getPageTitle();
  const isDashboard = location.pathname === "/admin";
  const isProfile = pageTitle === "Profile";

  const links: LinkItem[] = [
    {
      id: 1,
      link: "/admin",
      icon: <MdDashboard />,
      label: "Dashboard",
      viewEnabled: true,
    },
    {
      id: 2,
      link: "/admin/*",
      icon: <MdOutlineManageSearch />,
      label: "Management",
      viewEnabled: true,
      submenu: [
        {
          id: 2.1,
          link: "/admin/user",
          label: "Users",
          subLabel: "U",
          viewEnabled: true,
        },
        {
          id: 2.2,
          link: "/admin/employees",
          label: "Employees",
          subLabel: "E",
          viewEnabled: true,
        },
        {
          id: 2.3,
          link: "/admin/designations",
          label: "Designations",
          subLabel: "E",
          viewEnabled: true,
        },
        {
          id: 10,
          link: "/admin/medicine",
          subLabel: "E",
          label: "Medicines",
          viewEnabled: true,
        },
        {
          id: 3,
          link: "/admin/doctor",
          subLabel: "E",
          label: "Doctors",
          viewEnabled: true,
        },
      ],
    },

    {
      id: 5,
      link: "/admin/in-patient",
      icon: <FaUserInjured />,
      label: "In Patients",
      viewEnabled: true,
    },
    {
      id: 4,
      link: "/admin/patients",
      icon: <MdSick />,
      label: "Patients",
      viewEnabled: true,
    },

    {
      id: 6,
      link: "/admin/billingLedger",
      icon: <MdReceipt />,
      label: "Billing Ledger",
      viewEnabled: true,
    },
    {
      id: 7,
      link: "/admin/discharge-table",
      icon: <IoReceiptOutline />,
      label: "Discharge Info",
      viewEnabled: true,
    },
  ];

  useEffect(() => {
    const temp = JSON.parse(
      localStorage?.getItem("isSidebarCollapsed") || "null"
    );
    if (temp !== null) {
      setIsSidebarCollapsed(temp);
    }
  }, []);

  const toggleCollapsed = () => {
    localStorage.setItem(
      "isSidebarCollapsed",
      JSON.stringify(!isSidebarCollapsed)
    );
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorProfileMenu(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorProfileMenu(null);
  };

  const handleProfileClick = () => {
    handleProfileMenuClose();
    navigate("/admin/profile");
  };

  const handleLogout = async () => {
    const storedToken = getItem("token");
    try {
      const response = await logoutAPI();
      // Always perform cleanup actions regardless of the response
      deleteItem("organisation");
      deleteItem("user");
      deleteItem("token");
      setAuthHeader("");
      handleProfileMenuClose();
      deleteAll();
      navigate("/");
    } catch (err) {
      console.error(err);
      // Also perform cleanup actions if there's an error
      deleteItem("organisation");
      deleteItem("user");
      deleteItem("token");
      setAuthHeader("");
      handleProfileMenuClose();
      deleteAll();
      navigate("/");
    }
  };

  // const handeLogout = () => {
  //   const storedToken = getItem("token");
  //   mutate({ token: storedToken });
  //   navigate("/");

  //   if (storedToken) {
  //     deleteItem("organisation");
  //     deleteItem("user");
  //     deleteItem("token");
  //     setAuthHeader("");
  //     handleProfileMenuClose();
  //     deleteAll();
  //   }
  // };

  const handleSubmenuToggle = (id: number) => {
    setExpandedSubmenu(expandedSubmenu === id ? null : id);
  };

  return (
    <div className="w-full h-screen mx-auto relative p-4">
      {/* Fixed Navbar */}
      <div
        className={`${
          isSidebarCollapsed ? "w-[calc(100%-130px)]" : "w-[calc(100%-290px)]"
        } flex fixed top-[20px] right-[12px] rounded-xl z-50 bg-[#FCFCFE] drop-shadow-md text-white p-4 justify-between items-center transition-all duration-300 ease-in-out`}
      >
        <div className="flex items-center">
          <button onClick={toggleCollapsed} className="text-gray-600 mr-2">
            {isSidebarCollapsed ? (
              <CgMenu size={24} />
            ) : (
              <CgMenuRightAlt size={24} />
            )}
          </button>
          <h1 className="text-base ml-4 text-gray-600 capitalize font-semibold font-Poppins">
            {isDashboard || isProfile ? pageTitle : `${pageTitle}`}
          </h1>
        </div>
        <div className="flex items-center">
          {/* Profile */}
          <IconButton aria-label="show profile" onClick={handleProfileMenuOpen}>
            <MdAccountCircle style={{ color: "#354666" }} />
          </IconButton>
          <Menu
            anchorEl={anchorProfileMenu}
            open={Boolean(anchorProfileMenu)}
            onClose={handleProfileMenuClose}
          >
            <MenuItem onClick={handleProfileClick}>
              <FaRegUserCircle className="inline-block mr-2" /> Profile
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <MdLogout className="inline-block mr-2" /> Logout
            </MenuItem>
          </Menu>
        </div>
      </div>

      {/* Sidebar */}
      <div
        className={`${
          isSidebarCollapsed ? "w-[90px]" : "w-[250px]"
        } fixed h-[calc(100%-40px)] p-2 flex flex-col bg-gradient-to-b from-[#3F3F47] to-[#1C1C1C] rounded-xl top-[20px] z-10 transition-all duration-300 ease-in-out`}
      >
        {/* Logo or brand */}
        <div className="flex flex-col items-center border-b-[0.5px] border-gray-400 justify-center">
          <img
            src={Logo}
            alt="Logo"
            className={`${
              isSidebarCollapsed
                ? "h-[60px] w-[60px] mb-2"
                : "w-[90px] h-[90px]"
            } bg-white rounded-full`}
          />
          <span
            className={`${
              isSidebarCollapsed
                ? "hidden"
                : "py-2 text-white font-Poppins text-xl font-semibold"
            }`}
          >
            Flamingo
          </span>
        </div>
        {/* Navigation links */}
        <ul className="flex flex-col px-2 my-6 overflow-y-auto">
          {links.map((link) => (
            <div key={link.id}>
              {link.submenu ? (
                <div>
                  <Tooltip title={link.label} placement="right" arrow>
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        handleSubmenuToggle(link.id);
                      }}
                      className={`
                          ${
                            isSidebarCollapsed
                              ? "text-white mb-2 px-2 py-2 rounded-md justify-center cursor-pointer"
                              : "text-white px-6 mb-2 py-2 rounded-md hover:bg-[#57575d] cursor-pointer"
                          } 
                          flex items-center transition-all duration-300 ease-in-out 
                          ${
                            location.pathname.startsWith(link.link ?? "") &&
                            !location.pathname.startsWith("/admin/accounting")
                              ? "bg-green-600"
                              : ""
                          }
                      `}
                    >
                      {link.icon}
                      <span
                        className={`${
                          isSidebarCollapsed ? "hidden" : "ml-2"
                        } text-white text-sm font-thin font-Poppins`}
                      >
                        {link.label}
                      </span>
                      <IconButton
                        size="small"
                        sx={{ color: "white" }}
                        onClick={(e) => {
                          e.preventDefault();
                          handleSubmenuToggle(link.id);
                        }}
                        className="ml-auto"
                      >
                        {expandedSubmenu === link.id ? (
                          <MdExpandLess className="text-xs" />
                        ) : (
                          <MdExpandMore className="text-xs" />
                        )}
                      </IconButton>
                    </div>
                  </Tooltip>
                  <Collapse
                    in={expandedSubmenu === link.id}
                    timeout="auto"
                    unmountOnExit
                  >
                    <ul className={`${isSidebarCollapsed ? "ml-2" : "ml-6"}`}>
                      {link.submenu.map((submenu) => (
                        <li key={submenu.id} className="mb-2">
                          <Tooltip
                            title={submenu.label}
                            placement="right"
                            arrow
                          >
                            <NavLink
                              to={submenu.link}
                              className={`
                                                                text-white flex items-center px-4 py-2 rounded-md hover:bg-[#57575d] 
                                                                transition-all duration-300 ease-in-out 
                                                                ${
                                                                  location.pathname ===
                                                                  submenu.link
                                                                    ? "bg-green-600"
                                                                    : ""
                                                                }
                                                            `}
                            >
                              <span
                                className={`${
                                  isSidebarCollapsed
                                    ? "text-white text-xs font-thin font-Poppins"
                                    : "text-white text-sm font-thin font-Poppins"
                                }`}
                              >
                                {isSidebarCollapsed
                                  ? submenu.subLabel
                                  : submenu.label}
                              </span>
                            </NavLink>
                          </Tooltip>
                        </li>
                      ))}
                    </ul>
                  </Collapse>
                </div>
              ) : (
                <Tooltip title={link.label} placement="right" arrow>
                  <NavLink
                    to={link.link ?? "/"}
                    className={`
                                            ${
                                              isSidebarCollapsed
                                                ? "text-white mb-2 px-2 py-2 rounded-md justify-center"
                                                : "text-white px-6 mb-2 py-2 rounded-md hover:bg-[#57575d]"
                                            } 
                                            flex items-center transition-all duration-300 ease-in-out 
                                            ${
                                              location.pathname === link.link
                                                ? "bg-green-600"
                                                : ""
                                            }
                                        `}
                  >
                    {link.icon}
                    <span
                      className={`${
                        isSidebarCollapsed ? "hidden" : "ml-2"
                      } text-white text-sm font-thin font-Poppins`}
                    >
                      {link.label}
                    </span>
                  </NavLink>
                </Tooltip>
              )}
            </div>
          ))}
        </ul>
      </div>

      {/* Main Content */}
      <main
        className={`flex-1 min-h-screen transition-all duration-300 ease-in-out ${
          isSidebarCollapsed ? "ml-[105px]" : "ml-[275px]"
        }`}
      >
        <Routes>
          <Route
            index
            element={<Dashboard isSidebarCollapsed={isSidebarCollapsed} />}
          />
          <Route path="user" element={<Users pageTitle="Users" />} />
          <Route path="profile" element={<Profile />} />
          <Route
            path="employees"
            element={<Employees pageTitle="Employees" />}
          />
          <Route
            path="designations"
            element={<Designations pageTitle="Designations" />}
          />
          <Route
            path="patient/:id/vitals"
            element={<Vitals pageTitle="Vital Records" />}
          />
          <Route
            path="patient/:id/treatments"
            element={<Treatment pageTitle="Treatments" />}
          />
          <Route
            path="patients/billings/:id"
            element={<BillingHistory pageTitle="Billing Records" />}
          />
          <Route path="patients" element={<Patients pageTitle="Patients" />} />
          <Route
            path="medicine"
            element={<Medicines pageTitle="Medicines" />}
          />
          <Route
            path="/bills/:id/history"
            element={<PaymentHistory pageTitle="Payments" />}
          />
          <Route path="doctor" element={<Doctors pageTitle="Doctors" />} />
          <Route path="profile" element={<Profile />} />
          <Route
            path="patient/:id/documents"
            element={<UploadDocument pageTitle="Patient Documents" />}
          />
          <Route
            path="patient/id:/billing"
            element={<Billing pageTitle="Bills" />}
          />
          <Route
            path="in-patient"
            element={<Admission pageTitle="In-Patients" />}
          />
          <Route
            path="in-patient/:patientId/billings/:admissionId"
            element={<Billing pageTitle="Billing Records" />}
          />
          <Route
            path="billingLedger"
            element={<BillingLedger pageTitle="Billing Ledgers" />}
          />
          <Route
            path="discharge-table"
            element={<DischargeTable pageTitle="Discharge" />}
          />
        </Routes>
      </main>
    </div>
  );
};

export default AdminPage;
