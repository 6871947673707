import React, { useEffect, useState } from "react";
import {
  Chip,
  TableCell,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import CommonTable from "../CommonTable";
import { useGetQuery, useMutationQuery } from "../../hooks/queryHook";
import { queryConfigs } from "../../hooks/config";
import AddBilling from "./AddBilling";
import dayjs from "dayjs";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate, useParams } from "react-router-dom";
import { BillingData, Emp } from "../../types/common";
import EditBilling from "./EditBilling";
import DeleteModal from "../DeleteModal";
import { showNotification } from "../../services/helper";
import { FaEdit } from "react-icons/fa";
import {
  MdDeleteForever,
  MdLocalPrintshop,
  MdOutlinePayments,
  MdOutlinePendingActions,
} from "react-icons/md";
import AddBillingByMonth from "./AddBillingByMonth";
import AddPayment from "../payments/AddPayment";
import { BsCash } from "react-icons/bs";
import { FaCashRegister } from "react-icons/fa6";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

type BillingProps = {
  pageTitle: string;
};

type Header = {
  name: string;
};
interface DataItem {
  id: number;
  month: number;
  year: number;
}
const userHeaders: Header[] = [
  //   { name: "Patient Name" },
  { name: "Due Month" },
  { name: "Due" },
  { name: "Extra Charge" },
  { name: "Discount" },
  { name: "Paid Amount" },
  { name: "Status" },
  { name: "Action" },
];

const Billing: React.FC<BillingProps> = ({ pageTitle }) => {
  const userHeaderNames: string[] = userHeaders.map((header) => header.name);

  const navigate = useNavigate();
  const { patientId, admissionId } = useParams();
  const admissionIdNumber = admissionId ? parseInt(admissionId, 10) : undefined;
  const patientIdNumber = patientId ? parseInt(patientId, 10) : undefined;
  // const { queryKey, queryFn } = queryConfigs.useGetBillingByPT;
  const { queryKey, queryFn } = queryConfigs.getCurrentAdmissionBill;
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState({ offset: 0, limit: 10 });
  const limit = pagination.limit;
  const { data, refetch, isLoading } = useGetQuery({
    key: queryKey,
    func: queryFn,
    params: {
      offset: (currentPage - 1) * pagination.limit,
      limit: pagination.limit,
      id: patientIdNumber,
      admission: admissionIdNumber,
    },
    isEnabled: patientIdNumber && admissionIdNumber ? true : false,
  });
  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => {
    setCurrentPage(page + 1);
    setPagination((prev) => ({
      ...prev,
      offset: page * pagination.limit,
    }));
  };
  const [extractedData, setExtractedData] = useState<DataItem[]>([]);

  useEffect(() => {
    // Extract id, month, and year from data
    const extractData = data?.list?.map(({ id, month, year }: DataItem) => ({
      id,
      month,
      year,
    }));

    // Update state with the extracted data
    setExtractedData(extractData);
  }, []);

  const [isAddBillingOpen, setIsAddBillingOpen] = useState(false);

  const handleAddClick = () => {
    setIsAddBillingOpen(true);
  };

  const [editValues, setEditValues] = useState({});
  const [viewOpen, setViewOpen] = useState(false);

  const handleEditClick = (bill: any) => {
    setViewOpen(true);
    if (bill.id > 0) {
      setEditValues({ ...bill });
    }
  };
  const { queryKey: employees, queryFn: employeesFunc } =
    queryConfigs.useGetEmployees;
  const { data: employeeData, isLoading: isEmployeeLoaiding } = useGetQuery({
    key: employees,
    func: employeesFunc,
  });

  const { invalidateKey, mutationFn } = queryConfigs.deleteBilling;
  const { mutate: deleteBilling, isPending } = useMutationQuery({
    key: invalidateKey,
    func: mutationFn,
    onSuccess: () => {
      showNotification("success", "Patient deleted successfully");
      handleDeleteClose();
      refetch();
    },
    onError: (error) => {
      showNotification("error", error.message);
    },
  });
  const [toDelete, setToDelete] = useState<number | null>(null);
  const [selectedBill, setSelectedBill] = useState<number | null>(null);
  const [selectedHistory, setSelectedHistory] = useState<number | null>(null);
  const [open, setOpen] = useState(false);

  const handleSelectedBill = (id: number) => {
    setSelectedBill(id);
    setIsAddBillingOpen(true);
  };
  const handleHistoryClick = (billing: BillingData) => {
    setSelectedHistory(billing.id);
    navigate(`/admin/bills/${billing.id}/history/`);
  };

  const handleSelectDelete = (id: number) => {
    setToDelete(id);
    setOpen(true);
  };
  const handleDeleteClose = () => {
    setToDelete(null);
    setOpen(false);
  };

  const handleDelete = () => {
    if (toDelete !== null) {
      deleteBilling(toDelete);
    }
  };
  const handleRefetch = () => {
    refetch();
  };
  return (
    <div className="w-full overflow-hidden mx-auto relative h-screen">
      <CommonTable
        refetchIcon={true}
        onRefetchClick={handleRefetch}
        tableHeaders={userHeaderNames}
        totalRows={data?.count || 0}
        colSpan={userHeaders.length}
        pageTitle={pageTitle}
        marginTop={16}
        showInchargeFilter={false}
        showDesignationFilter={false}
        children3={
          <>
            <div className="flex items-start space-x-5">
              <div className="w-[250px] bg-white rounded-lg drop-shadow-lg h-24  flex flex-col justify-between p-4">
                <div className="flex items-start">
                  <div className="bg-gradient-to-b from-[#459EF1] to-[#2179E9] p-0.5 rounded-xl">
                    <FaCashRegister className=" rounded-lg text-white text-5xl" />
                  </div>
                  <span className="ml-auto capitalize text-sm font-light text-slate-500 text-end font-Poppins">
                    total amount
                    <br />
                    collected
                  </span>
                </div>
                <div className="text-right">
                  <span className="capitalize text-xl font-semibold text-black font-Poppins">
                    ₹{data?.total_paid_amount}
                  </span>
                </div>
              </div>

              <div className="w-[250px] bg-white rounded-lg drop-shadow-lg h-24  flex flex-col justify-between p-4">
                <div className="flex items-start">
                  <div className="bg-gradient-to-b from-[#faa307] to-[#faa307] rounded-lg  p-0.5">
                    <MdOutlinePendingActions className="bg-gradient-to-b from-[#faa307] to-[#faa307] rounded-lg drop-shadow-lg text-white text-5xl" />
                  </div>
                  <span className="ml-auto capitalize text-sm font-light text-slate-500 text-end font-Poppins">
                    total pending
                    <br /> dues
                  </span>
                </div>
                <div className="text-right">
                  <span className="capitalize text-xl font-semibold text-black font-Poppins">
                    ₹{data?.total_due}
                  </span>
                </div>
              </div>
            </div>
            <TablePagination
              component="div"
              count={data?.count || 0}
              page={currentPage - 1}
              rowsPerPage={limit}
              onPageChange={handlePageChange}
              onRowsPerPageChange={(event) => {
                const newLimit = parseInt(event.target.value, 10);
                setPagination((prev) => ({
                  ...prev,
                  limit: newLimit,
                  offset: 0,
                }));
                setCurrentPage(1);
              }}
              sx={{ color: "#38a3a5", font: "Poppins, sans-serif" }}
            />
          </>
        }
      >
        {data?.list?.map((billing: BillingData) => (
          <TableRow key={billing.id}>
            <TableCell align="center" className="capitalize">
              {dayjs()
                .month(billing.month - 1)
                .format("MMMM")}
              -{billing.year}
            </TableCell>
            <TableCell align="center" className="capitalize">
              ₹{billing.dues}/-
            </TableCell>
            <TableCell align="center" className="capitalize">
              ₹{billing.extra_charge}/-
            </TableCell>
            <TableCell align="center" className="capitalize">
              ₹{billing.deducted_amount}/-
            </TableCell>
            <TableCell align="center" className="capitalize">
              ₹{billing.paid_amount}/-
            </TableCell>
            {/* <TableCell align="center" className="capitalize">
              {employeeData?.list.find(
                (d: Emp) => d.id === billing?.received_by
              )?.name ?? ""}
            </TableCell> */}

            <TableCell align="center" className="capitalize">
              {billing.dues === 0 ? (
                <Chip
                  label="Paid"
                  color="success" // Green color for "Paid"
                  variant="outlined"
                />
              ) : (
                <Chip
                  label="Pending"
                  color="warning" // Yellow color for "Pending"
                  variant="outlined"
                />
              )}
            </TableCell>

            <TableCell align="center" className="capitalize space-x-2">
              <Tooltip title="Print Bill" arrow>
                <button
                  onClick={() => handleEditClick(billing)}
                  className="edit-btn"
                >
                  <MdLocalPrintshop />
                </button>
              </Tooltip>
              <Tooltip title="Payment History" arrow>
                <button
                  onClick={() => handleHistoryClick(billing)}
                  className="edit-btn-warning"
                >
                  <BsCash />
                </button>
              </Tooltip>
              <Tooltip title="Pay Bill" arrow>
                <button
                  onClick={() => handleSelectedBill(billing.id)}
                  className="edit-btn-success"
                >
                  <MdOutlinePayments />
                </button>
              </Tooltip>

              {/* <Tooltip title="Delete" arrow>
                <button
                  onClick={() => handleSelectDelete(billing.id)}
                  className="del-btn"
                >
                  <MdDeleteForever />
                </button>
              </Tooltip> */}
            </TableCell>
          </TableRow>
        ))}
      </CommonTable>
      {/* <AddBillingByMonth
        ptID={patientIdNumber}
        open={isAddBillingOpen}
        options={extractedData ?? []}
        onClose={() => setIsAddBillingOpen(false)}
      /> */}
      <AddPayment
        billID={selectedBill}
        open={isAddBillingOpen}
        onClose={() => setIsAddBillingOpen(false)}
      />

      <EditBilling
        onClose={() => setViewOpen(false)}
        open={viewOpen}
        ptInfo={editValues}
      />
      <DeleteModal
        open={open}
        onClose={handleDeleteClose}
        title="Delete Patient ?"
        contentText="Are you sure you want to delete this patient? This action cannot be undone."
        onCancel={handleDeleteClose}
        onConfirm={handleDelete}
        isDeleting={isPending}
      />
    </div>
  );
};

export default Billing;
