import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { clearAuthHeader, setAuthHeader } from "../../services/api";


type Organisation = {
    id?: number | null | undefined;
    name?: string | null;
    contact?: string | null;
    email?: string | null;
    logo?: string | null;
};

type User = {
    email?: string | null;
    id?: number | null;
    image?: string | null;
    mobile?: string | null;
    name?: string | null;
    organisation?: number | null;

};


type TAuthState = {
    organisation: Organisation | null;
    user: User | null;
    token: string | null;
};


const initialState: TAuthState = {
    organisation: {
        id: null,
        contact: "",
        email: "",
        logo: "",
        name: "",
    },
    user: null,
    token: null,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setCredentials: (state, action: PayloadAction<User>) => {
            const user = action.payload;
            state.user = user;

        },
        setOrganisation: (state, action: PayloadAction<Organisation>) => {
            state.organisation = action.payload;
        },
        setUser: (state, action: PayloadAction<User | null>) => {
            state.user = action.payload;
        },
        setToken: (state, action: PayloadAction<string | null>) => {

            state.token = action.payload;
            if (action.payload) {
                setAuthHeader(action.payload);
            } else {
                clearAuthHeader();
            }
        },
        logOut: (state) => {
            state.user = null;
            state.token = null;
            state.organisation = null;
            clearAuthHeader();
        }
    },
});


export const { setCredentials, setOrganisation, setUser, setToken, logOut } = authSlice.actions;
export default authSlice.reducer;
export const selectCurrentAuth = (state: RootState) => state.auth;
