import {
  addUser,
  deletePatient,
  deleteUser,
  getMedicineById,
  getMedicines,
  getPatientById,
  getPatients,
  getDoctorById,
  getDoctors,
  getUserById,
  getUsers,
  updatePatient,
  updateUser,
  getEmployees,
  getEmployeeById,
  deleteEmployee,
  addDoctor,
  addPatient,
  addMedicine,
  deleteMedicine,
  updateMedicine,
  addEmployee,
  getDesignations,
  getDesignationById,
  updateDesignation,
  deleteDesignation,
  addDesignation,
  updateEmployee,
  getVitals,
  getVitalById,
  updateVital,
  deleteVital,
  addVital,
  addTreatment,
  getTreatments,
  getTreatmentById,
  updateTreatment,
  deleteTreatment,
  login,

  getMetaData,
  addBilling,
  getBillings,
  getBillingById,
  deleteBilling,
  updateBilling,
  addAdmission,
  getAdmissions,
  getAdmissionById,
  updateAdmission,
  deleteAdmission,
  getBillingByPatient,
  getDashboardData,
  getDueByPatient,
  dischargePatient,
  getVitalByPatientDate,
  addPartialPayment,
  getBillingSummary,
  getDischargedAdmissions,
  updateDoctor,
  deleteDoctor,
  getProfile,
  updateProfile,
  getPatientBillings,
  getAllBillingsForPatient,
  updateBill,
  addPayment,
  getPaymentByBill,
  updatePasswordForUser,
  updateProfilePwd,
  updatePatientFiles,
  addPatientFiles,
  getFilesByPatientId,
  deletePatientFiles,
} from "../services/api";

export const queryConfigs = {
  getMetadata: { queryKey: ["metadata"], queryFn: getMetaData },

  getFilesByPatientId: { queryKey: ["files"], queryFn: getFilesByPatientId },
  updatePatientFiles: { invalidateKey: ["files"], mutationFn: updatePatientFiles },
  addPatientFiles: { mutationFn: addPatientFiles, invalidateKey: ["files"] },
  deletePatientFiles: { mutationFn: deletePatientFiles, invalidateKey: ["files"] },

  getProfile: { queryKey: ["profile"], queryFn: getProfile },
  updateProfile: { queryKey: ["profile"], mutationFn: updateProfile },
  updateProfilePwd: { queryKey: ["profile"], mutationFn: updateProfilePwd },

  useLogin: { queryKey: ["auth"], queryFn: login },



  useAddUser: { queryKey: ["users"], queryFn: addUser, invalidateKey: ["users"] },
  getUsers: { queryKey: ["users"], queryFn: getUsers },
  getUserById: { queryKey: ["user-"], queryFn: getUserById },
  updateUser: { mutationFn: updateUser, invalidateKey: ["users"] },
  updatePasswordForUser: { mutationFn: updatePasswordForUser, invalidateKey: ["users"] },
  deleteUser: { mutationFn: deleteUser, invalidateKey: ["users"] },

  useAddPatient: {
    queryKey: ["patients"],
    queryFn: addPatient,
    invalidateKey: ["patients"],
  },
  getPatients: { queryKey: ["patients"], queryFn: getPatients },
  getPatientById: { queryKey: ["patient-"], queryFn: getPatientById },
  getPatientBillings: { queryKey: ["billings-"], queryFn: getPatientBillings },
  updatePatient: { mutationFn: updatePatient, invalidateKey: ["patients"] },
  deletePatient: { mutationFn: deletePatient, invalidateKey: ["patients"] },

  addMedicine: { queryKey: ["medicine"], queryFn: addMedicine },
  getMedicines: { queryKey: ["medicine"], queryFn: getMedicines },
  getMedicineById: { queryKey: ["medicine-"], queryFn: getMedicineById },
  updateMedicine: { mutationFn: updateMedicine, invalidateKey: ["medicine"] },
  deleteMedicine: { mutationFn: deleteMedicine, invalidateKey: ["medicine"] },

  useAddEmployee: { queryKey: ["employees"], queryFn: addEmployee },
  useGetEmployees: { queryKey: ["employees"], queryFn: getEmployees },
  useGetemployeesById: { queryKey: ["employee-"], queryFn: getEmployeeById },

  UseUpdateEmployee: { mutationFn: updateEmployee, invalidateKey: ["employees"] },
  UseDeleteEemployees: {
    mutationFn: deleteEmployee,
    invalidateKey: ["employees"],
  },

  useAddDoctor: {
    queryKey: ["doctors"],
    queryFn: addDoctor,
    invalidateKey: ["doctors"],
  },
  getDoctors: { queryKey: ["doctors"], queryFn: getDoctors },
  getDoctorById: { queryKey: ["doctor"], queryFn: getDoctorById },
  updateDoctor: { mutationFn: updateDoctor, invalidateKey: ["doctors"] },
  deleteDoctor: { mutationFn: deleteDoctor, invalidateKey: ["doctors"] },

  useAddVitals: { queryKey: ["vitals"], queryFn: addVital },
  getVitals: { queryKey: ["vitals"], queryFn: getVitals },
  getVitalById: { queryKey: ["vital"], queryFn: getVitalById },
  getVitalByPatient: { queryKey: ["patient-vitals"], queryFn: getVitalByPatientDate },
  updateVital: { mutationFn: updateVital, invalidateKey: ["vitals"] },
  deleteVital: { mutationFn: deleteVital, invalidateKey: ["vitals"] },

  useAddDesignation: {
    queryKey: ["designations"],
    queryFn: addDesignation,
    invalidateKey: ["designations"],
  },
  getDesignations: { queryKey: ["designations"], queryFn: getDesignations },
  getDesignationById: { queryKey: ["designation-"], queryFn: getDesignationById },
  updateDesignation: { mutationFn: updateDesignation, invalidateKey: ["designations"] },
  deleteDesignation: { mutationFn: deleteDesignation, invalidateKey: ["designations"] },



  addPayment: {
    queryKey: ["payments"],
    queryFn: addPayment,
    invalidateKey: ["payments"],
  },
  getPaymentByBill: { queryKey: ["payments"], queryFn: getPaymentByBill },


  useAddTreatment: {
    queryKey: ["treatments"],
    queryFn: addTreatment,
    invalidateKey: ["treatments"],
  },
  getTreatments: { queryKey: ["treatments"], queryFn: getTreatments },
  getTreatmentById: { queryKey: ["treatment-"], queryFn: getTreatmentById },
  updateTreatment: { mutationFn: updateTreatment, invalidateKey: ["treatments"] },
  deleteTreatment: { mutationFn: deleteTreatment, invalidateKey: ["treatments"] },

  useAddBilling: {
    queryKey: ["billings"],
    queryFn: addBilling,
    invalidateKey: ["billings"],
  },
  getBillingSummary: { queryKey: ["billings-summary"], queryFn: getBillingSummary },
  getBillings: { queryKey: ["billings"], queryFn: getBillings },
  getAllBillingsForPatient: { queryKey: ["allbilling"], queryFn: getAllBillingsForPatient },
  getBillingById: { queryKey: ["billings"], queryFn: getBillingById },
  getCurrentAdmissionBill: { queryKey: ["billing"], queryFn: getPatientBillings },
  useGetBillingByPT: { queryKey: ["billings"], queryFn: getBillingByPatient },
  updateBilling: { mutationFn: updateBilling, invalidateKey: ["billings"] },
  deleteBilling: { mutationFn: deleteBilling, invalidateKey: ["billings"] },
  updateBill: { mutationFn: updateBill, invalidateKey: ["admissions"] },

  useAddPartialPayments: {
    queryKey: ["billings", "billing", "allbilling"],
    queryFn: addPartialPayment,
    invalidateKey: ["billings"],
  },

  useAddAdmission: {
    queryKey: ["admissions", "patients", "patient"],
    queryFn: addAdmission,
    invalidateKey: [["admissions"], ["patients"], ["patient"]],
  },
  getAdmissions: { queryKey: ["admissions"], queryFn: getAdmissions },
  getDischargedAdmissions: { queryKey: ["discharged_admissions"], queryFn: getDischargedAdmissions },
  getAdmissionById: { queryKey: ["admission-"], queryFn: getAdmissionById },
  updateAdmission: { mutationFn: updateAdmission, invalidateKey: ["admissions"] },

  deleteAdmission: { mutationFn: deleteAdmission, invalidateKey: ["admissions"] },

  useGetDashboardData: { queryKey: ["dashboard"], queryFn: getDashboardData },


  useGetTotalDueByPT: {
    queryKey: ["billings"],
    queryFn: getDueByPatient
  },
  useDischargePatient: { queryKey: ["discharge"], invalidateKey: [["admissions"], ["patients"], ["billings-summary"], ["discharge"], ["billings"], ["discharged_admissions"]], queryFn: dischargePatient },

};
