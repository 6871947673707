import React from "react";
import { useParams } from "react-router-dom";
import { queryConfigs } from "../../hooks/config";
import { useGetQuery } from "../../hooks/queryHook";
import CommonTable from "../CommonTable";
import { TableCell, TablePagination, TableRow } from "@mui/material";
import dayjs from "dayjs";
import { Emp } from "../../types/common";

type BillingProps = {
  pageTitle: string;
};
type Header = {
  name: string;
};
type PaymentData = {
  id?: number;
  bill?: number;
  patient?: number;
  admission?: number;
  created_on?: string;
  updated_on?: string;
  patient_name?: string;
  received_by?: number;
  remark?: string;
  month?: number;
  year?: number;
  upi_amount?: number;
  cash_amount?: number;
  card_amount?: number;
  admission_fee?: number;
};

const userHeaders: Header[] = [
  { name: "Bill ID" },
  { name: "Name" },
  { name: "UPI" },
  { name: "Cash" },
  { name: "Card" },
  { name: "Due Month" },
  { name: "Recieved By" },
  { name: "Paid On" },
];
const PaymentHistory: React.FC<BillingProps> = ({ pageTitle }) => {
  // const { queryKey: ptKey, queryFn: getPtFn } = queryConfigs.getPatientById;
  // const { data: ptData, isLoading } = useGetQuery({
  //   key: ptKey,
  //   func: getPtFn,
  //   params: {
  //     id: admission?.patient,
  //   },
  //   isEnabled: admission?.patient ? true : false,
  // });
  //
  const { queryKey: employees, queryFn: employeesFunc } =
    queryConfigs.useGetEmployees;
  const { data: employeeData, isLoading: isEmployeeLoaiding } = useGetQuery({
    key: employees,
    func: employeesFunc,
  });
  const params = useParams();

  const userHeaderNames: string[] = userHeaders.map((header) => header.name);
  // Get the bill ID from the params and parse it to an integer, or return null if invalid
  const billId = isNaN(parseInt(params?.id || "", 10))
    ? null
    : parseInt(params?.id || "", 10);

  const { queryKey, queryFn } = queryConfigs.getPaymentByBill;
  const {
    data: payData,
    isLoading,
    refetch,
  } = useGetQuery({
    key: queryKey,
    func: queryFn,
    params: {
      billId: billId ?? null,
      //   offset: (currentPage - 1) * pagination.limit,
      //   limit: pagination.limit,
      //   name: searchParams.name ?? "",
      //   id: searchParams.id ?? null,
      //   startDate: chartStartDate,
      //   endDate: chartEndDate,
    },
    // isEnabled: isEnabled,
  });
  return (
    <>
      <div className="w-full overflow-hidden mx-auto relative h-screen">
        <CommonTable
          tableHeaders={userHeaderNames}
          totalRows={payData?.count || 0}
          colSpan={userHeaders.length}
          pageTitle={pageTitle}
          marginTop={16}
          showInchargeFilter={false}
          showDesignationFilter={false}
          //   children3={
          //     <TablePagination
          //       component="div"
          //       count={payData?.count || 0}
          //       page={currentPage - 1}
          //       rowsPerPage={limit}
          //       onPageChange={handlePageChange}
          //       onRowsPerPageChange={(event) => {
          //         const newLimit = parseInt(event.target.value, 10);
          //         setPagination((prev) => ({
          //           ...prev,
          //           limit: newLimit,
          //           offset: 0,
          //         }));
          //         setCurrentPage(1);
          //       }}
          //       sx={{ color: "#38a3a5", font: "Poppins, sans-serif" }}
          //     />
          //   }
          //   children2={
          //     <>
          //       <div className="flex items-center space-x-5 w-full">
          //         <DatePicker
          //           selected={chartStartDate ? dayjs(chartStartDate).toDate() : null}
          //           onChange={handleChartDateRange}
          //           startDate={
          //             chartStartDate ? dayjs(chartStartDate).toDate() : undefined
          //           }
          //           endDate={chartEndDate ? dayjs(chartEndDate).toDate() : undefined}
          //           selectsRange
          //           dateFormat="yyyy-MM-dd"
          //           placeholderText="Select Date Range"
          //           className="w-[300px] border border-gray-300 rounded py-2 px-4"
          //         />
          //         <TextField
          //           label="Patient Name"
          //           variant="outlined"
          //           name="name"
          //           value={patientSearch.name || ""}
          //           onChange={handleChange}
          //           size="small"
          //           sx={{
          //             width: "20%",
          //             "& .MuiInputLabel-root": {
          //               fontFamily: "Poppins, sans-serif",
          //               fontSize: "13px",
          //             },
          //           }}
          //         />
          //         <TextField
          //           label="Patient ID"
          //           variant="outlined"
          //           name="id"
          //           value={patientSearch.id !== null ? patientSearch.id.toString() : ""}
          //           onChange={handleChange}
          //           size="small"
          //           sx={{
          //             width: "20%",
          //             "& .MuiInputLabel-root": {
          //               fontFamily: "Poppins, sans-serif",
          //               fontSize: "13px",
          //             },
          //           }}
          //         />

          //         <button onClick={handleSearch} className="search-btn">
          //           Search
          //         </button>
          //         <button onClick={handleClear} className="clear-btn">
          //           Clear
          //         </button>
          //       </div>
          //     </>
          //   }
        >
          {payData?.payments?.map((pay: PaymentData) => (
            <TableRow key={pay.id}>
              <TableCell align="center" className="capitalize">
                {pay.id}
              </TableCell>
              <TableCell align="center" className="capitalize">
                {pay.patient_name}
              </TableCell>
              <TableCell align="center" className="capitalize">
                ₹{pay.upi_amount}/-
              </TableCell>
              <TableCell align="center">₹{pay.cash_amount} /-</TableCell>
              <TableCell align="center" className="capitalize">
                ₹{pay.card_amount}/-
              </TableCell>
              <TableCell align="center" className="capitalize">
                {dayjs(`${pay.year}-${pay.month}`).format("MMM")}-{pay.year}
              </TableCell>
              <TableCell align="center" className="capitalize">
                {employeeData?.list.find((d: Emp) => d.id === pay?.received_by)
                  ?.name ?? ""}
              </TableCell>

              <TableCell align="center" className="capitalize">
                {dayjs(pay.created_on).format("DD-MM-YYYY")}
              </TableCell>
            </TableRow>
          ))}
        </CommonTable>
      </div>
    </>
  );
};

export default PaymentHistory;
