import React, { useEffect, useState } from "react";
import CommonTable from "../CommonTable";
import {
  TableCell,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { useGetQuery, useMutationQuery } from "../../hooks/queryHook";
import { queryConfigs } from "../../hooks/config";
import DeleteModal from "../DeleteModal";
import { showNotification } from "../../services/helper";
import dayjs from "dayjs";
import AddEmployee from "./AddEmployee";
import EditEmployee from "./EditEmployee";
import { FaEdit } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

type UsersProps = {
  pageTitle: string;
};

type Header = {
  name: string;
};
type DesignationMenu = {
  id: number;
  name: string;
  organisation: number;
  created_on: string;
};
type EmpRow = {
  id: number;
  name: string;
  created_on: string;
  email: string;
  mobile: string;
  address: string;
  designation: number;
};

const userHeaders: Header[] = [
  { name: "ID" },
  { name: "Name" },
  { name: "Mobile" },
  { name: "Email" },
  { name: "Designation" },
  { name: "Created on" },
  { name: "Action" },
];
interface EmployeeSearch {
  name: string | null;
  mobile: number | null;
}

const Employees: React.FC<UsersProps> = ({ pageTitle }) => {
  const { queryKey, queryFn } = queryConfigs.useGetEmployees;
  const [employeeSearch, setEmployeeSearch] = useState<EmployeeSearch>({
    name: null,
    mobile: null,
  });
  const [searchEmployee, setSearchEmployee] = useState<EmployeeSearch>({
    name: null,
    mobile: null,
  });
  const userHeaderNames: string[] = userHeaders.map((header) => header.name);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState({ offset: 0, limit: 10 });
  useEffect(() => {
    setCurrentPage(Math.floor(pagination.offset / pagination.limit) + 1);
  }, [pagination.offset, pagination.limit]);

  const limit = pagination.limit;
  const { data, refetch, isLoading } = useGetQuery({
    key: queryKey,
    func: queryFn,
    params: {
      offset: (currentPage - 1) * pagination.limit,
      limit: pagination.limit,
      name: searchEmployee.name ?? "",
      mobile: searchEmployee.mobile ?? null,
    },
  });

  const [openAdd, setOpenAdd] = useState(false);
  const [toDelete, setToDelete] = useState<number | null>(null);
  const [open, setOpen] = useState(false);
  const [editValue, setEditValue] = useState({});
  const [editOpen, setEditOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setToDelete(null);
  };
  const handleEditClose = () => {
    setEditOpen(false);
    setEditValue({
      id: 0,
      name: "",
      description: "",
    });
  };

  const handleAddClick = () => {
    setOpenAdd(true);
  };

  const { queryKey: designations, queryFn: designationFunc } =
    queryConfigs.getDesignations;
  const { data: designationdata, isLoading: isDesignationLoading } =
    useGetQuery({
      key: designations,
      func: designationFunc,
      params: {
        limit: 1000,
      },
    });

  const handleDeleteClose = () => {
    setToDelete(null);
    setOpen(false);
  };
  const { invalidateKey, mutationFn } = queryConfigs.UseDeleteEemployees;
  const { mutate: deleteEmp } = useMutationQuery({
    key: invalidateKey,
    func: mutationFn,
    onSuccess: () => {
      showNotification("success", "Patient deleted successfully");
      handleDeleteClose();
    },
    onError: (error) => {
      showNotification("error", error.message);
      setIsDeleting(false);
    },
  });
  const handleSelectDelete = (id: number) => {
    setToDelete(id);
    setOpen(true);
  };

  const handleDelete = () => {
    if (toDelete !== null) {
      setIsDeleting(true);
      deleteEmp(toDelete);
    }
  };
  const [editValues, setEditValues] = useState({});
  const [viewOpen, setViewOpen] = useState(false);
  const handleEditClick = (pt: EmpRow) => {
    setViewOpen(true);
    if (pt.id > 0) {
      setEditValues({ ...pt });
    }
  };

  const handleEmployeeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setEmployeeSearch((prevState) => ({
      ...prevState,
      [name]: value || null,
    }));
  };

  const handleSearch = () => {
    setSearchEmployee(employeeSearch);
  };

  const handleSearchClear = () => {
    setEmployeeSearch({
      name: null,
      mobile: null,
    });
    setSearchEmployee({
      name: null,
      mobile: null,
    });
  };
  const handleRefetch = () => {
    handleSearch();
    refetch();
  };

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => {
    setCurrentPage(page + 1);
    setPagination((prev) => ({
      ...prev,
      offset: page * pagination.limit,
    }));
  };

  return (
    <>
      <div className="w-full overflow-hidden mx-auto relative h-screen">
        <CommonTable
          refetchIcon={true}
          onRefetchClick={handleRefetch}
          tableHeaders={userHeaderNames}
          totalRows={data?.count || 0}
          colSpan={userHeaders.length}
          pageTitle={pageTitle}
          onAddClick={handleAddClick}
          marginTop={16}
          showInchargeFilter={false}
          children2={
            <>
              <div className=" flex items-center space-x-5 w-full">
                <TextField
                  label="Employee Name"
                  variant="outlined"
                  name="name"
                  value={employeeSearch.name || ""}
                  onChange={handleEmployeeChange}
                  size="small"
                  sx={{
                    width: "20%",
                    "& .MuiInputLabel-root": {
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "13px",
                    },
                  }}
                />
                <TextField
                  label="Employee Mobile"
                  variant="outlined"
                  name="mobile"
                  value={employeeSearch.mobile || ""}
                  onChange={handleEmployeeChange}
                  size="small"
                  sx={{
                    width: "20%",
                    "& .MuiInputLabel-root": {
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "13px",
                    },
                  }}
                />

                <button onClick={handleSearch} className="search-btn">
                  Search
                </button>
                <button onClick={handleSearchClear} className="clear-btn">
                  Clear
                </button>
              </div>
            </>
          }
          children3={
            <TablePagination
              component="div"
              count={data?.count || 0}
              page={currentPage - 1}
              rowsPerPage={limit}
              onPageChange={handlePageChange}
              onRowsPerPageChange={(event) => {
                const newLimit = parseInt(event.target.value, 10);
                setPagination((prev) => ({
                  ...prev,
                  limit: newLimit,
                  offset: 0,
                }));
                setCurrentPage(1);
              }}
              sx={{ color: "#38a3a5", font: "Poppins, sans-serif" }}
            />
          }
        >
          {data?.list?.map((emp: EmpRow) => (
            <TableRow key={emp.id}>
              <TableCell align="center" className="capitalize">
                {emp.id}
              </TableCell>
              <TableCell align="center" className="capitalize">
                {emp.name}
              </TableCell>
              <TableCell align="center" className="capitalize">
                {emp.mobile}
              </TableCell>
              <TableCell align="center" className="capitalize">
                {emp.email}
              </TableCell>
              <TableCell align="center" className="capitalize">
                {designationdata?.list.find(
                  (d: DesignationMenu) => d.id === emp.designation
                )?.name ?? "Not Found"}
              </TableCell>

              <TableCell align="center" className="capitalize">
                {dayjs(emp.created_on).format("DD-MM-YYYY")}
              </TableCell>
              <TableCell align="center" className="space-x-3">
                <Tooltip title="Edit" arrow>
                  <button
                    onClick={() => handleEditClick(emp)}
                    className="edit-btn"
                  >
                    <FaEdit />
                  </button>
                </Tooltip>
                <Tooltip title="Delete" arrow>
                  <button
                    onClick={() => handleSelectDelete(emp.id)}
                    className="del-btn"
                  >
                    <MdDeleteForever />
                  </button>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </CommonTable>
      </div>
      <AddEmployee onClose={() => setOpenAdd(false)} open={openAdd} />
      <DeleteModal
        open={open}
        onClose={handleDeleteClose}
        title="Delete Employee ?"
        contentText="Are you sure you want to delete this employee? This action cannot be undone."
        onCancel={handleDeleteClose}
        onConfirm={handleDelete}
        isDeleting={isDeleting}
      />
      <EditEmployee
        editClose={() => setViewOpen(false)}
        openEdit={viewOpen}
        empInfo={editValues}
      />
    </>
  );
};

export default Employees;
