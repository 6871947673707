import React, { useEffect, useState } from "react";
import CommonTable from "../CommonTable";
import { TableCell, TableRow, Tooltip } from "@mui/material";
import { useGetQuery, useMutationQuery } from "../../hooks/queryHook";
import { queryConfigs } from "../../hooks/config";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteModal from "../DeleteModal";
import { showNotification } from "../../services/helper";
import dayjs from "dayjs";
import { FaFileDownload } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import AddTreatment from "./AddTreatment";
import EditTreatment from "./EditTreatment";
import { MdDeleteForever } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import TreatmentTable from "./TreatmentTable";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

type UsersProps = {
  pageTitle: string;
};

type Header = {
  name: string;
};
type DesignationMenu = {
  id: number;
  name: string;
  organisation: number;
  created_on: string;
};
type VitalRow = {
  id: number;
  blood_high: string;
  blood_low: string;
  pulse_rate: string;
  bio_func: string;
  spo: string;
  weight: number;
  in_charge: string;
  created_on: string;
};

const userHeaders: Header[] = [
  { name: "ID" },
  { name: "ADministered on" },
  { name: "Action" },
];
type Emp = {
  id: number;
  name: string;
  created_on: string;
  email: string;
  mobile: string;
  address: string;
  designation: number;
};
type Medicine = {
  id: number;
  treatment: number;
  created_on: string;
  timing: string;
};

type TreatmentChart = {
  id: number;
  patient: number;
  remarks: string;
  created_on: string;
  administered: string;
  medicines: Medicine[];
};

const Treatment: React.FC<UsersProps> = ({ pageTitle }) => {
  const params = useParams();
  const navigate = useNavigate();
  const pTId = parseInt(params.id || "0");

  const { queryKey, queryFn } = queryConfigs.getTreatments;
  const userHeaderNames: string[] = userHeaders?.map((header) => header.name);
  const { data, refetch, isLoading } = useGetQuery({
    key: queryKey,
    func: queryFn,
    params: { offset: 0, limit: 10, patient: pTId },
    isEnabled: params.id ? true : false,
  });

  const [openAdd, setOpenAdd] = useState(false);
  const [toDelete, setToDelete] = useState<number | null>(null);
  const [open, setOpen] = useState(false);
  const [editValue, setEditValue] = useState({});
  const [editOpen, setEditOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setToDelete(null);
  };
  const handleEditClose = () => {
    setEditOpen(false);
    setEditValue({
      id: 0,
      name: "",
      description: "",
    });
  };

  const handleAddClick = () => {
    setOpenAdd(true);
  };

  const handleDeleteClose = () => {
    setToDelete(null);
    setOpen(false);
    setIsDeleting(false);
  };
  const { invalidateKey, mutationFn } = queryConfigs.deleteTreatment;
  const { mutate: deleteTreatment } = useMutationQuery({
    key: invalidateKey,
    func: mutationFn,
    onSuccess: () => {
      showNotification("success", "Treatment Records deleted successfully");
      handleDeleteClose();
    },
    onError: (error) => {
      showNotification("error", error.message);
      setIsDeleting(false);
    },
  });
  const handleSelectDelete = (id: number) => {
    setToDelete(id);
    setOpen(true);
  };

  const handleDelete = () => {
    if (toDelete !== null) {
      setIsDeleting(true);
      deleteTreatment(toDelete);
    }
  };

  const { queryKey: employees, queryFn: employeesFunc } =
    queryConfigs.useGetEmployees;
  const { data: employeeData, isLoading: isEmployeeLoaiding } = useGetQuery({
    key: employees,
    func: employeesFunc,
  });

  const handleVitalEdit = (id: number) => {};
  const [editValues, setEditValues] = useState({});
  const [viewOpen, setViewOpen] = useState(false);
  const handleEditClick = (treatment: TreatmentChart) => {
    setViewOpen(true);
    if (treatment.id > 0) {
      setEditValues({ ...treatment });
    }
  };
  const [treatmentOpen, setTreatmentOpen] = useState(false);
  const handleOpenTreatment = () => setTreatmentOpen(true);
  const handleCloseTreatment = () => setTreatmentOpen(false);
  const handleRefetch = () => {
    refetch();
  };
  return (
    <>
      <div className="w-full overflow-hidden mx-auto relative h-screen">
        <CommonTable
          refetchIcon={true}
          onRefetchClick={handleRefetch}
          tableHeaders={userHeaderNames}
          totalRows={data?.count || 0}
          colSpan={userHeaders.length}
          pageTitle={pageTitle}
          onAddClick={handleAddClick}
          marginTop={16}
          showNameFilter={false}
          showDesignationFilter={false}
          showMobileFilter={false}
          showIdFilter={false}
          showInchargeFilter={false}
          showDateRangePicker={true}
        >
          {data?.list?.map((treatment: TreatmentChart) => (
            <TableRow key={treatment.id}>
              <TableCell align="center" className="capitalize">
                {treatment.id}
              </TableCell>

              <TableCell align="center" className="capitalize">
                {treatment.administered
                  ? dayjs(treatment.administered).format("DD-MM-YYYY")
                  : "--"}
              </TableCell>

              <TableCell align="center" className="space-x-3">
                <Tooltip title="Edit" arrow>
                  <button
                    onClick={() => handleEditClick(treatment)}
                    className="edit-btn"
                  >
                    <FaEdit />
                  </button>
                </Tooltip>
                <Tooltip title="Delete" arrow>
                  <button
                    onClick={() => handleSelectDelete(treatment.id)}
                    className="del-btn"
                  >
                    <MdDeleteForever />
                  </button>
                </Tooltip>
                <Tooltip title="Download" arrow>
                  <button
                    onClick={() => handleOpenTreatment()}
                    className="generate-btn"
                  >
                    <FaFileDownload />
                  </button>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </CommonTable>
      </div>
      <AddTreatment onClose={() => setOpenAdd(false)} open={openAdd} />
      <DeleteModal
        open={open}
        onClose={handleDeleteClose}
        title="Delete Treatment Record ?"
        contentText="Are you sure you want to delete this treatment record? This action cannot be undone."
        onCancel={handleDeleteClose}
        onConfirm={handleDelete}
        isDeleting={isDeleting}
      />
      <EditTreatment
        onClose={() => setViewOpen(false)}
        open={viewOpen}
        treatmentData={editValues}
      />

      <TreatmentTable
        pTId={pTId}
        open={treatmentOpen}
        onClose={handleCloseTreatment}
      />
    </>
  );
};

export default Treatment;
