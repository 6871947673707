import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  TableCell,
  TableRow,
  Tooltip,
  FormControl,
  InputLabel,
  TextField,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { FaFileDownload } from "react-icons/fa";
import CommonTable from "../CommonTable";
import { PiEyeFill } from "react-icons/pi";
import { BsFilePdf, BsFileImage } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import { queryConfigs } from "../../hooks/config";
import { useGetQuery, useMutationQuery } from "../../hooks/queryHook";
import { skynet, uploadFile } from "../../services/api";
import { showNotification } from "../../services/helper";
import { title } from "process";
import dayjs from "dayjs";
import DeleteModal from "../DeleteModal";
import { MdDeleteForever } from "react-icons/md";

type UploadDocumentProps = {
  pageTitle: string;
};

type UploadedFile = {
  name: string;
  type: string;
  patientName: string;
  url: string;
};

type Header = {
  name: string;
};

const userHeaders: Header[] = [
  { name: "ID" },
  { name: "File Type" },
  { name: "Uploaded On" },
  { name: "Action" },
];

const fileTypes = [
  "Government Certificate",
  "Patient History",
  "Patient Medical Prescription",
  "Others",
];
type Document = {
  id: number;
  patient: number;
  title: string;
  file_url: string;
  created_on: string;
  updated_on: string;
  created_by: number;
};

const fetchPatientName = async (): Promise<string> => {
  return "Sharma";
};

type fileData = {
  patient: number | null;
  title: string;
  file_url: string;
};

const UploadDocument: React.FC<UploadDocumentProps> = ({ pageTitle }) => {
  const [form, setForm] = useState<fileData>({
    patient: null,
    title: "",
    file_url: "",
  });
  const inputImageRef = useRef<HTMLInputElement>(null);
  const [currentFile, setCurrentFile] = useState<File | null>(null);
  const params = useParams();
  const pTId = parseInt(params.id || "0");

  const { invalidateKey, mutationFn } = queryConfigs.addPatientFiles;
  const { mutate: addFiles } = useMutationQuery({
    key: invalidateKey,
    func: mutationFn,
    onSuccess: () => {
      showNotification("success", "User added successfully");
      setFile(null);
      setFileName("");
      setSelectedFileType("");
      setFilePreview(null);
      setEnabledText(false);
      setForm({
        patient: null,
        title: "",
        file_url: "",
      });
    },
  });
  const { invalidateKey: fileKey, mutationFn: updateFunc } =
    queryConfigs.updatePatientFiles;
  const { mutate: updateFiles } = useMutationQuery({
    key: fileKey,
    func: updateFunc,
    onSuccess: () => {
      showNotification("success", "Files added successfully");
    },
  });
  const { invalidateKey: deleteKey, mutationFn: deleteFile } =
    queryConfigs.deletePatientFiles;
  const { mutate: deleteFunc } = useMutationQuery({
    key: deleteKey,
    func: deleteFile,
    onSuccess: () => {
      showNotification("success", "File deleted successfully");
      handleClose();
    },
  });

  const { queryKey, queryFn } = queryConfigs.getFilesByPatientId;

  const { data: ptFiles } = useGetQuery({
    key: queryKey,
    func: queryFn,
    params: { id: pTId },
    isEnabled: params.id ? true : false,
  });

  const userHeaderNames: string[] = userHeaders.map((header) => header.name);
  const [patientName, setPatientName] = useState<string>("");
  const [selectedFileType, setSelectedFileType] = useState<string>("");
  const [fileTitle, setFileTitle] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState<string>("");
  const [filePreview, setFilePreview] = useState<string | ArrayBuffer | null>(
    null
  );
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);

  useEffect(() => {
    const getPatientName = async () => {
      const name = await fetchPatientName();
      setPatientName(name);
    };

    getPatientName();
  }, []);

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFilePreview(reader.result);
      };
      if (file.type.startsWith("image/")) {
        reader.readAsDataURL(file);
      } else {
        setFilePreview(null);
      }
    } else {
      setFilePreview(null);
    }
  }, [file]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      setFile(selectedFile);
      setCurrentFile(selectedFile);
      setFileName(selectedFile.name);
    }
  };
  const [enabledText, setEnabledText] = useState(false);

  const handleFileTypeChange = (event: SelectChangeEvent<string>) => {
    if (event.target.value === "Others") {
      setEnabledText(true);
      setForm({ ...form, title: "" });
      setFileTitle("");
      setSelectedFileType("Others");
    } else {
      setEnabledText(false);
      setSelectedFileType(event.target.value as string);
      setForm({ ...form, title: event.target.value as string });
      setFileTitle(event.target.value as string);
    }
  };

  const handleView = (url: string) => {
    // const baseUrl = process.env.REACT_APP_FILE_URL_GET;
    const fullUrl = `${skynet}/${url}`;
    window.open(fullUrl, "_blank");
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setFileTitle(value);
    setForm({ ...form, title: event.target.value as string });
  };

  const [previewImage, setPreviewImage] = useState("");

  const handleClearFile = () => {
    setCurrentFile(null);
    setFile(null);
    setCurrentFile(null);
    setFileName("");
  };

  const submitHandler = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    try {
      const body = { ...form, patient: pTId, title: fileTitle };
      if (currentFile) {
        const formData = new FormData();
        formData.append("file", currentFile);
        const response = await uploadFile(formData);
        if (response?.data?.success && response?.data?.result) {
          body.file_url = response.data.result ?? "";
        } else {
          throw new Error("File upload failed");
        }
      }

      if (body.patient && body.file_url && body.title) {
        addFiles(body);
      } else {
        showNotification("error", "Check if all fields are filled");
      }
    } catch (err) {
      console.error(err);
      showNotification("error", "Unable to add file");
    }
  };
  const [toDelete, setToDelete] = useState<number | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [open, setOpen] = useState(false);
  const handleSelectDelete = (id: number) => {
    setToDelete(id);
    setOpen(true);
  };

  const handleDelete = () => {
    if (toDelete !== null) {
      setIsDeleting(true);
      deleteFunc(toDelete);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setToDelete(null);
  };

  const handleCancelDelete = () => {
    handleClose();
  };
  return (
    <div className="w-full overflow-hidden mx-auto mt-24 relative bg-white h-auto drop-shadow-lg rounded-lg p-8">
      <span className="font-Poppins text-xl font-semibold">
        Upload Documents
      </span>
      <div className="flex flex-col items-start mt-4">
        <span className="font-Poppins text-sm font-semibold">
          Patient Name: {patientName}
        </span>
      </div>
      <div className="flex flex-col gap-12 items-start mt-4">
        <FormControl fullWidth margin="dense" variant="outlined">
          <InputLabel
            style={{
              color: "gray",
              fontFamily: "Poppins, sans-serif",
              fontSize: "13px",
            }}
          >
            Select Document type
          </InputLabel>
          <Select
            name="Select Document type"
            value={selectedFileType}
            onChange={handleFileTypeChange}
            label="Select Document type"
            sx={{
              width: "30%",
              "& .MuiOutlinedInput-root": {
                borderRadius: "1.5rem",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "inherit",
                borderRadius: "1.5rem",
              },
              "& .MuiInputBase-input": {
                height: "1rem",
                padding: "1rem 1rem",
              },
              "& .MuiSelect-icon": {
                color: "gray",
              },
            }}
            className="rounded-3xl bg-white text-black"
          >
            {fileTypes.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {selectedFileType && (
          <div className="flex flex-col items-start">
            <input
              type="file"
              id="file-input"
              onChange={handleFileChange}
              className="hidden"
            />
            <label htmlFor="file-input">
              <Button
                variant="contained"
                color="secondary"
                component="span"
                startIcon={<CloudUploadIcon />}
              >
                Choose File
              </Button>
            </label>
          </div>
        )}

        <section className=" flex items-center space-x-4">
          <div>
            {enabledText && (
              <>
                <TextField
                  name="Title"
                  label="Title"
                  value={form.title}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  InputLabelProps={{
                    style: {
                      color: "gray",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "13px",
                    },
                  }}
                  InputProps={{
                    inputProps: {
                      maxLength: 10,
                      pattern: "[0-9]*",
                    },
                    classes: {
                      root: "text-black",
                      input: "h-10 py-0",
                    },
                  }}
                  inputProps={{
                    className: "text-black font-Poppins",
                  }}
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "1.5rem",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "inherit",
                    },
                    "& .MuiInputBase-input": {
                      height: "1rem",
                      padding: "1rem 1rem",
                    },
                  }}
                  onChange={handleInputChange}
                />
              </>
            )}
          </div>

          <div className="flex flex-col items-start">
            {file && file.type.startsWith("image/") && filePreview && (
              <img
                src={filePreview as string}
                alt="Preview"
                className="w-32 h-32 object-cover"
              />
            )}
            {file && !file.type.startsWith("image/") && (
              <div className="flex flex-col items-center">
                {file.type === "application/pdf" ? (
                  <BsFilePdf size={48} color="red" />
                ) : (
                  <Typography variant="subtitle1">{fileName}</Typography>
                )}
              </div>
            )}
          </div>
        </section>
        {file && (
          <div className="flex items-start space-x-10">
            <button className="edit-btn" onClick={submitHandler}>
              Upload
            </button>
            <button className="edit-btn-warning" onClick={handleClearFile}>
              Cancel
            </button>
          </div>
        )}
      </div>
      <CommonTable
        tableHeaders={userHeaderNames}
        totalRows={3}
        colSpan={userHeaders.length}
        pageTitle={pageTitle}
        showInchargeFilter={false}
        showDesignationFilter={false}
        showDateRangePicker={false}
        showIdFilter={false}
        showMobileFilter={false}
        showNameFilter={false}
        showSearchButton={false}
      >
        {ptFiles?.data?.map((file: Document) => (
          <TableRow key={file.id}>
            <TableCell align="center" className="capitalize">
              {file.id}
            </TableCell>
            <TableCell align="center" className="capitalize">
              {file.title}
            </TableCell>
            <TableCell align="center" className="capitalize">
              {dayjs(file.created_on).format("DD-MM-YYYY")}
            </TableCell>
            <TableCell align="center" className="space-x-3">
              <Tooltip title="View" arrow>
                <button
                  className="view-btn"
                  onClick={() => handleView(file.file_url)}
                >
                  <PiEyeFill />
                </button>
              </Tooltip>
              <Tooltip title="Delete" arrow>
                <button
                  onClick={() => handleSelectDelete(file?.id)}
                  className="del-btn"
                >
                  <MdDeleteForever />
                </button>
              </Tooltip>
            </TableCell>
          </TableRow>
        ))}
      </CommonTable>
      <DeleteModal
        open={open}
        onClose={handleClose}
        title="Delete Doctor ?"
        contentText={
          "Are you sure you want to delete this file?\n" +
          "This action cannot be undone."
        }
        onCancel={handleCancelDelete}
        onConfirm={handleDelete}
        isDeleting={isDeleting}
      />
    </div>
  );
};

export default UploadDocument;
