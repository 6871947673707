import React, { useState } from "react";
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useMutationQuery } from "../../hooks/queryHook";
import { addDoctor } from "../../services/api";
import { showNotification } from "../../services/helper";
import { useQueryClient } from "@tanstack/react-query";
import { queryConfigs } from "../../hooks/config";

type AddDoctorProps = {
  open: boolean;
  onClose: () => void;
};

const AddDoctor = ({ open, onClose }: AddDoctorProps) => {
  const [formValues, setFormValues] = useState({
    name: "",
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const queryClient = useQueryClient();
  const { queryFn, invalidateKey } = queryConfigs.useAddDoctor;
  const { mutate } = useMutationQuery({
    key: invalidateKey,
    func: queryFn,
    onSuccess: () => {
      showNotification("success", "Doctor added successfully");
      onClose();
    },
    client: queryClient,
  });

  const handleSubmit = () => {
    mutate({ name: formValues.name });

    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <div className="bg-[#1C1C1C] sticky yop-0 z-10 flex justify-between items-center px-4">
        <DialogTitle sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}>
          Add New Doctor
        </DialogTitle>
        <IconButton onClick={onClose} sx={{ color: "white" }}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent>
        <TextField
          required
          name="name"
          label="Name"
          value={formValues.name}
          onChange={handleInputChange}
          fullWidth
          margin="dense"
          variant="outlined"
          InputLabelProps={{
            style: {
              color: "gray",
              fontFamily: "Poppins, sans-serif",
              fontSize: "13px",
            },
          }}
          InputProps={{
            classes: {
              root: "text-black",
              input: "h-8 py-0",
            },
          }}
          inputProps={{
            className: "text-black",
          }}
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-root": {
              borderRadius: "1.5rem",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "inherit",
            },
            "& .MuiInputBase-input": {
              height: "1rem",
              padding: "1rem 1rem",
            },
          }}
          className="rounded-3xl bg-white"
        />
      </DialogContent>
      <div className="flex justify-center mb-4 items-center">
        <DialogActions>
          <div className="mr-12">
            <button
              onClick={handleSubmit}
              className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group"
            >
              <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#4CAF50] opacity-[3%]" />
              <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#4CAF50] opacity-100 group-hover:-translate-x-8" />
              <span className="relative w-full text-left text-[#4CAF50] transition-colors duration-200 ease-in-out group-hover:text-white">
                Add
              </span>
              <span className="absolute inset-0 border-2 border-[#4CAF50] rounded-lg" />
            </button>
          </div>
          <div className="ml-12">
            <button
              onClick={onClose}
              className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group"
            >
              <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#d00000] opacity-[3%]" />
              <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#d00000] opacity-100 group-hover:-translate-x-8" />
              <span className="relative w-full text-left text-[#d00000] transition-colors duration-200 ease-in-out group-hover:text-white">
                Cancel
              </span>
              <span className="absolute inset-0 border-2 border-[#d00000] rounded-lg" />
            </button>
          </div>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default AddDoctor;
