import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { showNotification } from "../services/helper";
import { MutationProp, TQueryProp, ResponseType, TQueryParams } from "../types/common";

export const useGetQuery = ({ key, params, isEnabled, func }: TQueryProp) => {
    const offset = params?.offset || 0;
    const limit = params?.limit || 10;
    const queryParams: TQueryParams = {
        ...params,
        offset,
        limit
    };

    const keyArr: any[] = [...key];
    for (const [key, value] of Object.entries(queryParams)) {
        keyArr.push({ [key]: value });
    }

    return useQuery({
        queryKey: keyArr,
        queryFn: () => func(queryParams),
        enabled: isEnabled !== undefined ? isEnabled : true,
    });
};

export const useMutationQuery = ({ key, func, onSuccess, invalidateKeys }: MutationProp) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: func,
        onSuccess: (data: ResponseType) => {
            if (data.success) {
                onSuccess(data);
                queryClient.invalidateQueries({ queryKey: key });
                // Invalidate additional keys if provided
                if (invalidateKeys) {
                    invalidateKeys.forEach((invalidateKey) => {
                        queryClient.invalidateQueries({ queryKey: invalidateKey });
                    });
                }
            } else {
                const errorMessage = data?.message || data?.error || 'An error occurred';
                showNotification("error", errorMessage);
            }
        }
    });
};
