import {
  DialogActions,
  DialogTitle,
  IconButton,
  Backdrop,
  Modal,
  Box,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useParams } from "react-router-dom";
import { queryConfigs } from "../../hooks/config";
import { useGetQuery, useMutationQuery } from "../../hooks/queryHook";
import { showNotification } from "../../services/helper";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";
import { useState } from "react";
import { Admission } from "../../types/common";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);
interface DataItem {
  id: number;
  month: number;
  year: number;
}
interface DueData {
  total_due: number;
  entries: DataItem[];
}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: 500,
  bgcolor: "background.paper",
  backdropFilter: "blur(3px)",
  borderRadius: 2,
  overflowY: "auto",
};
type DischargeProps = {
  open: boolean;
  onClose: () => void;

  admission?: Admission | null;
};
const DischargeModal = ({ open, onClose, admission }: DischargeProps) => {
  const { queryKey: ptKey, queryFn: getPtFn } = queryConfigs.getPatientById;
  const { data: ptData, isLoading } = useGetQuery({
    key: ptKey,
    func: getPtFn,
    params: {
      id: admission?.patient,
    },
    isEnabled: admission?.patient ? true : false,
  });

  const { queryKey, queryFn, invalidateKey } = queryConfigs.useDischargePatient;
  const { mutate } = useMutationQuery({
    key: queryKey,
    func: queryFn,
    invalidateKeys: invalidateKey,
    onSuccess: () => {
      showNotification("success", "Patient discharged successfully");
      onClose();
    },
  });
  const { queryKey: dueKey, queryFn: getDue } = queryConfigs.useGetTotalDueByPT;
  const { data: dueData, isLoading: isDueLoading } = useGetQuery({
    key: dueKey,
    func: getDue,
    params: { admission: admission?.id },
    isEnabled: admission?.id ? true : false,
  });

  const handleSubmit = () => {
    const updatedState = {
      discharge_date: formData.dischargeDate,
      patient: admission?.patient,
      admission: admission?.id,
    };
    if (!formData.dischargeDate || formData.dischargeDate.trim() === "") {
      showNotification(
        "error",
        "Discharge date is required. Please try again."
      );
      return;
    }
    mutate(updatedState);
    setFormData({
      dischargeDate: "",
    });
  };

  const navigate = useNavigate();

  const handleBillClick = () => {
    if (admission?.id && admission?.id > 0) {
      navigate(
        `/admin/in-patient/${admission?.patient}/billings/${admission?.id}`
      );
    }
  };
  const [formData, setFormData] = useState({
    dischargeDate: "",
  });

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    const formattedDate = dayjs(date).format("YYYY-MM-DD HH:mm:ss");
    setFormData({ ...formData, dischargeDate: formattedDate });
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Box sx={style}>
        {!isDueLoading && (
          <>
            <div className="bg-[#1C1C1C] flex justify-between items-center px-4">
              <DialogTitle
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Discharge
              </DialogTitle>
              <IconButton onClick={onClose} sx={{ color: "white" }}>
                <CloseIcon />
              </IconButton>
            </div>

            <div className="my-4 px-5 text-center font-Poppins mt-10">
              <p>Patient has a due balance of {dueData?.total_due}</p>
            </div>
            {dueData?.total_due === 0 && (
              <div
                style={{ zIndex: 999 }}
                className="flex justify-center my-6 items-center"
              >
                <DatePicker
                  selected={selectedDate ? dayjs(selectedDate).toDate() : null}
                  onChange={handleDateChange}
                  dateFormat="dd, MMMM, yyyy"
                  placeholderText="Select a Date"
                  maxDate={dayjs().toDate()}
                  className="px-6 py-4  h-12 border border-gray-200 rounded-3xl placeholder-gray-500 mt-2"
                />
              </div>
            )}
          </>
        )}
        <div className="flex justify-center mb-4 items-center">
          {dueData?.total_due === 0 ? (
            <button
              onClick={handleSubmit}
              className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group"
            >
              <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#4CAF50] opacity-[3%]"></span>
              <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#4CAF50] opacity-100 group-hover:-translate-x-8"></span>
              <span className="relative w-full text-left text-[#4CAF50] transition-colors duration-200 ease-in-out group-hover:text-white">
                Discharge
              </span>
              <span className="absolute inset-0 border-2 border-[#4CAF50] rounded-lg"></span>
            </button>
          ) : (
            <div className="flex justify-center mb-4 items-center">
              <DialogActions>
                <div className="mr-12">
                  <button
                    onClick={handleBillClick}
                    className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group"
                  >
                    <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#4CAF50] opacity-[3%]"></span>
                    <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#4CAF50] opacity-100 group-hover:-translate-x-8"></span>
                    <span className="relative w-full text-left text-[#4CAF50] transition-colors duration-200 ease-in-out group-hover:text-white">
                      Pay Bill
                    </span>
                    <span className="absolute inset-0 border-2 border-[#4CAF50] rounded-lg"></span>
                  </button>
                </div>
                <div className="ml-12">
                  <button
                    onClick={onClose}
                    className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group"
                  >
                    <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#d00000] opacity-[3%]"></span>
                    <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#d00000] opacity-100 group-hover:-translate-x-8"></span>
                    <span className="relative w-full text-left text-[#d00000] transition-colors duration-200 ease-in-out group-hover:text-white">
                      Cancel
                    </span>
                    <span className="absolute inset-0 border-2 border-[#d00000] rounded-lg"></span>
                  </button>
                </div>
              </DialogActions>
            </div>
          )}
        </div>
        <div className="px-4 flex items-end h-[30%]">
          <p className="text-xs font-medium font-Poppins">
            *Note: Patient will an outstanding bill cannot be discharge until
            the bill has been paid
          </p>
        </div>
      </Box>
    </Modal>
  );
};

export default DischargeModal;
