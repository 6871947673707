import React, { useState } from "react";
import CommonTable from "../CommonTable";
import {
  TableCell,
  TableRow,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  Popover,
  Button,
  TextField,
  TablePagination,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddPatient from "./AddPatient";
import {
  getEndingTimeOfDate,
  getStartingTimeOfDate,
  showNotification,
} from "../../services/helper";
import DeleteModal from "../DeleteModal";
import { useGetQuery, useMutationQuery } from "../../hooks/queryHook";
import { queryConfigs } from "../../hooks/config";
import { useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import PtCard from "./PtCard";
import { PiEyeFill } from "react-icons/pi";
import { useNavigate, useParams } from "react-router-dom";
import { FaBook, FaEdit } from "react-icons/fa";
import { IoStatsChart } from "react-icons/io5";
import { MdDeleteForever, MdReceipt } from "react-icons/md";
import { IoDocumentAttachSharp } from "react-icons/io5";
import AddAdmission from "../inPatient/AddInPatient";
import { FaHospitalUser } from "react-icons/fa6";
import AddInPatient from "../inPatient/AddInPatient";
import CustomStatusChip from "../CustomStatusChip";
import { uploadFile } from "../../services/api";
import { UploadResponse } from "../../types/common";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import EditPatient from "./EditPatient";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

type PatientsProps = {
  pageTitle: string;
};
type PtRow = {
  id: number;
  created_on: string;
  name: string;
  mobile: string;
  age: string;
  diagnosis: string;
  marital_status: string;
  address: string;
  district: string;
  pincode: string;
  emergency_contact: string;
  gender: string;
  remark: string;
  admission_charge: string;
  admission_date: string;
  referred_by: string;
  is_discharged: number;
};

type Header = {
  name: string;
};

const userHeaders: Header[] = [
  { name: "ID" },
  { name: "Name" },
  { name: "Mobile" },
  { name: "Emergency Contact" },
  { name: "Discharge Status" },
  { name: "Created On" },
  { name: "Action" },
];

const Patients: React.FC<PatientsProps> = ({ pageTitle }) => {
  const { queryKey, queryFn } = queryConfigs.getPatients;
  const userHeaderNames: string[] = userHeaders.map((header) => header.name);
  const [chartStartDate, setChartStartDate] = useState<string | null>(null);
  const [chartEndDate, setChartEndDate] = useState<string | null>(null);

  const [patientSearch, setPatientSearch] = useState<{
    id: number | null;
    name: string | null;
  }>({
    id: null,
    name: null,
  });
  const [searchParams, setSearchParams] = useState<{
    id: number | null;
    name: string | null;
  }>({
    id: null,
    name: null,
  });
  const queryClient = useQueryClient();
  const { invalidateKey, mutationFn } = queryConfigs.deletePatient;
  const { mutate: deleteMedicine } = useMutationQuery({
    key: invalidateKey,
    func: mutationFn,
    onSuccess: () => {
      showNotification("success", "Patient deleted successfully");
      handleDeleteClose();
    },
    onError: (error) => {
      showNotification("error", error.message);
      setIsDeleting(false);
    },
    client: queryClient,
  });
  const isEnabled =
    (chartStartDate === null && chartEndDate === null) ||
    (chartStartDate !== null && chartEndDate !== null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState({ offset: 0, limit: 10 });
  const limit = pagination.limit;
  const {
    data: ptData,
    isLoading,
    refetch,
  } = useGetQuery({
    key: queryKey,
    func: queryFn,
    params: {
      offset: (currentPage - 1) * pagination.limit,
      limit: pagination.limit,
      name: searchParams.name ?? "",
      id: searchParams.id ?? null,
      startDate: chartStartDate,
      endDate: chartEndDate,
    },
    isEnabled: isEnabled,
  });
  const [isAddPatientOpen, setIsAddPatientOpen] = useState(false);
  const [isAdmissionOpen, setIsAdmissionOpen] = useState(false);
  const [toDelete, setToDelete] = useState<number | null>(null);
  const [open, setOpen] = useState(false);
  const [editValue, setEditValue] = useState({
    name: "",
    mobile: "",
    age: "",
    diagnosis: "",
    gender: "",
    admission_charge: 0,
    marital_status: "",
    address: "",
    district: "",
    pincode: "",
    emergency_contact: "",
    remark: "",
    referred_by: 0,
    admission_date: "",
  });
  const [editOpen, setEditOpen] = useState(false);
  const [ptId, setPtId] = useState<number | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);

  function handleAddClick(): void {
    setIsAddPatientOpen(true);
  }
  const handleEditClick = (pt: PtRow) => {
    setEditOpen(true);
    setPtId(pt.id);
  };

  // const handleEditClick = (pt: PtRow) => {
  //   setEditOpen(true);
  //   // if (pt.id > 0) {
  //   //     setEditValue({
  //   //         id: pt.id,
  //   //         name: pt.name,
  //   //     });
  //   // } else {
  //   //     return showNotification("error", "Try Again")
  //   // }
  // };

  const handleSelectDelete = (id: number) => {
    setToDelete(id);
    setOpen(true);
  };
  const handleDeleteClose = () => {
    setToDelete(null);
    setOpen(false);
  };

  const handleDelete = () => {
    if (toDelete !== null) {
      setIsDeleting(true);
      deleteMedicine(toDelete);
    }
  };
  const [view, setView] = useState({});
  const [selectedPtID, setSelectedPtID] = useState<PtRow | null>(null);
  const [viewOpen, setViewOpen] = useState(false);

  const handleClickAdmission = (ptData: PtRow) => {
    if (ptData.id > 0) {
      setIsAdmissionOpen(true);
      setSelectedPtID(ptData);
    }
  };

  const handleViewClick = (pt: PtRow) => {
    setViewOpen(true);
    if (pt.id > 0) {
      setView({ ...pt });
    }
  };
  const navigate = useNavigate();
  const handleVitalNavigate = (id: number) => {
    navigate(`/admin/patient/${id}/vitals`);
  };
  const handleUploadDocumentNavigate = (id: number) => {
    navigate(`/admin/patient/${id}/documents`);
  };
  const handleTreatmentNavigate = (id: number) => {
    navigate(`/admin/patient/${id}/treatments`);
  };
  const handleBillClick = (pt: PtRow) => {
    if (pt.id > 0) {
      // `/admin/in-patient/${pt.id}/billings/${pt.admission}`;
      navigate(`/admin/patients/billings/${pt.id}`);
    }
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedPt, setSelectedPt] = useState<PtRow | null>(null);
  // const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
  //     setAnchorEl(event.currentTarget);
  // };
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>, pt: PtRow) => {
    setAnchorEl(event.currentTarget);
    setSelectedPt(pt);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedPt(null);
  };
  const [image, setImage] = useState<File | null>(null);
  const [editImage, setEditImage] = useState<File | null>(null);
  const [editImgUrl, setEditImgUrl] = useState<string>("");
  const [hovered, setHovered] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isEditPatientOpen, setIsEditPatientOpen] = useState<boolean>(false);

  const uploadImage = async (img: File): Promise<string | void> => {
    try {
      const formData = new FormData();
      formData.append("file", img);
      setIsUploading(true);
      const response: UploadResponse = await uploadFile(formData);
      if (response.status === 200 && response.data.success) {
        setImage(null);
        setIsUploading(false);
        showNotification("success", "Image uploaded successfully");
        return response.data.result;
      }
    } catch (error) {
      console.error(error);
      showNotification("error", "Cannot Upload Image");
      setIsUploading(false);
    }
  };
  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => {
    setCurrentPage(page + 1);
    setPagination((prev) => ({
      ...prev,
      offset: page * pagination.limit,
    }));
  };
  // Date handling functions
  const handleChartDateRange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    const formattedStart = start ? getStartingTimeOfDate(dayjs(start)) : null;
    const formattedEnd = end ? getEndingTimeOfDate(dayjs(end)) : null;
    setChartStartDate(formattedStart);
    setChartEndDate(formattedEnd);
  };
  const handleClear = () => {
    setPatientSearch({
      id: null,
      name: null,
    });
    setSearchParams({
      id: null,
      name: null,
    });
    setChartStartDate(null);
    setChartEndDate(null);
  };
  // Search functions
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setPatientSearch((prevState) => ({
      ...prevState,
      [name]: name === "id" ? (value ? Number(value) : null) : value,
    }));
  };

  const handleSearch = () => {
    setSearchParams(patientSearch);
  };
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };
  const handleRefetch = () => {
    refetch();
    handleClear();
  };

  return (
    <>
      <div className="w-full overflow-hidden mx-auto relative h-screen">
        <CommonTable
          refetchIcon={true}
          onRefetchClick={handleRefetch}
          tableHeaders={userHeaderNames}
          totalRows={ptData?.count || 0}
          colSpan={userHeaders.length}
          pageTitle={pageTitle}
          onAddClick={handleAddClick}
          marginTop={16}
          showInchargeFilter={false}
          showDesignationFilter={false}
          children3={
            <TablePagination
              component="div"
              count={ptData?.count || 0}
              page={currentPage - 1}
              rowsPerPage={limit}
              onPageChange={handlePageChange}
              onRowsPerPageChange={(event) => {
                const newLimit = parseInt(event.target.value, 10);
                setPagination((prev) => ({
                  ...prev,
                  limit: newLimit,
                  offset: 0,
                }));
                setCurrentPage(1);
              }}
              sx={{ color: "#38a3a5", font: "Poppins, sans-serif" }}
            />
          }
          children2={
            <>
              <div className=" flex items-center space-x-5 w-full z-50">
                <DatePicker
                  selected={
                    chartStartDate ? dayjs(chartStartDate).toDate() : null
                  }
                  onChange={handleChartDateRange}
                  startDate={
                    chartStartDate ? dayjs(chartStartDate).toDate() : undefined
                  }
                  endDate={
                    chartEndDate ? dayjs(chartEndDate).toDate() : undefined
                  }
                  selectsRange
                  dateFormat="yyyy-MM-dd"
                  placeholderText="Select Date Range"
                  className="w-[300px] border border-gray-300 rounded py-2 px-4"
                />
                <TextField
                  label="Patient Name"
                  variant="outlined"
                  name="name"
                  value={patientSearch.name || ""}
                  onChange={handleChange}
                  size="small"
                  sx={{
                    width: "20%",
                    "& .MuiInputLabel-root": {
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "13px",
                    },
                  }}
                />
                <TextField
                  label="Patient ID"
                  variant="outlined"
                  name="id"
                  value={
                    patientSearch.id !== null ? patientSearch.id.toString() : ""
                  }
                  onChange={handleChange}
                  size="small"
                  sx={{
                    width: "20%",
                    "& .MuiInputLabel-root": {
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "13px",
                    },
                  }}
                />

                <button onClick={handleSearch} className="search-btn">
                  Search
                </button>
                <button onClick={handleClear} className="clear-btn">
                  Clear
                </button>
              </div>
            </>
          }
        >
          {ptData?.list?.map((pt: PtRow) => (
            <TableRow key={pt.id}>
              <TableCell align="center" className="capitalize">
                {pt.id}
              </TableCell>
              <TableCell align="center">
                <p className="capitalize font-sans">{pt.name}</p>
              </TableCell>
              <TableCell align="center" className="capitalize">
                {pt.mobile}
              </TableCell>
              <TableCell align="center" className="capitalize">
                {pt.emergency_contact}
              </TableCell>
              <TableCell align="center" className="capitalize">
                <CustomStatusChip value={pt?.is_discharged ?? 0} />
              </TableCell>
              <TableCell align="center" className="capitalize">
                {dayjs(pt.admission_date).format("DD-MM-YYYY")}
              </TableCell>
              <TableCell align="center">
                <IconButton onClick={(event) => handleMenuClick(event, pt)}>
                  <MoreVertIcon />
                </IconButton>
                <Popover
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  onClose={handleMenuClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  sx={{ marginLeft: "-70px", marginTop: "6px", zIndex: 999 }}
                  slotProps={{
                    paper: {
                      style: {
                        boxShadow: "none",
                      },
                    },
                  }}
                >
                  <div className="flex space-x-2 p-2">
                    <Tooltip title="Edit" arrow>
                      <button
                        onClick={() => {
                          if (selectedPt) handleEditClick(selectedPt);
                          handleMenuClose();
                        }}
                        className="edit-btn"
                      >
                        <FaEdit />
                      </button>
                    </Tooltip>
                    <Tooltip title="Upload Document" arrow>
                      <button
                        onClick={() => {
                          if (selectedPt)
                            handleUploadDocumentNavigate(selectedPt.id);
                          handleMenuClose();
                        }}
                        className="generate-btn"
                      >
                        <IoDocumentAttachSharp />
                      </button>
                    </Tooltip>
                    <Tooltip title="Vital Records" arrow>
                      <button
                        onClick={() => {
                          if (selectedPt) handleVitalNavigate(selectedPt.id);
                          handleMenuClose();
                        }}
                        className="action-btn"
                      >
                        <FaBook />
                      </button>
                    </Tooltip>

                    <Tooltip title="Treatment Charts" arrow>
                      <button
                        onClick={() => {
                          if (selectedPt)
                            handleTreatmentNavigate(selectedPt.id);
                          handleMenuClose();
                        }}
                        className="action-btn"
                      >
                        <IoStatsChart />
                      </button>
                    </Tooltip>
                    <Tooltip title="View" arrow>
                      <button
                        onClick={() => {
                          if (selectedPt) handleViewClick(selectedPt);
                          handleMenuClose();
                        }}
                        className="view-btn"
                      >
                        <PiEyeFill />
                      </button>
                    </Tooltip>
                    <Tooltip title="Billing" arrow>
                      <button
                        onClick={() => {
                          if (selectedPt) handleBillClick(selectedPt);
                          handleMenuClose();
                        }}
                        className="view-btn"
                      >
                        <MdReceipt />
                      </button>
                    </Tooltip>
                    <Tooltip title="Add Admission" arrow>
                      <button
                        onClick={() => {
                          if (selectedPt) handleClickAdmission(selectedPt);
                          handleMenuClose();
                        }}
                        className="action-btn"
                      >
                        <FaHospitalUser />
                      </button>
                    </Tooltip>
                    <Tooltip title="Delete" arrow>
                      <button
                        onClick={() => {
                          if (selectedPt) handleSelectDelete(selectedPt.id);
                          handleMenuClose();
                        }}
                        className="del-btn"
                      >
                        <MdDeleteForever />
                      </button>
                    </Tooltip>
                  </div>
                </Popover>
              </TableCell>
            </TableRow>
          ))}
        </CommonTable>
      </div>
      <AddPatient
        open={isAddPatientOpen}
        onClose={() => setIsAddPatientOpen(false)}
      />
      <EditPatient
        open={editOpen}
        onClose={() => setEditOpen(false)}
        ptId={ptId}
      />
      <AddInPatient
        open={isAdmissionOpen}
        onClose={() => setIsAdmissionOpen(false)}
        ptData={selectedPtID || null}
      />
      <DeleteModal
        open={open}
        onClose={handleDeleteClose}
        title="Delete Patient ?"
        contentText="Are you sure you want to delete this patient? This action cannot be undone."
        onCancel={handleDeleteClose}
        onConfirm={handleDelete}
        isDeleting={isDeleting}
      />
      <PtCard
        editClose={() => setViewOpen(false)}
        openEdit={viewOpen}
        patientInfo={view}
      />
    </>
  );
};

export default Patients;
