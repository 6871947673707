import React, { useEffect, useState } from "react";
import DashboardImage from "../../assets/dashboard.png";
import {
  FaHospitalUser,
  FaCashRegister,
  FaBuildingUser,
} from "react-icons/fa6";
import { FaUserFriends } from "react-icons/fa";
import { MdOutlinePendingActions } from "react-icons/md";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { queryConfigs } from "../../hooks/config";
import { useGetQuery } from "../../hooks/queryHook";
import { useNavigate } from "react-router-dom";
import {
  getEndingTimeOfDate,
  getItem,
  getStartingTimeOfDate,
} from "../../services/helper";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// Define types
type MonthlyData = {
  year: number;
  month: number;
  count: number;
};

type PaymentModeData = {
  total_upi_amount: number;
  total_cash_amount: number;
  total_card_amount: number;
};

type MonthlyAmount = {
  year: number;
  month: number;
  total_due_amount: number;
};

type MonthlyPaidAmount = {
  year: number;
  month: number;
  total_paid: number;
};

type ChartData = {
  discharged_patient_count: number;
  in_patient_count: number;
  patient_count: number;
  total_admissions_by_month: MonthlyData[];
  total_amount_by_payment_mode: PaymentModeData;
  total_due_amount: number;
  total_due_amount_by_month: MonthlyAmount[];
  total_paid_amount: number;
  total_paid_amount_by_month: MonthlyPaidAmount[];
};

interface DataResponse {
  data: ChartData;
  success: boolean;
}

interface DashboardProps {
  isSidebarCollapsed: boolean;
}

const Dashboard: React.FC<DashboardProps> = ({ isSidebarCollapsed }) => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [chartStartDate, setChartStartDate] = useState<string | null>(null);
  const [chartEndDate, setChartEndDate] = useState<string | null>(null);

  const [user, setUser] = useState({
    id: null,
    name: "",
    email: "",
    mobile: "",
    organisation: null,
    image: "",
  });
  useEffect(() => {
    const storedUserData = getItem("user");
    if (storedUserData) {
      setUser({ ...storedUserData });
    }
  }, []);
  const isEnabled =
    (chartStartDate === null && chartEndDate === null) ||
    (chartStartDate !== null && chartEndDate !== null);

  const navigate = useNavigate();
  const { queryKey, queryFn } = queryConfigs.useGetDashboardData;

  const { data, refetch, isLoading } = useGetQuery({
    key: queryKey,
    func: queryFn,
    params: {
      startDate: chartStartDate,
      endDate: chartEndDate,
    },
    isEnabled: isEnabled,
  });

  // Helper function to format month numbers to month names
  const formatMonth = (monthNumber: number) =>
    dayjs()
      .month(monthNumber - 1)
      .format("MMMM");

  // Mapping for patient admissions by month
  const monthlyPatientData: { month: string; count: number }[] =
    data?.data?.total_admissions_by_month.map((item: MonthlyData) => ({
      month: formatMonth(item.month),
      count: item.count,
    }));

  // Mapping for total paid amount by month
  const categoriesForPaidAmt: string[] =
    data?.data?.total_paid_amount_by_month?.map((item: MonthlyPaidAmount) =>
      formatMonth(item.month)
    );

  const seriesDataForPaidAmt: number[] =
    data?.data?.total_paid_amount_by_month?.map(
      (item: MonthlyPaidAmount) => item.total_paid
    );

  // Mapping for total due amount by month
  const categoriesForDue: string[] = data?.data?.total_due_amount_by_month?.map(
    (item: MonthlyAmount) => formatMonth(item.month)
  );

  const seriesDataForDue: number[] = data?.data?.total_due_amount_by_month?.map(
    (item: MonthlyAmount) => item.total_due_amount
  );

  const categoriesForPaymentMode = ["UPI", "Cash", "Card"];
  const seriesDataByPaymentMode = [
    data?.data?.total_amount_by_payment_mode?.total_upi_amount,
    data?.data?.total_amount_by_payment_mode?.total_cash_amount,
    data?.data?.total_amount_by_payment_mode?.total_card_amount,
  ];

  const options2: Highcharts.Options = {
    chart: {
      type: "area",
      backgroundColor: "transparent",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: categoriesForPaidAmt,
      labels: {
        enabled: true,
        style: {
          color: "#FFF",
          fontSize: "12px",
          fontFamily: "Poppins, sans-serif",
        },
        formatter: function () {
          return `<b>${this.value}</b>`;
        },
      },
    },
    yAxis: {
      title: {
        text: "Amount",
        style: {
          color: "#FFF",
          fontSize: "12px",
          fontFamily: "Poppins, sans-serif",
        },
      },
      min: 0,
      labels: {
        style: {
          color: "#FFF",
          fontSize: "12px",
        },
        formatter: function () {
          return `${this.value}`;
        },
      },
    },
    series: [
      {
        type: "area",
        name: "Amount Collected",
        data: seriesDataForPaidAmt,
        color: "#FFFFFF",
        dataLabels: {
          enabled: true,
          color: "#FFFFFF",
          style: {
            textOutline: "none",
          },
          formatter: function () {
            return `${this.y}`;
          },
        },
        fillOpacity: 0.5,
      } as Highcharts.SeriesAreaOptions,
    ],
    credits: {
      enabled: false,
    },
  };

  // bar
  const options3: Highcharts.Options = {
    chart: {
      type: "column",
      backgroundColor: "transparent",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: categoriesForDue,
      gridLineDashStyle: "Dot",
      labels: {
        style: {
          color: "#FFF",
          fontSize: "12px",
          fontFamily: "Poppins, sans-serif",
        },
        formatter: function () {
          return `<b>${this.value}</b>`;
        },
      },
    },
    yAxis: {
      title: {
        text: "Amount",
        style: {
          color: "#FFF",
          fontSize: "12px",
          fontFamily: "Poppins, sans-serif",
        },
      },
      min: 0,
      labels: {
        style: {
          color: "#FFF",
          fontSize: "12px",
        },
        formatter: function () {
          return `${this.value}`;
        },
      },
      tickInterval: 2000,
    },
    series: [
      {
        type: "column",
        name: "Due Amount",
        data: seriesDataForDue,
        color: "#FFFFFF",
        dataLabels: {
          enabled: true,
          color: "#FFFFFF",
          style: {
            textOutline: "none",
          },
        },
      },
    ],
    credits: {
      enabled: false,
    },
  };

  // pie
  const options4: Highcharts.Options = {
    chart: {
      type: "pie",
      backgroundColor: "transparent",
    },
    title: {
      text: "Total Amount by Payment Mode",
      style: {
        color: "#00000",
        fontSize: "16px",
        fontFamily: "Poppins, sans-serif",
      },
    },
    tooltip: {
      formatter: function (): string {
        return `<b>${this.point.name}</b>: ${this.point.y}`;
      },
      backgroundColor: "#FFFFFF",
      style: {
        color: "#00000",
        fontSize: "12px",
        fontFamily: "Poppins, sans-serif",
      },
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: true, // Enable data labels to be visible permanently
          format: "{point.name}: {point.y}",
          color: "#00000",
          style: {
            textOutline: "none",
            fontSize: "12px",
            fontFamily: "Poppins, sans-serif",
          },
        },
        showInLegend: true, // Ensure the legend is visible
        colors: Highcharts.getOptions().colors,
      },
    },
    series: [
      {
        type: "pie",
        name: "Total Amount",
        data: categoriesForPaymentMode?.map((category, index) => ({
          name: category,
          y: seriesDataByPaymentMode[index],
        })),
        colorByPoint: true,
      } as Highcharts.SeriesPieOptions,
    ],
    legend: {
      align: "left",
      verticalAlign: "top",
      layout: "horizontal",
      itemStyle: {
        color: "#00000",
        fontSize: "12px",
        fontFamily: "Poppins, sans-serif",
      },
      symbolRadius: 0, // Square legend symbols
      symbolHeight: 10,
      symbolWidth: 10,
    },
    credits: {
      enabled: false,
    },
  };

  useEffect(() => {
    setSidebarCollapsed(isSidebarCollapsed);
  }, [isSidebarCollapsed]);

  useEffect(() => {
    localStorage.setItem("isSidebarCollapsed", sidebarCollapsed.toString());
  }, [sidebarCollapsed]);
  const handleChartDateRange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    const formattedStart = start ? getStartingTimeOfDate(dayjs(start)) : null;
    const formattedEnd = end ? getEndingTimeOfDate(dayjs(end)) : null;
    setChartStartDate(formattedStart);
    setChartEndDate(formattedEnd);
  };
  const handleClear = () => {
    setChartStartDate(null);
    setChartEndDate(null);
  };
  return (
    <>
      {!isLoading ? (
        <>
          <div
            className={`flex flex-col w-full h-screen ${
              sidebarCollapsed ? "ml-[0.5px]" : "-ml-[11px]"
            } py-12 relative transition-all duration-300 ease-in-out`}
          >
            {/* intro */}
            <div
              className={`absolute z-30 top-[15%] bg-white flex flex-col items-start p-4 rounded-lg drop-shadow-lg justify-start ${
                isSidebarCollapsed ? "w-full" : "w-full"
              }`}
            >
              <span className="capitalize text-xl mb-4 font-bold text-[#e76f51]">
                <span className=" text-black text-2xl">Welcome !</span>{" "}
                {user?.name || ""}
              </span>
              <span className="capitalize text-lg font-base text-[#e76f51] font-Poppins">
                Have a nice day ahead...
              </span>
              <img
                src={DashboardImage}
                alt="Dashboard Logo"
                className="absolute -top-[40%] right-[6%] w-[140px] transition-all duration-300 ease-in-out"
              />
            </div>
            {/* chips */}
            <div className="absolute flex top-[35%] w-full">
              <div className="w-1/5 bg-white rounded-lg drop-shadow-lg h-32 m-2 flex items-center justify-center">
                <FaUserFriends className="absolute -top-4 left-2 bg-gradient-to-b from-[#3F3F47] to-[#1C1C1C] rounded-lg p-4 drop-shadow-lg text-white text-7xl" />
                <span className="absolute top-4 right-2 capitalize text-sm font-light text-slate-500 font-Poppins">
                  total patient
                </span>
                <span className="absolute top-16 right-2 capitalize text-xl font-semibold text-black font-Poppins">
                  {data?.data?.patient_count}
                </span>
                <p className="absolute bottom-2 left-2 text-slate-500 text-sm font-light font-Poppins">
                  Till today
                </p>
              </div>
              <div className="w-1/5 bg-white rounded-lg drop-shadow-lg h-32 m-2 flex items-center justify-center">
                <FaHospitalUser className="absolute -top-4 left-2 bg-gradient-to-b from-[#E83A75] to-[#DA2064] rounded-lg p-4 drop-shadow-lg text-white text-7xl" />
                <span className="absolute top-4 right-2 capitalize text-sm font-light text-slate-500 font-Poppins">
                  in patient
                </span>
                <span className="absolute top-16 right-2 capitalize text-xl font-semibold text-black font-Poppins">
                  {data?.data?.in_patient_count}
                </span>
                <p className="absolute bottom-2 left-2 text-slate-500 text-sm font-light font-Poppins">
                  Till today
                </p>
              </div>
              <div className="w-1/5 bg-white rounded-lg drop-shadow-lg h-32 m-2 flex items-center justify-center">
                <FaBuildingUser className="absolute -top-4 left-2 bg-gradient-to-b from-[#66BA6A] to-[#44A149] rounded-lg p-4 drop-shadow-lg text-white text-7xl" />
                <span className="absolute top-4 right-2 capitalize text-sm font-light text-slate-500 font-Poppins">
                  discharged
                </span>
                <span className="absolute top-16 right-2 capitalize text-xl font-semibold text-black font-Poppins">
                  {data?.data?.discharged_patient_count}
                </span>
                <p className="absolute bottom-2 left-2 text-slate-500 text-sm font-light font-Poppins">
                  Till today
                </p>
              </div>
              <div className="w-1/5 bg-white rounded-lg drop-shadow-lg h-32 m-2 flex items-center justify-center">
                <FaCashRegister className="absolute -top-4 left-2 bg-gradient-to-b from-[#459EF1] to-[#2179E9] rounded-lg p-4 drop-shadow-lg text-white text-7xl" />
                <span className="absolute top-4 right-2 capitalize text-sm font-light text-slate-500 text-end font-Poppins">
                  total amount
                  <br />
                  collected
                </span>
                <span className="absolute top-16 right-2 capitalize text-xl font-semibold text-black font-Poppins">
                  ₹{data?.data?.total_paid_amount}
                </span>
                <p className="absolute bottom-2 left-2 text-slate-500 text-sm font-light font-Poppins">
                  Till today
                </p>
              </div>
              <div className="w-1/5 bg-white rounded-lg drop-shadow-lg h-32 m-2 flex items-center justify-center">
                <MdOutlinePendingActions className="absolute -top-4 left-2 bg-gradient-to-b from-[#faa307] to-[#faa307] rounded-lg p-4 drop-shadow-lg text-white text-7xl" />
                <span className="absolute top-4 right-2 capitalize text-sm font-light text-slate-500 text-end font-Poppins">
                  total pending
                  <br /> dues
                </span>
                <span className="absolute top-16 right-2 capitalize text-xl font-semibold text-black font-Poppins">
                  ₹{data?.data?.total_due_amount}
                </span>
                <p className="absolute bottom-2 left-2 text-slate-500 text-sm font-light font-Poppins">
                  Till today
                </p>
              </div>
            </div>
            <div className="absolute flex top-[59%]  items-center space-x-5 w-full">
              <DatePicker
                selected={
                  chartStartDate ? dayjs(chartStartDate).toDate() : null
                }
                onChange={handleChartDateRange}
                startDate={
                  chartStartDate ? dayjs(chartStartDate).toDate() : undefined
                }
                endDate={
                  chartEndDate ? dayjs(chartEndDate).toDate() : undefined
                }
                selectsRange
                dateFormat="yyyy-MM-dd"
                placeholderText="Select Date Range"
                className="w-[300px] border border-gray-300 rounded py-2 px-4"
              />
              <button onClick={handleClear} className="clear-btn">
                Clear
              </button>
            </div>
            {/* highcharts */}
            <div className="flex justify-between absolute top-[70%] w-full py-12 ">
              {/* bar */}
              <div className="w-1/2 mx-4">
                <div className="relative">
                  <div className="w-full h-[450px] bg-white rounded-lg p-4 drop-shadow-lg">
                    <span className="absolute bottom-[40px] left-[50%] -translate-x-[50%] text-sm w-full flex justify-center items-center font-Poppins font-semibold">
                      Amount Due Per Month this year
                    </span>
                    <div className="w-[90%] absolute -top-12 bg-gradient-to-r from-[#459EF1] to-[#2179E9] rounded-lg drop-shadow-lg">
                      <div
                        style={{
                          height: "400px",
                          padding: "16px",
                          boxSizing: "border-box",
                        }}
                      >
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={options3}
                          containerProps={{ style: { height: "100%" } }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* area */}
              <div className="w-1/2 mx-4">
                <div className="relative">
                  <div className="w-full h-[450px] bg-white rounded-lg p-4 drop-shadow-lg">
                    <span className="absolute bottom-[40px] left-[50%] -translate-x-[50%] text-sm w-full flex justify-center items-center font-Poppins font-semibold">
                      Amount Collected Per Month this year
                    </span>
                    <div className="w-[90%] absolute -top-12 bg-gradient-to-r from-[#66BA6A] to-[#44A149] rounded-lg drop-shadow-lg">
                      <div
                        style={{
                          height: "400px",
                          padding: "16px",
                          boxSizing: "border-box",
                        }}
                      >
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={options2}
                          containerProps={{ style: { height: "100%" } }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* pie */}
            <div className="absolute top-[105%] py-8 px-4 w-full flex justify-between space-x-2">
              <div className="bg-white rounded-lg shadow-md w-[60%] mt-32">
                <HighchartsReact highcharts={Highcharts} options={options4} />
              </div>
              <div className="bg-white rounded-lg shadow-md w-[40%] mt-32">
                <div className="p-4">
                  <h2 className="text-md text-center font-Poppins font-bold mb-4">
                    Patients Admitted Per Month
                  </h2>
                  <div className="overflow-x-auto">
                    <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md">
                      <thead className="bg-gray-300 text-black">
                        <tr>
                          <th className="py-2 px-4 border-b font-Poppins">
                            Sl. No
                          </th>
                          <th className="py-2 px-4 border-b font-Poppins">
                            Month
                          </th>
                          <th className="py-2 px-4 border-b font-Poppins">
                            Total Patients
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {monthlyPatientData?.map((data, index) => (
                          <tr key={data.month}>
                            <td className="py-2 px-4 border-b text-slate-600 text-center font-Poppins">
                              {index + 1}
                            </td>
                            <td className="py-2 px-4 border-b  text-slate-600 font-light font-Poppins">
                              {data.month}
                            </td>
                            <td className="py-2 px-4 border-b font-semibold font-Poppins text-center">
                              {data.count}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default Dashboard;
