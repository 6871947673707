import React, { useEffect, useState } from "react";
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { queryConfigs } from "../../hooks/config";
import { useMutationQuery } from "../../hooks/queryHook";
import { showNotification } from "../../services/helper";

type Tprops = {
  open: boolean;
  onClose: () => void;
  userData: TUserData;
};

interface FormValues {
  password: string;
  repassword: string;
}

interface TUserData {
  id?: number;
  password?: string;
  repassword?: string;
}

const UpdatePassword = ({ open, onClose, userData }: Tprops) => {
  const { mutationFn, invalidateKey } = queryConfigs.updatePasswordForUser;
  const { mutate } = useMutationQuery({
    key: invalidateKey,
    func: mutationFn,
    onSuccess: () => {
      showNotification("success", "User updated successfully");
      handleClose();
    },
  });

  const [formValues, setFormValues] = useState<FormValues>({
    password: "",
    repassword: "",
  });

  const handleClose = () => {
    setFormValues({
      password: "",
      repassword: "",
    });
    onClose();
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const validateForm = () => {
    const { password, repassword } = formValues;
    const errors = [];

    if (!password || password.trim() === "") {
      errors.push({ field: "password", message: "Password is required" });
    } else if (password.trim().length < 8) {
      errors.push({
        field: "password",
        message: "Password length should be at least 8 characters",
      });
    }
    if (
      !repassword ||
      repassword.trim() === "" ||
      repassword.trim().length < 8
    ) {
      errors.push({
        field: "repassword",
        message: "Confirm Password length should be at least 8 characters",
      });
    } else if (password?.trim() !== repassword.trim()) {
      errors.push({ field: "repassword", message: "Passwords don't match" });
    }

    return errors.length > 0 ? errors.map((e) => e.message).join(", ") : null;
  };

  const handleSubmit = () => {
    const validationError = validateForm();
    if (validationError) {
      setErrorMessage(validationError);
      showNotification("error", validationError);
      return;
    }
    mutate({
      body: {
        password: formValues.password,
        repassword: formValues.repassword,
      },
      id: userData.id,
    });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <div className="bg-[#1C1C1C] sticky top-0 z-10 flex justify-between items-center px-4">
        <DialogTitle sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}>
          Update Password
        </DialogTitle>
        <IconButton onClick={handleClose} sx={{ color: "white" }}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent>
        <TextField
          required
          name="password"
          label="Password"
          type="password"
          value={formValues.password}
          onChange={handleInputChange}
          fullWidth
          margin="dense"
          variant="outlined"
          InputLabelProps={{
            style: {
              color: "gray",
              fontFamily: "Poppins, sans-serif",
              fontSize: "13px",
            },
          }}
          InputProps={{
            classes: {
              root: "text-black",
              input: "h-10 py-0",
            },
          }}
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-root": {
              borderRadius: "1.5rem",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "inherit",
            },
            "& .MuiInputBase-input": {
              height: "1rem",
              padding: "1rem 1rem",
            },
          }}
          className="rounded-3xl bg-white"
        />
        <TextField
          required
          name="repassword"
          label="Confirm Password"
          type="password"
          value={formValues.repassword}
          onChange={handleInputChange}
          fullWidth
          margin="dense"
          variant="outlined"
          InputLabelProps={{
            style: {
              color: "gray",
              fontFamily: "Poppins, sans-serif",
              fontSize: "13px",
            },
          }}
          InputProps={{
            classes: {
              root: "text-black",
              input: "h-10 py-0",
            },
          }}
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-root": {
              borderRadius: "1.5rem",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "inherit",
            },
            "& .MuiInputBase-input": {
              height: "1rem",
              padding: "1rem 1rem",
            },
          }}
          className="rounded-3xl bg-white"
        />
      </DialogContent>
      <div className="flex justify-center mb-4 items-center">
        <DialogActions>
          <div className="mr-12">
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              sx={{ borderRadius: "1.5rem" }}
            >
              Update
            </Button>
          </div>
          <div className="ml-12">
            <Button
              onClick={onClose}
              variant="outlined"
              color="secondary"
              sx={{ borderRadius: "1.5rem" }}
            >
              Cancel
            </Button>
          </div>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default UpdatePassword;
