import React, { useState } from "react";
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { queryConfigs } from "../../hooks/config";
import { useMutationQuery } from "../../hooks/queryHook";
import { showNotification } from "../../services/helper";

type AddUserProps = {
  open: boolean;
  onClose: () => void;
};

const AddUser = ({ open, onClose }: AddUserProps) => {
  const { queryFn, queryKey } = queryConfigs.useAddUser;
  const { mutate } = useMutationQuery({
    key: queryKey,
    func: queryFn,
    onSuccess: () => {
      showNotification("success", "User added successfully");
      onClose();
    },
  });

  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    mobile: "",
    password: "",
    repassword: "",
    image: "",
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setFormValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      setFormValues((prevState) => ({ ...prevState, avatar: file }));
    }
  };
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const validateForm = () => {
    const { name, mobile, email, password, repassword } = formValues;
    const errors = [];

    if (!name || name.trim() === "") {
      errors.push({ field: "name", message: "Name is required" });
    }
    if (!mobile || mobile.trim() === "" || mobile === "0") {
      errors.push({ field: "mobile", message: "Mobile is required" });
    }
    if (!email || email.trim() === "") {
      errors.push({ field: "email", message: "Email is required" });
    }
    if (!password || password.trim() === "") {
      errors.push({ field: "password", message: "Invalid Password" });
    } else if (password.trim().length < 8) {
      errors.push({
        field: "password",
        message: "Password length should be at least 8 characters",
      });
    }
    if (
      !repassword ||
      repassword.trim() === "" ||
      repassword.trim().length < 8
    ) {
      errors.push({
        field: "repassword",
        message: "Confirm Password length should be at least 8 characters",
      });
    } else if (repassword.trim() !== password.trim()) {
      errors.push({ field: "repassword", message: "Passwords don't match" });
    }

    if (errors.length === 5) {
      return "All fields are missing";
    }

    return errors.length > 0 ? errors.join(", ") : null;
  };
  const handleSubmit = () => {
    const validationError = validateForm();
    if (validationError) {
      setErrorMessage(validationError);
      showNotification("error", validationError);

      return;
    }
    mutate(formValues);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <div className="bg-[#1C1C1C] sticky top-0 z-10 flex justify-between items-center px-4">
        <DialogTitle sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}>
          Add New User
        </DialogTitle>
        <IconButton onClick={onClose} sx={{ color: "white" }}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent>
        <TextField
          name="name"
          label="Name"
          value={formValues.name}
          onChange={handleInputChange}
          fullWidth
          required
          margin="dense"
          variant="outlined"
          InputLabelProps={{
            style: {
              color: "gray",
              fontFamily: "Poppins, sans-serif",
              fontSize: "13px",
            },
          }}
          InputProps={{
            classes: {
              root: "text-black",
              input: "h-8 py-0",
            },
          }}
          inputProps={{
            className: "text-black",
          }}
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-root": {
              borderRadius: "1.5rem",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "inherit",
            },
            "& .MuiInputBase-input": {
              height: "1rem",
              padding: "1rem 1rem",
            },
          }}
          className="rounded-3xl bg-white"
        />
        <TextField
          name="mobile"
          label="Mobile"
          required
          value={formValues.mobile}
          onChange={handleInputChange}
          fullWidth
          margin="dense"
          variant="outlined"
          InputLabelProps={{
            style: {
              color: "gray",
              fontFamily: "Poppins, sans-serif",
              fontSize: "13px",
            },
          }}
          InputProps={{
            inputProps: {
              maxLength: 10,
              pattern: "[0-9]*",
            },
            classes: {
              root: "text-black",
              input: "h-10 py-0",
            },
          }}
          inputProps={{
            className: "text-black font-Poppins",
          }}
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-root": {
              borderRadius: "1.5rem",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "inherit",
            },
            "& .MuiInputBase-input": {
              height: "1rem",
              padding: "1rem 1rem",
            },
          }}
          className="rounded-3xl bg-white"
          onInput={(e) => {
            const input = e.target as HTMLInputElement;
            input.value = input.value.replace(/[^0-9]/g, "");
          }}
        />
        <TextField
          name="email"
          required
          label="Email"
          value={formValues.email}
          onChange={handleInputChange}
          fullWidth
          margin="dense"
          variant="outlined"
          InputLabelProps={{
            style: {
              color: "gray",
              fontFamily: "Poppins, sans-serif",
              fontSize: "13px",
            },
          }}
          InputProps={{
            classes: {
              root: "text-black",
              input: "h-10 py-0",
            },
          }}
          inputProps={{
            className: "text-black",
          }}
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-root": {
              borderRadius: "1.5rem",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "inherit",
            },
            "& .MuiInputBase-input": {
              height: "1rem",
              padding: "1rem 1rem",
            },
          }}
          className="rounded-3xl bg-white"
        />
        <TextField
          name="password"
          label="Password"
          value={formValues.password}
          onChange={handleInputChange}
          fullWidth
          required
          margin="dense"
          variant="outlined"
          InputLabelProps={{
            style: {
              color: "gray",
              fontFamily: "Poppins, sans-serif",
              fontSize: "13px",
            },
          }}
          InputProps={{
            inputProps: { minLength: 8 },
            classes: {
              root: "text-black",
              input: "h-10 py-0",
            },
          }}
          inputProps={{
            className: "text-black",
          }}
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-root": {
              borderRadius: "1.5rem",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "inherit",
            },
            "& .MuiInputBase-input": {
              height: "1rem",
              padding: "1rem 1rem",
            },
          }}
          className="rounded-3xl bg-white"
        />
        <TextField
          name="repassword"
          label="Confirm Password"
          value={formValues.repassword}
          onChange={handleInputChange}
          fullWidth
          required
          margin="dense"
          variant="outlined"
          InputLabelProps={{
            style: {
              color: "gray",
              fontFamily: "Poppins, sans-serif",
              fontSize: "13px",
            },
          }}
          InputProps={{
            inputProps: { minLength: 8 },
            classes: {
              root: "text-black",
              input: "h-10 py-0",
            },
          }}
          inputProps={{
            className: "text-black",
          }}
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-root": {
              borderRadius: "1.5rem",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "inherit",
            },
            "& .MuiInputBase-input": {
              height: "1rem",
              padding: "1rem 1rem",
            },
          }}
          className="rounded-3xl bg-white"
        />
      </DialogContent>
      <div className="flex justify-center mb-4 items-center">
        <DialogActions>
          <div className="mr-12">
            <button
              onClick={handleSubmit}
              className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group"
            >
              <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#4CAF50] opacity-[3%]"></span>
              <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#4CAF50] opacity-100 group-hover:-translate-x-8"></span>
              <span className="relative w-full text-left text-[#4CAF50] transition-colors duration-200 ease-in-out group-hover:text-white">
                Add
              </span>
              <span className="absolute inset-0 border-2 border-[#4CAF50] rounded-lg"></span>
            </button>
          </div>
          <div className="ml-12">
            <button
              onClick={onClose}
              className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group"
            >
              <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#d00000] opacity-[3%]"></span>
              <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#d00000] opacity-100 group-hover:-translate-x-8"></span>
              <span className="relative w-full text-left text-[#d00000] transition-colors duration-200 ease-in-out group-hover:text-white">
                Cancel
              </span>
              <span className="absolute inset-0 border-2 border-[#d00000] rounded-lg"></span>
            </button>
          </div>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default AddUser;
