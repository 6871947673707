
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { useState } from "react";

type TGenericPdfDownloaderProps = {
	rootElementId1: string;
	rootElementId2?: string;
	downloadFileName: string;
};

const GenericPdfDownloader = ({
	rootElementId1,
	rootElementId2,
	downloadFileName,
}: TGenericPdfDownloaderProps) => {
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);

	const downloadPdfDocument = async () => {
		setIsLoading(true);
		setError(null);

		try {
			const pdf = new jsPDF({
				orientation: "portrait",
			});

			const captureAndAddPage = async (elementId: string, isFirstPage: boolean) => {
				const input = document.getElementById(elementId);
				if (!input) throw new Error(`Element with ID ${elementId} not found`);

				// Capture the element excluding certain elements
				const canvas = await html2canvas(input as HTMLElement, {
					useCORS: true,
					allowTaint: true,
					ignoreElements: (element) => element.classList.contains('exclude-from-capture'),
				});
				const imgData = canvas.toDataURL("image/png");

				if (!isFirstPage) {
					pdf.addPage();
				}

				const pdfWidth = pdf.internal.pageSize.getWidth();
				const pdfHeight = pdf.internal.pageSize.getHeight();
				const imgWidth = canvas.width;
				const imgHeight = canvas.height;

				// Calculate the aspect ratio
				const aspectRatio = imgWidth / imgHeight;

				// Adjust dimensions to maintain aspect ratio
				let renderWidth = pdfWidth;
				let renderHeight = pdfWidth / aspectRatio;

				if (renderHeight > pdfHeight) {
					renderHeight = pdfHeight;
					renderWidth = pdfHeight * aspectRatio;
				}

				pdf.addImage(imgData, "JPEG", 0, 0, renderWidth, renderHeight);
			};

			// Capture and add the first page
			await captureAndAddPage(rootElementId1, true);

			// Capture and add the second page if rootElementId2 is provided
			if (rootElementId2) {
				await captureAndAddPage(rootElementId2, false);
			}

			// Save the PDF
			pdf.save(`${downloadFileName}.pdf`);
		} catch (err) {
			setError(err instanceof Error ? err.message : "An unknown error occurred");
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div>
			<button
				className="generate-btn text-xs font-Poppins"
				onClick={downloadPdfDocument}
				disabled={isLoading}
			>
				{isLoading ? "Generating..." : "Download Pdf"}
			</button>
			{error && <p className="text-red-500 mt-2">{error}</p>}
		</div>
	);
};

export default GenericPdfDownloader;
