import React, { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  IconButton,
  Popover,
  TableCell,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import CommonTable from "../CommonTable";
import { useGetQuery, useMutationQuery } from "../../hooks/queryHook";
import { queryConfigs } from "../../hooks/config";
import dayjs from "dayjs";
import AddBilling from "../billing/AddBilling";
import { FaEdit } from "react-icons/fa";
import { MdDeleteForever, MdReceipt } from "react-icons/md";
import {
  getEndingTimeOfDate,
  getStartingTimeOfDate,
  showNotification,
} from "../../services/helper";
import { PiEyeFill } from "react-icons/pi";
import DeleteModal from "../DeleteModal";
import { useNavigate } from "react-router-dom";
import DischargeModal from "../discharge/DischargeModal";
import { Admission } from "../../types/common";
import { FaBook } from "react-icons/fa6";
import { IoStatsChart } from "react-icons/io5";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaHandHoldingMedical } from "react-icons/fa";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

type BillingProps = {
  pageTitle: string;
};

type Header = {
  name: string;
};

export type BillingData = {
  id: number;
  patient_name: string;
  patient?: number;
  due: number;
  paid_amount: number;
  payable_amount: number;
  created_on: string;
  status: string;
  admission_fee: number;
  admission_date: string;
};

const userHeaders: Header[] = [
  { name: "Admission ID" },
  { name: "Patient Name" },
  { name: "Admission Fees" },
  { name: "Admitted On" },
  { name: "Action" },
];

const InPatient: React.FC<BillingProps> = ({ pageTitle }) => {
  const navigate = useNavigate();
  const userHeaderNames: string[] = userHeaders.map((header) => header.name);

  const [chartStartDate, setChartStartDate] = useState<string | null>(null);
  const [chartEndDate, setChartEndDate] = useState<string | null>(null);

  const [isAddBillingOpen, setIsAddBillingOpen] = useState(false);

  const [editValues, setEditValues] = useState({});
  const [viewOpen, setViewOpen] = useState(false);

  const [isAddAdmissionOpen, setIsAddAdmissionOpen] = useState(false);
  const [selectedPT, setSelectedPT] = useState<Admission | null>(null);

  const [open, setOpen] = useState(false);
  const [toDelete, setToDelete] = useState<number | null>(null);

  const [patientSearch, setPatientSearch] = useState<{
    id: number | null;
    name: string | null;
  }>({
    id: null,
    name: null,
  });
  const [searchParams, setSearchParams] = useState<{
    id: number | null;
    name: string | null;
  }>({
    id: null,
    name: null,
  });

  const isEnabled =
    (chartStartDate === null && chartEndDate === null) ||
    (chartStartDate !== null && chartEndDate !== null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const totalRows = 100;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const { queryKey, queryFn } = queryConfigs.getAdmissions;
  const { data, refetch, isLoading } = useGetQuery({
    key: queryKey,
    func: queryFn,
    params: {
      offset: page,
      limit: rowsPerPage,
      name: searchParams.name ?? "",
      id: searchParams.id ?? null,
      startDate: chartStartDate,
      endDate: chartEndDate,
    },
    isEnabled: isEnabled,
  });

  const { invalidateKey, mutationFn } = queryConfigs.deleteAdmission;
  const { mutate: deleteAdmission, isPending } = useMutationQuery({
    key: invalidateKey,
    func: mutationFn,
    onSuccess: () => {
      showNotification("success", "Patient deleted successfully");
      handleDeleteClose();
    },
    onError: (error) => {
      showNotification("error", error.message);
    },
  });

  const handleVitalNavigate = (id: number) => {
    navigate(`/admin/patient/${id}/vitals`);
  };
  const handleUploadDocumentNavigate = (id: number) => {
    navigate(`/admin/patient/${id}/documents`);
  };
  const handleTreatmentNavigate = (id: number) => {
    navigate(`/admin/patient/${id}/treatments`);
  };
  const [billID, setBillID] = useState<Admission | null>(null);
  const handleBillClick = (admission: Admission) => {
    if (admission.patient > 0) {
      navigate(
        `/admin/in-patient/${admission.patient}/billings/${admission.id}`
      );
    }
  };

  const handleAddClick = () => {
    setIsAddBillingOpen(true);
  };
  const handleEditClick = (admissions: any) => {
    setViewOpen(true);
    if (admissions.id > 0) {
      setEditValues({ ...admissions });
    }
  };
  const handleDischargeClick = (admissions: Admission) => {
    if (admissions.id > 0) {
      setBillID(admissions);
      setIsAddAdmissionOpen(true);
    }
  };

  const handleChartDateRange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    const formattedStart = start ? getStartingTimeOfDate(dayjs(start)) : null;
    const formattedEnd = end ? getEndingTimeOfDate(dayjs(end)) : null;
    setChartStartDate(formattedStart);
    setChartEndDate(formattedEnd);
  };
  const handleClear = () => {
    setPatientSearch({
      id: null,
      name: null,
    });
    setSearchParams({
      id: null,
      name: null,
    });
    setChartStartDate(null);
    setChartEndDate(null);
  };
  const handleRefetch = () => {
    handleClear();
    refetch();
  };

  const handleSelectDelete = (id: number) => {
    setToDelete(id);
    setOpen(true);
  };
  const handleDeleteClose = () => {
    setToDelete(null);
    setOpen(false);
  };
  const handleDelete = () => {
    if (toDelete !== null) {
      deleteAdmission(toDelete);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setPatientSearch((prevState) => ({
      ...prevState,
      [name]: name === "id" ? (value ? Number(value) : null) : value,
    }));
  };

  const handleSearch = () => {
    setSearchParams(patientSearch);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    pt: Admission
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedPT(pt);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedPT(null);
  };

  return (
    <div className="w-full overflow-hidden mx-auto relative h-screen">
      <CommonTable
        refetchIcon={true}
        onRefetchClick={handleRefetch}
        tableHeaders={userHeaderNames}
        totalRows={data?.result?.count || 0}
        colSpan={userHeaders.length}
        pageTitle={pageTitle}
        marginTop={16}
        showMobileFilter={false}
        children2={
          <>
            <div className=" flex items-center space-x-5 w-full  z-50">
              <DatePicker
                selected={
                  chartStartDate ? dayjs(chartStartDate).toDate() : null
                }
                onChange={handleChartDateRange}
                startDate={
                  chartStartDate ? dayjs(chartStartDate).toDate() : undefined
                }
                endDate={
                  chartEndDate ? dayjs(chartEndDate).toDate() : undefined
                }
                selectsRange
                dateFormat="yyyy-MM-dd"
                placeholderText="Select Date Range"
                className="w-[300px] border border-gray-400 rounded py-2 px-4"
              />
              <TextField
                label="Patient Name"
                variant="outlined"
                name="name"
                value={patientSearch.name || ""}
                onChange={handleChange}
                size="small"
                sx={{
                  width: "20%",
                  "& .MuiInputLabel-root": {
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "13px",
                  },
                }}
              />
              <TextField
                label="Patient ID"
                variant="outlined"
                name="id"
                value={
                  patientSearch.id !== null ? patientSearch.id.toString() : ""
                }
                onChange={handleChange}
                size="small"
                sx={{
                  width: "20%",
                  "& .MuiInputLabel-root": {
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "13px",
                  },
                }}
              />

              <button onClick={handleSearch} className="search-btn">
                Search
              </button>
              <button onClick={handleClear} className="clear-btn">
                Clear
              </button>
            </div>
          </>
        }
        children3={
          <TablePagination
            component="div"
            count={data?.result?.count || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{ color: "#38a3a5", font: "Poppins, sans-serif" }}
          />
        }
      >
        {data?.result?.list?.map((admission: Admission) => (
          <TableRow key={admission.id}>
            <TableCell align="center" className="capitalize">
              {admission.id}
            </TableCell>
            <TableCell align="center" className="capitalize">
              {admission.patient_name}
            </TableCell>
            <TableCell align="center" className="capitalize">
              {admission.admission_fee}
            </TableCell>

            <TableCell align="center" className="capitalize">
              {dayjs(admission.admission_date).format("DD-MM-YYYY")}
            </TableCell>
            <TableCell align="center" className="capitalize space-x-2">
              <IconButton
                onClick={(event) => handleMenuClick(event, admission)}
              >
                <MoreVertIcon />
              </IconButton>
              <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleMenuClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                sx={{ marginLeft: "-100px", marginTop: "6px", zIndex: 999 }}
                slotProps={{
                  paper: {
                    style: {
                      boxShadow: "none",
                    },
                  },
                }}
              >
                <div className="flex space-x-2 p-2">
                  <Tooltip title="Vital Records" arrow>
                    <button
                      onClick={() => {
                        if (selectedPT) handleVitalNavigate(selectedPT.patient);
                        handleMenuClose();
                      }}
                      className="action-btn"
                    >
                      <FaBook />
                    </button>
                  </Tooltip>
                  <Tooltip title="Treatment Charts" arrow>
                    <button
                      onClick={() => {
                        if (selectedPT)
                          handleTreatmentNavigate(selectedPT.patient);
                        handleMenuClose();
                      }}
                      className="action-btn"
                    >
                      <IoStatsChart />
                    </button>
                  </Tooltip>
                  <Tooltip title="Discharge" arrow>
                    <button
                      onClick={() => {
                        if (selectedPT) handleDischargeClick(selectedPT);
                        handleMenuClose();
                      }}
                      className="generate-btn"
                    >
                      <FaHandHoldingMedical />
                    </button>
                  </Tooltip>

                  <Tooltip title="Billing" arrow>
                    <button
                      onClick={() => {
                        if (selectedPT) handleBillClick(selectedPT);
                        handleMenuClose();
                      }}
                      className="view-btn"
                    >
                      <MdReceipt />
                    </button>
                  </Tooltip>
                </div>
              </Popover>
            </TableCell>
          </TableRow>
        ))}
      </CommonTable>

      <DischargeModal
        admission={billID}
        open={isAddAdmissionOpen}
        onClose={() => setIsAddAdmissionOpen(false)}
      />
      <DeleteModal
        open={open}
        onClose={handleDeleteClose}
        title="Delete Patient ?"
        contentText="Are you sure you want to delete this patient? This action cannot be undone."
        onCancel={handleDeleteClose}
        onConfirm={handleDelete}
        isDeleting={isPending}
      />
    </div>
  );
};

export default InPatient;
