import React, { useState } from "react";
import CommonTable from "../CommonTable";
import {
  Autocomplete,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { useGetQuery, useMutationQuery } from "../../hooks/queryHook";
import { queryConfigs } from "../../hooks/config";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteModal from "../DeleteModal";
import {
  getEndingTimeOfDate,
  getStartingTimeOfDate,
  showNotification,
} from "../../services/helper";
import dayjs from "dayjs";
import AddVital from "./AddVital";
import { useNavigate, useParams } from "react-router-dom";
import EditVital from "./EditVital";
import { Emp } from "../../types/common";
import VItalDownload from "./VItalDownload";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MdDeleteForever } from "react-icons/md";
import { FaEdit } from "react-icons/fa";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

type UsersProps = {
  pageTitle: string;
};

type Header = {
  name: string;
};
type DesignationMenu = {
  id: number;
  name: string;
  organisation: number;
  created_on: string;
};
type VitalRow = {
  id: number;
  blood_high: string;
  blood_low: string;
  pulse_rate: string;
  bio_func: string;
  spo: string;
  weight: number;
  in_charge: string;
  created_on: string;
  administered_on: string;
};

const userHeaders: Header[] = [
  { name: "ID" },
  { name: "Blood Pressure" },
  { name: "Pulse Rate" },
  { name: "Biological Function" },
  { name: "SPO2" },
  { name: "Weight" },
  { name: "In Charge" },
  { name: "Recorded On" },
  { name: "Action" },
];

const Vitals: React.FC<UsersProps> = ({ pageTitle }) => {
  const params = useParams();
  const navigate = useNavigate();
  const pTId = parseInt(params.id || "0");
  const [selectedEmp, setSelectedEmp] = useState<Emp | null>(null);
  const [selectedEmployee, setSelectedEmployee] = useState<number | null>(null);
  const [chartStartDate, setChartStartDate] = useState<string | null>(null);
  const [chartEndDate, setChartEndDate] = useState<string | null>(null);

  const { queryKey, queryFn } = queryConfigs.getVitals;
  const userHeaderNames: string[] = userHeaders.map((header) => header.name);
  const isEnabled: boolean | undefined = params.id
    ? (chartStartDate === null && chartEndDate === null) ||
      (chartStartDate !== null && chartEndDate !== null)
    : undefined;

  const { data, refetch, isLoading } = useGetQuery({
    key: queryKey,
    func: queryFn,
    params: {
      offset: 0,
      limit: 10,
      patient: pTId,
      inCharge: selectedEmployee,
      startDate: chartStartDate,
      endDate: chartEndDate,
    },
    isEnabled: isEnabled,
  });

  // useEffect(() => {
  // 	if (!data.success) {
  // 		if (data?.message === "Patient doesnt not exist" || !data?.success || !params.id) {
  // 			navigate("/admin/patients")
  // 		}
  // 	}
  // }, [params.id])

  const [openAdd, setOpenAdd] = useState(false);
  const [toDelete, setToDelete] = useState<number | null>(null);
  const [open, setOpen] = useState(false);
  const [editValue, setEditValue] = useState({});
  const [editOpen, setEditOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setToDelete(null);
  };
  const handleEditClose = () => {
    setEditOpen(false);
    setEditValue({
      id: 0,
      name: "",
      description: "",
    });
  };

  const handleAddClick = () => {
    setOpenAdd(true);
  };

  const handleDeleteClose = () => {
    setToDelete(null);
    setOpen(false);
  };
  const { invalidateKey, mutationFn } = queryConfigs.deleteVital;
  const { mutate: deleteVitals } = useMutationQuery({
    key: invalidateKey,
    func: mutationFn,
    onSuccess: () => {
      showNotification("success", "Vital Records deleted successfully");
      handleDeleteClose();
    },
    onError: (error) => {
      showNotification("error", error.message);
      setIsDeleting(false);
    },
  });
  const handleSelectDelete = (id: number) => {
    setToDelete(id);
    setOpen(true);
  };

  const handleDelete = () => {
    if (toDelete !== null) {
      setIsDeleting(true);
      deleteVitals(toDelete);
    }
  };

  const { queryKey: employees, queryFn: employeesFunc } =
    queryConfigs.useGetEmployees;
  const { data: employeeData, isLoading: isEmployeeLoaiding } = useGetQuery({
    key: employees,
    func: employeesFunc,
  });

  const handleVitalEdit = (id: number) => {
    // navigate(`/patient/${id}/vitals`);
  };
  const [editValues, setEditValues] = useState({});
  const [viewOpen, setViewOpen] = useState(false);
  const handleEditClick = (vital: VitalRow) => {
    setViewOpen(true);
    if (vital.id > 0) {
      setEditValues({ ...vital });
    }
  };
  const [vitalOpen, setVitalOpen] = useState(false);
  const handleOpenVital = () => setVitalOpen(true);
  const handleCloseVital = () => setVitalOpen(false);
  const handleChartDateRange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;

    const formattedStart = start ? getStartingTimeOfDate(dayjs(start)) : null;
    const formattedEnd = end ? getEndingTimeOfDate(dayjs(end)) : null;

    setChartStartDate(formattedStart);
    setChartEndDate(formattedEnd);
  };
  const handleClear = () => {
    setSelectedEmployee(null);
    setChartStartDate(null);
    setChartEndDate(null);
    setSelectedEmp(null);
  };

  const handleSelectedEmpChange = (
    event: React.SyntheticEvent,
    value: Emp | null
  ) => {
    if (value) {
      setSelectedEmployee(value.id);
      setSelectedEmp(value);
    } else {
      setSelectedEmployee(null);
      setSelectedEmp(null);
    }
  };
  const handleRefetch = () => {
    refetch();
  };
  return (
    <>
      <div className="w-full overflow-hidden mx-auto relative h-screen">
        <CommonTable
          refetchIcon={true}
          onRefetchClick={handleRefetch}
          tableHeaders={userHeaderNames}
          children2={
            <>
              <div className=" flex items-center space-x-5 w-full">
                <DatePicker
                  selected={
                    chartStartDate ? dayjs(chartStartDate).toDate() : null
                  }
                  onChange={handleChartDateRange}
                  startDate={
                    chartStartDate ? dayjs(chartStartDate).toDate() : undefined
                  }
                  endDate={
                    chartEndDate ? dayjs(chartEndDate).toDate() : undefined
                  }
                  selectsRange
                  dateFormat="yyyy-MM-dd"
                  placeholderText="Select Date Range"
                  className="w-[300px] border rounded  py-2 px-4"
                />
                <Autocomplete
                  size="small"
                  options={employeeData?.list}
                  getOptionLabel={(option: Emp) => option.name}
                  onChange={handleSelectedEmpChange}
                  value={selectedEmp}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Incharge"
                      variant="outlined"
                      InputLabelProps={{
                        sx: {
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "13px",
                        },
                      }}
                    />
                  )}
                  sx={{
                    width: "20%",
                  }}
                />
                <button onClick={handleClear} className="clear-btn">
                  Clear
                </button>
              </div>
            </>
          }
          totalRows={data?.count || 0}
          colSpan={userHeaders.length}
          pageTitle={pageTitle}
          onAddClick={handleAddClick}
          onClickDownload={handleOpenVital}
          downloadTitle="Download Chart"
          marginTop={16}
          showNameFilter={false}
          showDesignationFilter={false}
          showMobileFilter={false}
          showIdFilter={false}
        >
          {data?.list?.map((vital: VitalRow) => (
            <TableRow key={vital.id}>
              <TableCell align="center" className="capitalize">
                {vital.id}
              </TableCell>
              <TableCell align="center" className="capitalize">
                {vital.blood_high}/{vital.blood_low}
              </TableCell>
              <TableCell align="center" className="capitalize">
                {vital.pulse_rate}
              </TableCell>
              <TableCell align="center" className="capitalize">
                {vital.bio_func}
              </TableCell>
              <TableCell align="center" className="capitalize">
                {vital.spo}
              </TableCell>
              <TableCell align="center" className="capitalize">
                {vital.weight}
              </TableCell>
              <TableCell align="center" className="capitalize">
                {employeeData?.list.find(
                  (d: Emp) => d.id === parseInt(vital.in_charge)
                )?.name ?? ""}
              </TableCell>

              <TableCell align="center" className="capitalize">
                {dayjs(vital.administered_on).format("DD-MM-YYYY")}
              </TableCell>
              <TableCell align="center" className="space-x-3">
                <Tooltip title="Edit" arrow>
                  <button
                    onClick={() => handleEditClick(vital)}
                    className="edit-btn"
                  >
                    <FaEdit />
                  </button>
                </Tooltip>
                <Tooltip title="Delete" arrow>
                  <button
                    onClick={() => handleSelectDelete(vital.id)}
                    className="del-btn"
                  >
                    <MdDeleteForever />
                  </button>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </CommonTable>
      </div>
      <AddVital onClose={() => setOpenAdd(false)} open={openAdd} />
      <DeleteModal
        open={open}
        onClose={handleDeleteClose}
        title="Delete Employee"
        contentText="Are you sure you want to delete this employee? This action cannot be undone."
        onCancel={handleDeleteClose}
        onConfirm={handleDelete}
        isDeleting={isDeleting}
      />
      <EditVital
        onClose={() => setViewOpen(false)}
        open={viewOpen}
        vital={editValues}
      />
      <VItalDownload pTId={pTId} open={vitalOpen} onClose={handleCloseVital} />
    </>
  );
};

export default Vitals;
