import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { RiAddFill } from "react-icons/ri";
import "react-datepicker/dist/react-datepicker.css";
import TablePagination from "@mui/material/TablePagination";
import { Emp } from "../types/common";
import dayjs from "dayjs";
import { FaArrowRotateRight } from "react-icons/fa6";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

export const tableHeaderStyle = {
  fontFamily: "Poppins, sans-serif",
  fontWeight: 500,
  color: "#353535",
  fontSize: "12px",
};

type TCommonTableProps = {
  tableHeaders: string[];
  children: React.ReactNode;
  children2?: React.ReactNode;
  children3?: React.ReactNode;
  totalRows: number;
  colSpan: number;
  noDataText?: string;
  pageTitle: string;
  onAddClick?: () => void;
  onRefetchClick?: () => void;
  handleRefetchClick?: () => void;
  onClickDownload?: () => void;
  downloadTitle?: string;
  marginTop?: number;
  nameOptions?: string[];
  idOptions?: string[];
  mobileOptions?: string[];
  inchargeOptions?: Emp[];
  designationOptions?: string[];
  showNameFilter?: boolean;
  showIdFilter?: boolean;
  showInchargeFilter?: boolean;
  showDesignationFilter?: boolean;
  showMobileFilter?: boolean;
  showDateRangePicker?: boolean;
  showSearchButton?: boolean;
  refetchIcon?: boolean;
  buttonTitle?: string;
};

export default function CommonTable({
  onRefetchClick = () => {},
  tableHeaders,
  children,
  children2,
  children3,
  totalRows,
  colSpan = 1,
  noDataText = "No Data Found",
  pageTitle,
  onAddClick,
  onClickDownload,
  downloadTitle,
  refetchIcon = false,
  marginTop = 6,
  buttonTitle,
}: TCommonTableProps) {
  const [dateRange, setDateRange] = useState<
    [Date | undefined, Date | undefined]
  >([undefined, undefined]);

  const shortenLastWord = (title: string): string => {
    const words = title.split(" ");
    if (words.length > 0) {
      const lastWord = words[words.length - 1];
      const shortenedLastWord = lastWord.slice(0, -1);
      words[words.length - 1] = shortenedLastWord;
    }
    return words.join(" ");
  };

  const shortenedTitle = shortenLastWord(pageTitle);

  const [selectedEmpId, setSelectedEmpId] = useState<number | null>(null);

  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [isSpinning, setIsSpinning] = useState(false);

  const handleRefetch = () => {
    setIsSpinning(true);
    onRefetchClick();
    setTimeout(() => setIsSpinning(false), 300); // Reset spinning state after animation
  };
  return (
    <div className="w-full relative mx-auto mt-[86px]">
      <div className="flex flex-col justify-between bg-white">
        <div className="flex justify-between items-center space-x-5 p-6">
            <div className="flex space-x-2">
                <h1 className="text-2xl font-Poppins text-gray-700 font-semibold mb-4 mt-4">
                    {pageTitle}
                </h1>
                {refetchIcon && (
                    <button
                    className={`flex items-center justify-center p-2 transition-all duration-300 ${
                        isSpinning ? "spin" : ""
                    }`}
                    onClick={handleRefetch}
                    >
                    <FaArrowRotateRight
                        className={`text-sm font-bold ${
                        isSpinning ? "animate-spin" : ""
                        }`}
                    />
                    </button>
                )}
          </div>
          <div className="space-x-4">
          {onAddClick && (
            <button
              className="mt-3 mb-3 text-white rounded-lg drop-shadow-lg px-3 py-2 bg-[#353535] border border-[#353535] hover:text-[#353535] hover:bg-white"
              onClick={onAddClick}
            >
              <RiAddFill className="inline-block" />{" "}
              {buttonTitle ? buttonTitle : "Add " + shortenedTitle}
            </button>
          )}
          {onClickDownload && (
            <button
              className="mt-3 mb-3 text-white rounded-lg drop-shadow-lg px-3 py-2 bg-[#4CAF50] border border-[#4CAF50] hover:text-[#4CAF50] hover:bg-white"
              onClick={onClickDownload}
            >
              {downloadTitle}
            </button>
          )}
          </div>
        </div>
      
      <div className="flex flex-wrap gap-4 px-6 bg-white">{children2}</div>
      <div>{children3}</div>
      <TableContainer
        component={Paper}
        sx={{
          minHeight:420,
          maxHeight: 380,
          overflow: "auto",
          marginTop: 2,
        }}
      >
        <Table
          stickyHeader
          aria-label="sticky table"
          sx={{ width: "100%", overflow: "auto", }}
          size="medium"
        >
          <TableHead>
            <TableRow sx={{ borderBottom: "none" }}>
              {tableHeaders?.map((head) => (
                <TableCell
                  sx={tableHeaderStyle}
                  align="center"
                  key={head}
                  className="uppercase font-semibold"
                >
                  {head}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              overflowY: "auto",
            }}
          >
            {children}
            {totalRows === 0 && (
              <TableRow>
                <TableCell align="center" colSpan={colSpan}>
                  <p className="text-xl font-Poppins py-5 text-black">
                    {noDataText}
                  </p>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      </div>
    </div>
  );
}
