import React from "react";
import { Chip } from "@mui/material";

interface StatusChipProps {
  status: string;
}

const PaymentStatusChips: React.FC<StatusChipProps> = ({ status }) => {
  const getChipStyles = (status: string) => {
    switch (status.toLowerCase()) {
      case "critical":
        return {
          borderColor: "#B71C1C",
          color: "#B71C1C",
          backgroundColor: "#FFEBEE",
          "& .MuiChip-label": {
            color: "#B71C1C",
          },
        };
      case "warning":
        return {
          borderColor: "#FFA000",
          color: "#FFA000",
          backgroundColor: "#FFF3E0",
          "& .MuiChip-label": {
            color: "#FFA000",
          },
        };
      case "high_alert":
        return {
          borderColor: "#FF5722",
          color: "#FF5722",
          backgroundColor: "#FBE9E7",
          "& .MuiChip-label": {
            color: "#FF5722",
          },
        };
      case "due":
        return {
          borderColor: "#FFC107",
          color: "#FFC107",
          backgroundColor: "#FFF8E1",
          "& .MuiChip-label": {
            color: "#FFC107",
          },
        };
      case "fulfilled":
        return {
          borderColor: "#1B5E20", // Dark green
          color: "#1B5E20", // Dark green
          backgroundColor: "#E8F5E9", // Light green
          "& .MuiChip-label": {
            color: "#1B5E20", // Dark green
          },
        };
      default:
        return {
          borderColor: "#9E9E9E",
          color: "#9E9E9E",
          backgroundColor: "#F5F5F5",
          "& .MuiChip-label": {
            color: "#9E9E9E",
          },
        };
    }
  };

  const chipStyles = getChipStyles(status);

  return <Chip label={status} variant="outlined" sx={chipStyles} />;
};

export default PaymentStatusChips;
