import {
  TextField,
  DialogActions,
  DialogTitle,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Backdrop,
  Modal,
  Box,
  SelectChangeEvent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";
import { queryConfigs } from "../../hooks/config";
import { useGetQuery, useMutationQuery } from "../../hooks/queryHook";
import { useEffect, useState } from "react";
import { showNotification } from "../../services/helper";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

type DesignationMenu = {
  id: number;
  name: string;
  organisation: number;
  created_on: string;
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit",
  height: 520,
  bgcolor: "background.paper",
  backdropFilter: "blur(3px)",
  borderRadius: 2,
  overflowY: "auto",
};
var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

type EditEmpProps = {
  openEdit: boolean;
  editClose: () => void;
  empInfo: any;
};
type EmpRow = {
  id: number;
  name: string;
  created_on: string;
  email: string;
  mobile: string;
  address: string;
  designation: number;
};

const EditEmployee = ({ openEdit, editClose, empInfo }: EditEmpProps) => {
  const [editValue, setEditValue] = useState({
    id: "",
    name: "",
    address: "",
    mobile: "",
    email: "",
    image: "",
    designation: null,
  });
  const handleClearForm = () => {
    setEditValue({
      id: "",
      name: "",
      address: "",
      mobile: "",
      email: "",
      image: "",
      designation: null,
    });
  };

  useEffect(() => {
    if (empInfo.id) {
      setEditValue({ ...empInfo });
    }
  }, [empInfo.id, empInfo]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setEditValue((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setEditValue((prevState) => ({ ...prevState, [name]: value }));
  };
  const { queryKey: designations, queryFn: designationFunc } =
    queryConfigs.getDesignations;
  const { data: designationdata, isLoading: isDesignationLoading } =
    useGetQuery({
      key: designations,
      func: designationFunc,
      params: { offset: 0, limit: 100 },
    });

  const { mutationFn: UpdateEmp, invalidateKey: employee } =
    queryConfigs.UseUpdateEmployee;
  const { mutate } = useMutationQuery({
    key: employee,
    func: UpdateEmp,
    onSuccess: () => {
      showNotification("success", "Employee updated successfully");
      editClose();
    },
  });
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const validateForm = () => {
    const { name, mobile, email, designation } = editValue;
    const errors = [];

    if (!name || name.trim() === "") {
      errors.push("Name is required");
    }
    if (!mobile || mobile.trim() === "" || mobile === "0") {
      errors.push("Mobile is required");
    }
    if (!email || email.trim() === "") {
      errors.push("Email is required");
    }
    if (!designation) {
      errors.push("Designation is required");
    }

    if (errors.length === 4) {
      return "All fields are missing";
    }

    return errors.length > 0 ? errors.join(", ") : null;
  };

  const handleEditSubmit = () => {
    const validationError = validateForm();
    if (validationError) {
      setErrorMessage(validationError);
      showNotification("error", validationError);
      return;
    }
    mutate({ body: editValue, id: editValue.id });
    editClose();
    handleClearForm();
  };

  const handleCancel = () => {
    editClose();
    handleClearForm();
  };

  return (
    <Modal
      open={openEdit}
      onClose={editClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Box sx={style}>
        <div className="bg-[#1C1C1C] sticky top-0 z-10 flex justify-between items-center px-4">
          <DialogTitle
            sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
          >
            Edit Employee
          </DialogTitle>
          <IconButton onClick={editClose} sx={{ color: "white" }}>
            <CloseIcon />
          </IconButton>
        </div>
        <section className="space-y-5 p-5">
          <TextField
            name="name"
            label="Name"
            value={editValue.name}
            onChange={handleInputChange}
            fullWidth
            margin="dense"
            variant="outlined"
            InputLabelProps={{
              style: {
                color: "gray",
                fontFamily: "Poppins, sans-serif",
                fontSize: "13px",
              },
            }}
            InputProps={{
              classes: {
                root: "text-black",
                input: "h-8 py-0",
              },
            }}
            inputProps={{
              className: "text-black",
            }}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                borderRadius: "1.5rem",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "inherit",
              },
              "& .MuiInputBase-input": {
                height: "1rem",
                padding: "1rem 1rem",
                textTransform: "capitalize",
              },
            }}
          />
          <TextField
            name="address"
            label="Address"
            value={editValue.address}
            onChange={handleInputChange}
            fullWidth
            margin="dense"
            variant="outlined"
            InputLabelProps={{
              style: {
                color: "gray",
                fontFamily: "Poppins, sans-serif",
                fontSize: "13px",
              },
            }}
            InputProps={{
              classes: {
                root: "text-black",
                input: "h-8 py-0",
              },
            }}
            inputProps={{
              className: "text-black",
            }}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                borderRadius: "1.5rem",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "inherit",
              },
              "& .MuiInputBase-input": {
                height: "1rem",
                padding: "1rem 1rem",
                textTransform: "capitalize",
              },
            }}
          />
          <TextField
            name="mobile"
            label="Mobile"
            value={editValue.mobile}
            onChange={handleInputChange}
            fullWidth
            margin="dense"
            variant="outlined"
            InputLabelProps={{
              style: {
                color: "gray",
                fontFamily: "Poppins, sans-serif",
                fontSize: "13px",
              },
            }}
            InputProps={{
              inputProps: {
                maxLength: 10,
                pattern: "[0-9]*",
              },
              classes: {
                root: "text-black",
                input: "h-10 py-0",
              },
            }}
            inputProps={{
              className: "text-black font-Poppins",
            }}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                borderRadius: "1.5rem",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "inherit",
              },
              "& .MuiInputBase-input": {
                height: "1rem",
                padding: "1rem 1rem",
              },
            }}
          />
          <TextField
            name="email"
            label="Email"
            value={editValue.email}
            onChange={handleInputChange}
            fullWidth
            margin="dense"
            variant="outlined"
            InputLabelProps={{
              style: {
                color: "gray",
                fontFamily: "Poppins, sans-serif",
                fontSize: "13px",
              },
            }}
            InputProps={{
              classes: {
                root: "text-black",
                input: "h-10 py-0",
              },
            }}
            inputProps={{
              className: "text-black",
            }}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                borderRadius: "1.5rem",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "inherit",
              },
              "& .MuiInputBase-input": {
                height: "1rem",
                padding: "1rem 1rem",
              },
            }}
          />
          <FormControl fullWidth margin="dense" variant="outlined">
            <InputLabel
              style={{
                color: "gray",
                fontFamily: "Poppins, sans-serif",
                fontSize: "13px",
              }}
            >
              Designation
            </InputLabel>
            <Select
              name="designation"
              value={editValue.designation || ""}
              onChange={handleSelectChange}
              label="Designation"
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "1.5rem",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "inherit",
                  borderRadius: "1.5rem",
                },
                "& .MuiInputBase-input": {
                  height: "1rem",
                  padding: "1rem 1rem",
                  textTransform: "capitalize",
                },
                "& .MuiSelect-icon": {
                  color: "gray",
                },
              }}
            >
              {designationdata?.list?.map((designation: DesignationMenu) => (
                <MenuItem key={designation.id} value={designation.id}>
                  {designation.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </section>
        <div className="flex justify-center mb-4 items-center">
          <DialogActions>
            <div className="mr-12">
              <button
                onClick={handleEditSubmit}
                className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group"
              >
                <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#4CAF50] opacity-[3%]"></span>
                <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#4CAF50] opacity-100 group-hover:-translate-x-8"></span>
                <span className="relative w-full text-left text-[#4CAF50] transition-colors duration-200 ease-in-out group-hover:text-white">
                  Add
                </span>
                <span className="absolute inset-0 border-2 border-[#4CAF50] rounded-lg"></span>
              </button>
            </div>
            <div className="ml-12">
              <button
                onClick={handleCancel}
                className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group"
              >
                <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#d00000] opacity-[3%]"></span>
                <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#d00000] opacity-100 group-hover:-translate-x-8"></span>
                <span className="relative w-full text-left text-[#d00000] transition-colors duration-200 ease-in-out group-hover:text-white">
                  Cancel
                </span>
                <span className="absolute inset-0 border-2 border-[#d00000] rounded-lg"></span>
              </button>
            </div>
          </DialogActions>
        </div>
      </Box>
    </Modal>
  );
};

export default EditEmployee;
