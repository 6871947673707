import React from "react";
import { Modal, Fade, Box, Typography } from "@mui/material";

interface CommonDeleteModalProps {
    open: boolean;
    onClose: () => void;
    title?: string;
    contentText?: string;
    onCancel: () => void;
    onConfirm: () => void;
    isDeleting?: boolean;
}

const DeleteModal: React.FC<CommonDeleteModalProps> = ({
    open,
    onClose,
    title,
    contentText,
    onCancel,
    onConfirm,
    isDeleting,
}) => {
    return (
        <Modal open={open} onClose={onClose} closeAfterTransition>
            <Fade in={open}>
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 500,
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        borderRadius: "5px",
                    }}
                >
                    <Typography
                        variant="h6"
                        component="h2"
                        sx={{
                            textAlign: "center",
                            fontWeight: 800,
                            marginBottom: 2,
                            color: 'red',
                            backgroundColor: '#1C1C1C',
                            borderRadius: '5px 5px 0 0',
                            p: 2
                        }}
                    >
                        {title || "Confirm Delete"}
                    </Typography>
                    <Typography sx={{p:2, fontFamily: "Poppins, san-serif", textAlign: 'center', whiteSpace: 'pre-line'}}>
                        {contentText ||
                            "Are you sure you want to delete this? This action cannot be undone."}
                    </Typography>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-around",
                            mt: 3,
                            p: 2
                        }}
                    >
                        <button disabled={isDeleting} onClick={onConfirm} className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group">
                            <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-blue-700 opacity-[3%]"></span>
                            <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-blue-700 opacity-100 group-hover:-translate-x-8"></span>
                            <span className="relative w-full text-left text-blue-700 transition-colors duration-200 ease-in-out group-hover:text-white">Confirm</span>
                            <span className="absolute inset-0 border-2 border-blue-700 rounded-lg"></span>
                        </button>
                        <button disabled={isDeleting} onClick={onCancel} className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group">
                            <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-red-600 opacity-[3%]"></span>
                            <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-red-600 opacity-100 group-hover:-translate-x-8"></span>
                            <span className="relative w-full text-left text-red-600 transition-colors duration-200 ease-in-out group-hover:text-white">Cancel</span>
                            <span className="absolute inset-0 border-2 border-red-600 rounded-lg"></span>
                        </button>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
};

export default DeleteModal;