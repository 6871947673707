import React, { useEffect, useState } from "react";
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  SelectChangeEvent,
  Modal,
  Backdrop,
  Box,
  Paper,
  TableRow,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableBody,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { queryConfigs } from "../../hooks/config";
import { useGetQuery, useMutationQuery } from "../../hooks/queryHook";
import { showNotification } from "../../services/helper";
import GenericPdfDownloader from "../GenericPdfDownloader";

type EditBillingProps = {
  open: boolean;
  onClose: () => void;
  ptInfo: any;
};
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
interface FormValues {
  id?: number | null;
  patient: number | null;
  payable_amount: number | null;
  paid_amount: number | null;
  month: number | null;
  year: number | null;
  received_by: number | null;
}
type EmpRow = {
  id: number;
  name: string;
  created_on: string;
  email: string;
  mobile: string;
  address: string;
  designation: number;
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 650,
  bgcolor: "background.paper",
  backdropFilter: "blur(3px)",
  borderRadius: 2,
  overflowY: "auto",
};
const EditBilling = ({ open, onClose, ptInfo }: EditBillingProps) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Box sx={style}>
        <GenericPdfDownloader
          rootElementId1="vitalTable"
          downloadFileName={`billing`}
        />
        <>
          <Paper>
            <section id="vitalTable" className="w-[210mm] p-[4mm] mx-auto">
              <div className="mt-4 w-full flex justify-between ">
                <section>
                  <div>Patient ID:</div>
                  <div>Patient:</div>
                  <div>Address:</div>
                  <div>Contact:</div>
                </section>
                <section>
                  <div>Organisation:</div>
                  <div>Address:</div>
                  <div>Contact:</div>
                  <div>Reg No:</div>
                </section>
              </div>
              <section className="my-10">
                <TableContainer
                  sx={{
                    boxShadow: "small",

                    maxHeight: 380,
                    borderRadius: 4,

                    marginTop: 2,
                  }}
                >
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    sx={{ width: "100%", overflow: "auto" }}
                    size="medium"
                  >
                    <TableHead sx={{ borderBottom: "none" }}>
                      <TableRow>
                        <TableCell align="center">Month</TableCell>
                        <TableCell align="center">Year</TableCell>
                        <TableCell align="center">Due</TableCell>
                        <TableCell align="center">Extra Charge</TableCell>
                        <TableCell align="center">Discount</TableCell>
                        <TableCell align="center">Paid Amount</TableCell>
                        <TableCell align="center" sx={{ w: 100 }}>
                          Date
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow sx={{ borderBottom: "none" }}>
                        <TableCell
                          align="center"
                          className="capitalize"
                          sx={{ borderBottom: "none" }}
                        >
                          May
                        </TableCell>
                        <TableCell
                          align="center"
                          className="capitalize"
                          sx={{ borderBottom: "none" }}
                        >
                          2024
                        </TableCell>
                        <TableCell
                          align="center"
                          className="capitalize"
                          sx={{ borderBottom: "none" }}
                        >
                          ₹ 2024 /-
                        </TableCell>
                        <TableCell
                          align="center"
                          className="capitalize"
                          sx={{ borderBottom: "none" }}
                        >
                          ₹ 2024 /-
                        </TableCell>
                        <TableCell
                          align="center"
                          className="capitalize"
                          sx={{ borderBottom: "none" }}
                        >
                          ₹ 2024 /-
                        </TableCell>
                        <TableCell
                          align="center"
                          className="capitalize"
                          sx={{ borderBottom: "none" }}
                        >
                          ₹ 2024 /-
                        </TableCell>
                        <TableCell
                          align="center"
                          className="capitalize"
                          sx={{ borderBottom: "none" }}
                        >
                          06-08-2024
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </section>
              <section></section>
            </section>
          </Paper>
        </>
      </Box>
    </Modal>
  );
};

export default EditBilling;
