import toast from "react-hot-toast";
import { Users } from "../types/models";
import { OrganisationType, ResponseType, UserType } from "../types/common";
import dayjs from "dayjs";

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export const showNotification = (type: string, message: string) => {
    if (type === "success") {
        toast.success(message, { duration: 2000, position: "top-center" });
    } else if (type === "error") {
        toast.error(message, { duration: 2000, position: "top-center" });
    }
};

// export const setItem = (key: string, value: ResponseType | string | number | UserType | OrganisationType | undefined) => {
//     localStorage.setItem(key, JSON.stringify(value));
// };
export const setItem = (key: string, value: ResponseType | string | number | UserType | OrganisationType | undefined) => {
    if (value !== undefined) {
        localStorage.setItem(key, JSON.stringify(value));
    } else {
        console.warn(`Attempted to store undefined value for key: ${key}`);
    }
};

// export const getItem = (key: string) => {
//     return JSON.parse(localStorage.getItem(key) || "");
// };
export const getItem = (key: string) => {
    const item = localStorage.getItem(key);

    // If the item doesn't exist, return null or an appropriate default value
    if (!item) return null;

    try {
        return JSON.parse(item);
    } catch (error) {
        console.error("Error parsing JSON:", error);
        return null;
    }
};

export const deleteItem = (key: string) => {
    localStorage.removeItem(key);
};

export const deleteAll = () => {
    localStorage.clear();
};


export const asyncHandler = (func: () => void, errorHandler: () => void, finalHandler?: () => void) => {
    try {
        return func();
    } catch (err) {
        console.error(err);
        if (errorHandler !== undefined) {
            errorHandler();
        }
    } finally {
        if (finalHandler !== undefined) {
            finalHandler();
        }
    }
};

export const debouncer = function (func: (e: any, value: string) => void, wait: number) {
    let timeout: any;
    return function (this: any, e: any, value: string) {
        const context = this;
        const later = function () {
            timeout = null;
            func.apply(context, [e, value]);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
};

export const getMonth = (date: Date) => {
    const index = new Date(date).getMonth();
    return months[index];
};


export const getStartingTimeOfDate = (date = dayjs()) => {
    const startingTimeOfDate = date.startOf("D").toISOString();

    return startingTimeOfDate;
}

export const getEndingTimeOfDate = (date = dayjs()) => {
    const endingTimeOfDate = date.endOf("D").toISOString();

    return endingTimeOfDate;
};