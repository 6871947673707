import { TextField, DialogActions, DialogTitle, IconButton, Select, MenuItem, FormControl, InputLabel, Backdrop, Modal, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";


var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);


const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    height: 600,
    bgcolor: "background.paper",
    backdropFilter: "blur(3px)",
    borderRadius: 2,
    overflowY: "auto",

};
var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

type AddPatientProps = {
    openEdit: boolean;
    editClose: () => void;
    patientInfo: any;
};

const PtCard = ({ openEdit, editClose, patientInfo }: AddPatientProps) => {

    return (
        <Modal
            open={openEdit}
            onClose={editClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Box sx={style}>
                <div className='bg-[#1C1C1C] sticky top-0 z-10 flex justify-between items-center px-4'>
                    <DialogTitle sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}>Patient Details</DialogTitle>
                    <IconButton onClick={editClose} sx={{ color: "white" }}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <section className='space-y-4 p-5'>
                    <TextField
                        name="name"
                        label="Name"
                        value={patientInfo.name}
                        fullWidth
                        margin="dense"
                        variant="outlined"
                        InputLabelProps={{ style: { color: 'gray', fontFamily: 'Poppins, sans-serif', fontSize: '13px' } }}
                        InputProps={{
                            classes: {
                            root: 'text-black',
                            input: 'h-8 py-0',
                            },
                        }}
                        inputProps={{
                            className: 'text-black',
                        }}
                        sx={{
                            width: '100%',
                            '& .MuiOutlinedInput-root': {
                            borderRadius: '1.5rem',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'inherit',
                            },
                            '& .MuiInputBase-input': {
                                height: '1rem',
                                padding: '1rem 1rem',
                                textTransform: 'capitalize',
                            },
                        }}
                    />
                    <div className='flex justify-between space-x-4' >
                        <TextField
                            name="mobile"
                            label="Mobile"
                            value={patientInfo.mobile}

                            fullWidth
                            margin="dense"
                            variant="outlined"
                            InputLabelProps={{ style: { color: 'gray', fontFamily: 'Poppins, sans-serif', fontSize: '13px' } }}
                            InputProps={{
                                inputProps: {
                                            maxLength: 10,
                                            pattern: "[0-9]*",
                                        },
                                classes: {
                                root: 'text-black',
                                input: 'h-10 py-0',
                                },
                            }}
                            inputProps={{
                                className: 'text-black font-Poppins',
                            }}
                            sx={{
                                width: '100%',
                                '& .MuiOutlinedInput-root': {
                                borderRadius: '1.5rem',
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'inherit',
                                },
                                '& .MuiInputBase-input': {
                                    height: '1rem',
                                    padding: '1rem 1rem',
                                },
                            }}
                        />
                        <TextField
                            name="emergency_contact"
                            label="Emergency Contact"
                            value={patientInfo.emergency_contact}
                            fullWidth
                            margin="dense"
                            variant="outlined"
                            InputLabelProps={{ style: { color: 'gray', fontFamily: 'Poppins, sans-serif', fontSize: '13px' } }}
                            InputProps={{
                                inputProps: {
                                            maxLength: 10,
                                            pattern: "[0-9]*",
                                        },
                                classes: {
                                root: 'text-black',
                                input: 'h-10 py-0',
                                },
                            }}
                            inputProps={{
                                className: 'text-black font-Poppins',
                            }}
                            sx={{
                                width: '100%',
                                '& .MuiOutlinedInput-root': {
                                borderRadius: '1.5rem',
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'inherit',
                                },
                                '& .MuiInputBase-input': {
                                    height: '1rem',
                                    padding: '1rem 1rem',
                                },
                            }}
                        />
                    </div>
                    <div className='flex justify-between space-x-2 items-center'>
                        <TextField
                            name="marital_status"
                            label="Martial Status"
                            value={patientInfo.marital_status}
                            margin="dense"
                            variant="outlined"
                            InputLabelProps={{ style: { color: 'gray', fontFamily: 'Poppins, sans-serif', fontSize: '13px' } }}
                            InputProps={{
                                inputProps: {
                                            maxLength: 3,
                                        },
                                classes: {
                                root: 'text-black',
                                input: 'h-10 py-0',
                                },
                            }}
                            inputProps={{
                                className: 'text-black font-Poppins',
                            }}
                            sx={{
                                width: '30%',
                                '& .MuiOutlinedInput-root': {
                                borderRadius: '1.5rem',
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'inherit',
                                },
                                '& .MuiInputBase-input': {
                                    height: '1rem',
                                    padding: '1rem 1rem',
                                    textTransform: 'capitalize',
                                },
                            }}
                        />
                        <TextField
                            name="gender"
                            label="Gender"
                            value={patientInfo.gender}
                            margin="dense"
                            variant="outlined"
                            InputLabelProps={{ style: { color: 'gray', fontFamily: 'Poppins, sans-serif', fontSize: '13px' } }}
                            InputProps={{
                                inputProps: {
                                            maxLength: 3,
                                        },
                                classes: {
                                root: 'text-black',
                                input: 'h-10 py-0',
                                },
                            }}
                            inputProps={{
                                className: 'text-black font-Poppins',
                            }}
                            sx={{
                                width: '30%',
                                '& .MuiOutlinedInput-root': {
                                borderRadius: '1.5rem',
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'inherit',
                                },
                                '& .MuiInputBase-input': {
                                    height: '1rem',
                                    padding: '1rem 1rem',
                                    textTransform: 'capitalize',
                                },
                            }}
                        />
                        <TextField
                            name="admission_charge"
                            label="Admission Charge"
                            value={patientInfo.admission_charge}
                            margin="dense"
                            variant="outlined"
                            InputLabelProps={{ style: { color: 'gray', fontFamily: 'Poppins, sans-serif', fontSize: '13px' } }}
                            InputProps={{
                                inputProps: {
                                            maxLength: 3,
                                        },
                                classes: {
                                root: 'text-black',
                                input: 'h-10 py-0',
                                },
                            }}
                            inputProps={{
                                className: 'text-black font-Poppins',
                            }}
                            sx={{
                                width: '30%',
                                '& .MuiOutlinedInput-root': {
                                borderRadius: '1.5rem',
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'inherit',
                                },
                                '& .MuiInputBase-input': {
                                    height: '1rem',
                                    padding: '1rem 1rem',
                                    textTransform: 'capitalize',
                                },
                            }}
                        />
                        <TextField
                            name="admission_date"
                            label="Admission Date"

                            value={dayjs(patientInfo.admission_date).format(
                                "DD-MM-YYYY"
                            )}
                            InputLabelProps={{ style: { color: 'gray', fontFamily: 'Poppins, sans-serif', fontSize: '13px' } }}
                            InputProps={{
                                inputProps: {
                                            maxLength: 3,
                                        },
                                classes: {
                                root: 'text-black',
                                input: 'h-10 py-0',
                                },
                            }}
                            inputProps={{
                                className: 'text-black font-Poppins',
                            }}
                            sx={{
                                width: '30%',
                                '& .MuiOutlinedInput-root': {
                                borderRadius: '1.5rem',
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'inherit',
                                },
                                '& .MuiInputBase-input': {
                                    height: '1rem',
                                    padding: '1rem 1rem',
                                    textTransform: 'capitalize',
                                },
                            }}
                        />

                    </div>
                    <TextField
                        name="address"
                        label="Address"
                        value={patientInfo.address}

                        fullWidth
                        margin="dense"
                        variant="outlined"
                        InputLabelProps={{ style: { color: 'gray', fontFamily: 'Poppins, sans-serif', fontSize: '13px' } }}
                        InputProps={{
                            classes: {
                            root: 'text-black',
                            input: 'h-8 py-0',
                            },
                        }}
                        inputProps={{
                            className: 'text-black',
                        }}
                        sx={{
                            width: '100%',
                            '& .MuiOutlinedInput-root': {
                            borderRadius: '1.5rem',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'inherit',
                            },
                            '& .MuiInputBase-input': {
                                height: '1rem',
                                padding: '1rem 1rem',
                                textTransform: 'capitalize',
                            },
                        }}
                    />
                    <TextField
                        name="district"
                        label="District"
                        value={patientInfo.district}
                        fullWidth
                        margin="dense"
                        variant="outlined"
                        InputLabelProps={{ style: { color: 'gray', fontFamily: 'Poppins, sans-serif', fontSize: '13px' } }}
                        InputProps={{
                            classes: {
                            root: 'text-black',
                            input: 'h-8 py-0',
                            },
                        }}
                        inputProps={{
                            className: 'text-black',
                        }}
                        sx={{
                            width: '100%',
                            '& .MuiOutlinedInput-root': {
                            borderRadius: '1.5rem',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'inherit',
                            },
                            '& .MuiInputBase-input': {
                                height: '1rem',
                                padding: '1rem 1rem',
                                textTransform: 'capitalize',
                            },
                        }}
                    />
                    <div className='flex justify-between space-x-5 items-center'>
                        <TextField
                            name="pincode"
                            label="Pincode"
                            value={patientInfo.pincode}

                            fullWidth
                            margin="dense"
                            variant="outlined"
                            InputLabelProps={{ style: { color: 'gray', fontFamily: 'Poppins, sans-serif', fontSize: '13px' } }}
                            InputProps={{
                                classes: {
                                root: 'text-black',
                                input: 'h-8 py-0',
                                },
                            }}
                            inputProps={{
                                className: 'text-black',
                            }}
                            sx={{
                                width: '100%',
                                '& .MuiOutlinedInput-root': {
                                borderRadius: '1.5rem',
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'inherit',
                                },
                                '& .MuiInputBase-input': {
                                    height: '1rem',
                                    padding: '1rem 1rem',
                                    textTransform: 'capitalize',
                                },
                            }}
                        />
                        <TextField
                            name="admission_charge"
                            label="Admission Charge"
                            value={patientInfo.admission_charge}
                            fullWidth
                            margin="dense"
                            variant="outlined"
                            InputLabelProps={{ style: { color: 'gray', fontFamily: 'Poppins, sans-serif', fontSize: '13px' } }}
                            InputProps={{
                                classes: {
                                root: 'text-black',
                                input: 'h-8 py-0',
                                },
                            }}
                            inputProps={{
                                className: 'text-black',
                            }}
                            sx={{
                                width: '100%',
                                '& .MuiOutlinedInput-root': {
                                borderRadius: '1.5rem',
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'inherit',
                                },
                                '& .MuiInputBase-input': {
                                    height: '1rem',
                                    padding: '1rem 1rem',
                                    textTransform: 'capitalize',
                                },
                            }}
                        />
                    </div>


                    <TextField
                        name="diagnosis"
                        label="Diagnosis"
                        value={patientInfo.diagnosis}
                        fullWidth
                        margin="dense"
                        variant="outlined"
                        InputLabelProps={{ style: { color: 'gray', fontFamily: 'Poppins, sans-serif', fontSize: '13px' } }}
                        InputProps={{
                            classes: {
                            root: 'text-black',
                            input: 'h-8 py-0',
                            },
                        }}
                        inputProps={{
                            className: 'text-black',
                        }}
                        sx={{
                            width: '100%',
                            '& .MuiOutlinedInput-root': {
                            borderRadius: '1.5rem',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'inherit',
                            },
                            '& .MuiInputBase-input': {
                                height: '1rem',
                                padding: '1rem 1rem',
                                textTransform: 'capitalize',
                            },
                        }}
                    />
                    <TextField
                        name="remark"
                        label="Remark"
                        value={patientInfo.remark}
                        fullWidth
                        margin="dense"
                        rows={3}
                        variant="outlined"
                        InputLabelProps={{ style: { color: 'gray', fontFamily: 'Poppins, sans-serif', fontSize: '13px' } }}
                        InputProps={{
                            classes: {
                            root: 'text-black',
                            input: 'h-8 py-0',
                            },
                        }}
                        inputProps={{
                            className: 'text-black',
                        }}
                        sx={{
                            width: '100%',
                            '& .MuiOutlinedInput-root': {
                            borderRadius: '1.5rem',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'inherit',
                            },
                            '& .MuiInputBase-input': {
                                height: '1rem',
                                padding: '1rem 1rem',
                                textTransform: 'capitalize',
                            },
                        }}
                    />
                </section>
                <div className='flex justify-center mb-4 items-center'>
                    <DialogActions>
                        <div className='ml-12'>
                            <button onClick={editClose} className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group">
                                <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#d00000] opacity-[3%]"></span>
                                <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#d00000] opacity-100 group-hover:-translate-x-8"></span>
                                <span className="relative w-full text-left text-[#d00000] transition-colors duration-200 ease-in-out group-hover:text-white">Close</span>
                                <span className="absolute inset-0 border-2 border-[#d00000] rounded-lg"></span>
                            </button>
                        </div>
                    </DialogActions>
                </div>
            </Box>
        </Modal>
    );
};

export default PtCard;
