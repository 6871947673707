import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TableCell,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import CloseIcon from "@mui/icons-material/Close";
import { MdDeleteForever } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { TableHeader } from "../../types/common";
import { queryConfigs } from "../../hooks/config";
import { useGetQuery, useMutationQuery } from "../../hooks/queryHook";
import { showNotification } from "../../services/helper";
import CommonTable from "../CommonTable";
import AddDoctor from "../doctors/AddDoctor";
import AddDesignation from "./AddDesignation";
import dayjs from "dayjs";
import EditDesignation from "./EditDesignation";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

type DoctorsProps = {
  pageTitle: string;
};
interface DesignationRow {
  id: number;
  created_on?: string;
  name: string;
}

const userHeaders: TableHeader[] = [
  { name: "ID" },
  { name: "Name" },
  { name: "Created On" },
  { name: "Action" },
];
const Designations: React.FC<DoctorsProps> = ({ pageTitle }) => {
  const [isAddPDoctorOpen, setIsAddPDoctorOpen] = useState(false);
  const [designationName, setDesignationName] = useState<string | null>(null);
  const [searchName, setSearchName] = useState<string | null>(null);
  const [editValue, setEditValue] = useState({
    name: "",
    description: "",
  });
  const { queryKey, queryFn } = queryConfigs.getDesignations;
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState({ offset: 0, limit: 10 });
  const limit = pagination.limit;

  useEffect(() => {
    setCurrentPage(Math.floor(pagination.offset / limit) + 1);
  }, [pagination.offset, limit]);

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => {
    setCurrentPage(page + 1);
    setPagination((prev) => ({
      ...prev,
      offset: page * limit,
    }));
  };

  const { data, isSuccess, refetch, isLoading } = useGetQuery({
    key: queryKey,
    func: queryFn,
    params: {
      offset: (currentPage - 1) * limit,
      limit: pagination.limit,
      name: searchName ?? "",
    },
  });

  const [deleteOpen, setDeleteOpen] = useState(false);

  const { mutationFn: deleteFunc, invalidateKey: medKey } =
    queryConfigs.deleteDesignation;
  const { mutate: deleteMedicine } = useMutationQuery({
    key: medKey,
    func: deleteFunc,
    onSuccess: () => {
      showNotification("success", "Designation deleted successfully");
      handleDeleteClose();
    },
  });

  const userHeaderNames: string[] = userHeaders.map((header) => header.name);

  const handleEditChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setEditValue((prevState) => ({ ...prevState, [name]: value }));
  };

  function handleAddClick(): void {
    setIsAddPDoctorOpen(true);
  }
  const handleDeleteClear = () => {
    setEditValue({
      name: "",
      description: "",
    });
  };
  const [toDelete, setToDelete] = useState<number | null>();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedDesignation, setSelectedDesignation] =
    useState<DesignationRow | null>(null);

  const handleOpenEditModal = (designation: DesignationRow) => {
    setSelectedDesignation(designation);
    setIsEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedDesignation(null);
  };
  const handleDeleteOpen = (id: number) => {
    setDeleteOpen(true);
    setToDelete(id);
  };
  const handleDeleteClose = () => {
    setDeleteOpen(false);
    handleDeleteClear();
  };

  const handleDelete = () => {
    deleteMedicine(toDelete);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDesignationName(event.target.value);
  };

  const handleSearch = () => {
    if (designationName !== "" || !designationName) {
      setSearchName(designationName);
    }
  };

  const handleSearchClear = () => {
    setDesignationName(null);
    setSearchName(null);
  };
  const handleRefetch = () => {
    handleSearchClear();
    refetch();
  };
  return (
    <div className="w-full overflow-hidden mx-auto relative h-screen">
      <CommonTable
        refetchIcon={true}
        onRefetchClick={handleRefetch}
        tableHeaders={userHeaderNames}
        totalRows={data?.count || 0}
        colSpan={userHeaders.length}
        pageTitle={pageTitle}
        onAddClick={handleAddClick}
        marginTop={16}
        showInchargeFilter={false}
        showDesignationFilter={false}
        showMobileFilter={false}
        children2={
          <>
            <div className="flex items-center space-x-5 w-full">
              <TextField
                label="Search By Name"
                variant="outlined"
                name="name"
                value={designationName || ""}
                onChange={handleSearchChange}
                size="small"
                sx={{
                  width: "20%",
                  "& .MuiInputLabel-root": {
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "13px",
                  },
                }}
              />

              <button onClick={handleSearch} className="search-btn">
                Search
              </button>
              <button onClick={handleSearchClear} className="clear-btn">
                Clear
              </button>
            </div>
          </>
        }
        children3={
          <TablePagination
            component="div"
            count={data?.count || 0}
            page={currentPage - 1}
            rowsPerPage={limit}
            onPageChange={handlePageChange}
            onRowsPerPageChange={(event) => {
              const newLimit = parseInt(event.target.value, 10);
              setPagination((prev) => ({
                ...prev,
                limit: newLimit,
                offset: 0,
              }));
              setCurrentPage(1);
            }}
            sx={{ color: "#38a3a5", font: "Poppins, sans-serif" }}
          />
        }
      >
        {data?.list?.map((designation: DesignationRow) => (
          <TableRow key={designation.id}>
            <TableCell align="center" className="capitalize">
              {designation.id}
            </TableCell>
            <TableCell align="center" className="capitalize">
              {designation.name}
            </TableCell>
            <TableCell align="center" className="capitalize">
              {dayjs(designation.created_on).format("DD-MM-YYYY")}
            </TableCell>
            <TableCell align="center" className="space-x-3">
              <Tooltip title="Edit" arrow>
                <button
                  onClick={() => handleOpenEditModal(designation)}
                  className="edit-btn"
                >
                  <FaEdit />
                </button>
              </Tooltip>
              <Tooltip title="Delete" arrow>
                <button
                  className="del-btn"
                  onClick={() => handleDeleteOpen(designation.id)}
                >
                  <MdDeleteForever />
                </button>
              </Tooltip>
            </TableCell>
          </TableRow>
        ))}
      </CommonTable>
      <AddDesignation
        open={isAddPDoctorOpen}
        onClose={() => setIsAddPDoctorOpen(false)}
      />

      <Dialog open={deleteOpen} onClose={handleDeleteClose}>
        <div className=" flex justify-between items-center px-4">
          <DialogTitle sx={{ fontFamily: "Poppins, sans-serif" }}>
            Confirm Delete
          </DialogTitle>
          <IconButton onClick={handleDeleteClose} sx={{ color: "white" }}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent>
          <p>
            Are you sure you want to delete this item? This action cannot be
            undone.
          </p>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            onClick={handleDelete}
            variant="contained"
            color="error"
            sx={{ marginRight: 2 }}
          >
            Delete
          </Button>
          <Button
            onClick={handleDeleteClose}
            variant="outlined"
            color="inherit"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <EditDesignation
        open={isEditModalOpen}
        onClose={handleCloseEditModal}
        initialData={selectedDesignation}
      />
    </div>
  );
};

export default Designations;
