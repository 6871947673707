import React, { useState } from "react";
import CommonTable from "../CommonTable";
import {
  TableCell,
  TableRow,
  Tooltip,
  IconButton,
  Popover,
  TextField,
  TablePagination,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  getEndingTimeOfDate,
  getStartingTimeOfDate,
  showNotification,
} from "../../services/helper";
import { useGetQuery, useMutationQuery } from "../../hooks/queryHook";
import { queryConfigs } from "../../hooks/config";
import { useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import { PiEyeFill } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import { FaBook, FaEdit } from "react-icons/fa";
import { IoStatsChart } from "react-icons/io5";
import { MdDeleteForever, MdReceipt } from "react-icons/md";
import { IoDocumentAttachSharp } from "react-icons/io5";
import { FaHospitalUser } from "react-icons/fa6";
import CustomStatusChip from "../CustomStatusChip";
import { uploadFile } from "../../services/api";
import { DoctorRow, UploadResponse } from "../../types/common";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PatientChips from "./PatientChips";
import DischargeSummaryModal from "./DischargeSummaryModal";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

type PatientsProps = {
  pageTitle: string;
};
type PtRow = {
  admission_id: number;
  id: number;
  created_on: string;
  name: string;
  patient_name: string;
  mobile: string;
  age: string;
  diagnosis: string;
  marital_status: string;
  address: string;
  district: string;
  pincode: string;
  emergency_contact: string;
  gender: string;
  remark: string;
  admission_charge: string;
  admission_date: string;
  discharge_date: string;
  referred_by: number;
  is_discharged: boolean;
};

type Header = {
  name: string;
};

const userHeaders: Header[] = [
  { name: "Admission ID" },
  { name: "Pt Name" },
  { name: "Referred By" },
  { name: "Admitted On" },
  { name: "Discharge On" },

  { name: "Action" },
];

const DischargeTable: React.FC<PatientsProps> = ({ pageTitle }) => {
  const userHeaderNames: string[] = userHeaders.map((header) => header.name);

  const { queryKey, queryFn } = queryConfigs.getDischargedAdmissions;
  const [chartStartDate, setChartStartDate] = useState<string | null>(null);
  const [chartEndDate, setChartEndDate] = useState<string | null>(null);

  const [patientSearch, setPatientSearch] = useState<{
    id: number | null;
    name: string | null;
  }>({
    id: null,
    name: null,
  });
  const [searchParams, setSearchParams] = useState<{
    id: number | null;
    name: string | null;
  }>({
    id: null,
    name: null,
  });
  const { invalidateKey, mutationFn } = queryConfigs.deletePatient;

  const isEnabled =
    (chartStartDate === null && chartEndDate === null) ||
    (chartStartDate !== null && chartEndDate !== null);

  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState({ offset: 0, limit: 10 });
  const limit = pagination.limit;
  const {
    data: ptData,
    isLoading,
    refetch,
  } = useGetQuery({
    key: queryKey,
    func: queryFn,
    params: {
      offset: (currentPage - 1) * pagination.limit,
      limit: pagination.limit,
      name: searchParams.name ?? "",
      id: searchParams.id ?? null,
      startDate: chartStartDate,
      endDate: chartEndDate,
    },
    isEnabled: isEnabled,
  });
  const { queryKey: doctors, queryFn: doctorFunc } = queryConfigs.getDoctors;
  const { data: doctorData } = useGetQuery({
    key: doctors,
    func: doctorFunc,
  });

  const [toDelete, setToDelete] = useState<number | null>(null);
  const [open, setOpen] = useState(false);

  const [view, setView] = useState({});
  const [selectedPtID, setSelectedPtID] = useState({});
  const [viewOpen, setViewOpen] = useState(false);

  const navigate = useNavigate();

  const [selectedPt, setSelectedPt] = useState<PtRow | null>(null);

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => {
    setCurrentPage(page + 1);
    setPagination((prev) => ({
      ...prev,
      offset: page * pagination.limit,
    }));
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleSelectAdmissions = (pt: PtRow) => {
    setSelectedPt(pt);
    setOpen(true);
  };
  const handleChartDateRange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    const formattedStart = start ? getStartingTimeOfDate(dayjs(start)) : null;
    const formattedEnd = end ? getEndingTimeOfDate(dayjs(end)) : null;
    setChartStartDate(formattedStart);
    setChartEndDate(formattedEnd);
  };
  const handleSearch = () => {
    setSearchParams(patientSearch);
  };
  const handleClear = () => {
    setPatientSearch({
      id: null,
      name: null,
    });
    setSearchParams({
      id: null,
      name: null,
    });
    setChartStartDate(null);
    setChartEndDate(null);
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setPatientSearch((prevState) => ({
      ...prevState,
      [name]: name === "id" ? (value ? Number(value) : null) : value,
    }));
  };

  const handleRefetch = () => {
    refetch();
    handleClear();
  };
  return (
    <>
      <div className="w-full overflow-hidden mx-auto relative h-screen">
        <CommonTable
          refetchIcon={true}
          onRefetchClick={handleRefetch}
          tableHeaders={userHeaderNames}
          totalRows={ptData?.total_count || 0}
          colSpan={userHeaders.length}
          pageTitle={pageTitle}
          marginTop={16}
          showInchargeFilter={false}
          showDesignationFilter={false}
          children3={
            <TablePagination
              component="div"
              count={ptData?.total_count || 0}
              page={currentPage - 1}
              rowsPerPage={limit}
              onPageChange={handlePageChange}
              onRowsPerPageChange={(event) => {
                const newLimit = parseInt(event.target.value, 10);
                setPagination((prev) => ({
                  ...prev,
                  limit: newLimit,
                  offset: 0,
                }));
                setCurrentPage(1);
              }}
              sx={{ color: "#38a3a5", font: "Poppins, sans-serif" }}
            />
          }
          children2={
            <>
              <div className=" flex items-center space-x-5 w-full">
                <DatePicker
                  selected={
                    chartStartDate ? dayjs(chartStartDate).toDate() : null
                  }
                  onChange={handleChartDateRange}
                  startDate={
                    chartStartDate ? dayjs(chartStartDate).toDate() : undefined
                  }
                  endDate={
                    chartEndDate ? dayjs(chartEndDate).toDate() : undefined
                  }
                  selectsRange
                  dateFormat="yyyy-MM-dd"
                  placeholderText="Select Date Range"
                  className="w-[300px] border rounded  py-2 px-4"
                />
                <TextField
                  label="Patient Name"
                  variant="outlined"
                  name="name"
                  onChange={handleChange}
                  value={patientSearch.name || ""}
                  size="small"
                  sx={{
                    width: "20%",
                    "& .MuiInputLabel-root": {
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "13px",
                    },
                  }}
                />
                <TextField
                  label="Patient ID"
                  variant="outlined"
                  name="id"
                  onChange={handleChange}
                  value={
                    patientSearch.id !== null ? patientSearch.id.toString() : ""
                  }
                  size="small"
                  sx={{
                    width: "20%",
                    "& .MuiInputLabel-root": {
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "13px",
                    },
                  }}
                />
                <button onClick={handleSearch} className="search-btn">
                  Search
                </button>
                <button onClick={handleClear} className="clear-btn">
                  Clear
                </button>
              </div>
            </>
          }
        >
          {ptData?.admissions?.map((pt: PtRow) => (
            <TableRow key={pt.admission_id}>
              <TableCell align="center" className="capitalize">
                {pt.admission_id}
              </TableCell>
              <TableCell align="center">
                <p className="capitalize font-sans">{pt.patient_name}</p>
              </TableCell>
              <TableCell align="center">
                <p className="capitalize font-sans">
                  {doctorData?.list.find(
                    (d: DoctorRow) => d.id === pt.referred_by
                  )?.name ?? "--"}
                </p>
              </TableCell>
              <TableCell align="center" className="capitalize">
                {dayjs(pt.admission_date).format("DD-MMM-YYYY")}
              </TableCell>
              <TableCell align="center" className="capitalize">
                {dayjs(pt.discharge_date).format("DD-MMM-YYYY")}
              </TableCell>
              {/* <TableCell align="center" className="capitalize">
                <PatientChips isDischarged={pt?.is_discharged ?? false} />
              </TableCell> */}

              <TableCell align="center">
                <Tooltip title="Generate Discharge Summary" arrow>
                  <button
                    onClick={() => handleSelectAdmissions(pt)}
                    className="generate-btn"
                  >
                    <IoDocumentAttachSharp />
                  </button>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </CommonTable>
      </div>
      <DischargeSummaryModal
        open={open}
        onClose={() => setOpen(false)}
        admission={selectedPt}
      />
    </>
  );
};

export default DischargeTable;
