import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { queryConfigs } from "../hooks/config";
import { useGetQuery, useMutationQuery } from "../hooks/queryHook";
import { setAuthHeader } from "../services/api";
import { setItem } from "../services/helper";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import bgImage from "../assets/login.jpg";
import { OrganisationType } from "../types/common";
import { useDispatch, useSelector } from "react-redux";
import {
  setOrganisation,
  setToken,
  setUser,
} from "../redux/features/authSlice";
import { RootState } from "../redux/store";

type User = {
  email: string;
  id: number;
  image: string;
  mobile: string;
  name: string;
  organisation: number;
  token?: string;
};

const Login: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { queryKey: authKey, queryFn: Loginfunc } = queryConfigs.useLogin;
  const { mutate, data } = useMutationQuery({
    key: authKey,
    func: Loginfunc,
    onSuccess: (data) => {
      navigate("/admin");
      setAuthHeader(data?.token);
      setItem("token", data?.token);
      setItem("user", data?.user);
      setItem("organisation", data?.organisation);
      dispatch(setOrganisation({ ...data?.organisation }));
      dispatch(setUser({ ...data?.user }));
      dispatch(setToken(data?.token ?? null));
    },
  });

  useEffect(() => {
    if (data?.success && data?.token) {
      setAuthHeader(data.token);
      setItem("token", data.token);
      setItem("user", data.user);
      setItem("organisation", data?.organisation);
    }
  }, [data]);

  const validateEmail = (email: string): boolean => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validatePassword = (password: string): boolean => {
    return password.length >= 6;
  };

  const [formState, setFormState] = useState<{
    email: string;
    password: string;
    emailError: string | null;
    passwordError: string | null;
  }>({
    email: "",
    password: "",
    emailError: null,
    passwordError: null,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormState((prevState) => {
      const updatedState = { ...prevState, [name]: value };
      if (name === "email") {
        updatedState.emailError = validateEmail(value)
          ? null
          : "Invalid email address";
      } else if (name === "password") {
        updatedState.passwordError = validatePassword(value)
          ? null
          : "Password must be at least 6 characters long";
      }

      return updatedState;
    });
  };

  const [selectedOrganisationId, setSelectedOrganisationId] = useState<
    number | ""
  >("");

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const { emailError, passwordError } = formState;

    if (emailError || passwordError) {
      return;
    }

    mutate({
      email: formState.email,
      password: formState.password,
      organisation: selectedOrganisationId,
    });
  };

  const handleOrgChange = (event: SelectChangeEvent<number>) => {
    setSelectedOrganisationId(event.target.value as number);
  };

  const { queryKey, queryFn } = queryConfigs.getMetadata;
  const {
    data: metaData,
    isLoading,
    refetch,
  } = useGetQuery({
    key: queryKey,
    func: queryFn,
  });

  return (
    <div
      className="flex h-screen bg-cover bg-center bg-no-repeat bg-fixed"
      style={{
        backgroundImage: `url(${bgImage})`,
      }}
    >
      {/* Right side with login form */}
      <div className="w-full flex flex-col items-center justify-center bg-white/10 backdrop-blur-sm">
        <span className="text-6xl font-bold text-center font-Poppins mb-6 bg-gradient-to-r from-gray-200 via-white to-gray-200 bg-clip-text text-transparent">
          Ningthoujam <br /> Healthcare
        </span>
        <div className="w-3/4 bg-white px-4 py-6 max-w-md rounded-lg drop-shadow-lg">
          <h2 className="text-3xl font-bold text-center font-Poppins text-slate-700 mb-2">
            Login
          </h2>
          <form className="space-y-1 flex flex-col">
            <div>
              <TextField
                label="Email"
                variant="outlined"
                type="email"
                name="email"
                fullWidth
                value={formState.email}
                onChange={handleChange}
                error={!!formState.emailError}
                helperText={formState.emailError}
                margin="normal"
                InputLabelProps={{
                  style: {
                    color: "gray",
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "13px",
                  },
                }}
                InputProps={{
                  classes: {
                    root: "text-black",
                    input: "h-10 py-0",
                  },
                }}
                inputProps={{
                  className: "text-black",
                }}
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "1.5rem",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "inherit",
                  },
                  "& .MuiInputBase-input": {
                    height: "1rem",
                    padding: "1rem 1rem",
                  },
                }}
                className="rounded-3xl bg-white"
              />
            </div>
            <div>
              <TextField
                fullWidth
                label="Password"
                variant="outlined"
                type="password"
                name="password"
                value={formState.password}
                onChange={handleChange}
                error={!!formState.passwordError}
                helperText={formState.passwordError}
                margin="normal"
                InputLabelProps={{
                  style: {
                    color: "gray",
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "13px",
                  },
                }}
                InputProps={{
                  classes: {
                    root: "text-black",
                    input: "h-10 py-0",
                  },
                }}
                inputProps={{
                  className: "text-black",
                }}
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "1.5rem",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "inherit",
                  },
                  "& .MuiInputBase-input": {
                    height: "1rem",
                    padding: "1rem 1rem",
                  },
                }}
                className="rounded-3xl bg-white"
              />
            </div>
            <div>
              <FormControl fullWidth margin="dense" variant="outlined">
                <InputLabel
                  style={{
                    color: "gray",
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "13px",
                  }}
                >
                  Organisation
                </InputLabel>
                <Select
                  name="organisation"
                  value={selectedOrganisationId}
                  onChange={handleOrgChange}
                  label="Select In Charge"
                  sx={{
                    width: "100%",
                    backgroundColor: "white",
                    borderRadius: "1.5rem",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "3xl",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "inherit",
                      borderRadius: "3xl",
                    },
                    "& .MuiInputBase-input": {
                      height: "1rem",
                      padding: "1rem 1rem",
                    },
                    "& .MuiSelect-icon": {
                      color: "gray",
                    },
                  }}
                >
                  {metaData?.list?.organisations?.map((org: any) => (
                    <MenuItem key={org.id} value={org.id}>
                      {org.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="flex justify-center py-4">
              <button
                onClick={handleSubmit}
                className="w-[30%] py-2 px-4 border border-transparent text-sm font-medium rounded-md font-Poppins text-white bg-blue-500 hover:bg-white hover:text-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                Sign In
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
